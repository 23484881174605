import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Subscription, timer } from 'rxjs';
import { PobierzAktualnePozycjeFuturesFiltry } from 'src/app/modele/futures/pobierzAktualnePozycjeFuturesFiltry';
import { PobierzAktualnePozycjeFuturesRezultat } from 'src/app/modele/futures/pobierzAktualnePozycjeFuturesRezultat';
import { RejestrFiltrowanyRequest } from 'src/app/modele/wspolne/rejestrFiltrowanyRequest';
import { Stronicowanie } from 'src/app/modele/wspolne/stronicowanie';
import { FuturesService } from 'src/app/serwisy/futures/futures.service';
import { AuthService } from 'src/app/serwisy/ogolne/auth.service';
import { LoaderService } from 'src/app/serwisy/ogolne/loader.service';
import { BazowyComponent } from '../../bazowy.component';

@Component({
  selector: 'app-testy',
  templateUrl: './testy.component.html',
  styleUrls: ['./testy.component.css'],
})
export class TestyComponent
  extends BazowyComponent
  implements OnInit, OnDestroy
{
  subscription: Subscription;
  czyZaladowanoDane: boolean;
  calkowitaLiczbaElementow: BigInteger;
  rozmiarTabeli: number = 25;
  czyJestWiecejRekordow: boolean = true;
  pozycje: Array<PobierzAktualnePozycjeFuturesRezultat> = new Array();
  wyszukiwanie: RejestrFiltrowanyRequest<PobierzAktualnePozycjeFuturesFiltry> =
    new RejestrFiltrowanyRequest<PobierzAktualnePozycjeFuturesFiltry>();

  slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: document.getElementsByClassName('btn__left'),
    nextArrow: document.getElementsByClassName('btn__right'),
    infinite: false,
    variableWidth: true,
  };

  constructor(
    public router: Router,
    modalService: NgbModal,
    loaderSerwis: LoaderService,
    komunikatSerwis: ToastrService,
    public authService: AuthService,
    public futuresSerwis: FuturesService
  ) {
    super(authService, modalService, loaderSerwis, komunikatSerwis, router);
  }
  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  ngOnInit(): void {
    setTimeout(() => {});
  }
}
