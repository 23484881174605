import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot } from '@angular/router';
import { DaneZalogowanegoUzytkownika } from 'src/app/modele/logowanie/daneZalogowanegoUzytkownika';
import { Ciasteczko } from 'src/app/modele/wspolne/ciasteczka';
import { Routing } from 'src/app/modele/wspolne/routing';
import { Uprawnienie } from 'src/app/modele/wspolne/uprawnienie';
import { MenuService } from './menu.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(
    private router: Router) { }

  logIn(ciasteczko: Ciasteczko, dane: DaneZalogowanegoUzytkownika) {
    this.ustawCiasteczko(ciasteczko, dane);
    this.router.navigate([Routing.KonfiguracjeGield]);
  }

  logOut() {
    this.wyczyscCiasteczka();
    this.router.navigate([Routing.Zaloguj]);
  }

  get isLoggedIn() {
    return this.czyZalogowany();
  }

  get czyAdmin() {
    var daneUzytkownika = this.pobierzDaneZalogowanegoUzytkownika();
    if (daneUzytkownika == undefined) {
      return false;
    }
    return daneUzytkownika.uprawnienie == Uprawnienie.administrator;
  }

  public getUserName(): string {
    var imie = this.pobierzCiasteczko<DaneZalogowanegoUzytkownika>(Ciasteczko.DaneZalogowanegoUzytkownika).imie;
    return imie ? imie : "";
  }
  public getUserLastName(): string {
    var nazwisko = this.pobierzCiasteczko<DaneZalogowanegoUzytkownika>(Ciasteczko.DaneZalogowanegoUzytkownika).nazwisko;
    return nazwisko ? nazwisko : "";
  }

  public czyZalogowany() {
    var istnieje = localStorage.getItem(Ciasteczko.DaneZalogowanegoUzytkownika.toString());

    if (!istnieje) {
      return false;
    }

    var uzytkownik = this.pobierzCiasteczko<DaneZalogowanegoUzytkownika>(Ciasteczko.DaneZalogowanegoUzytkownika);
    if (uzytkownik != null && uzytkownik.idUzytkownika != null && uzytkownik.token != null) {
      return true;
    }
    return false;
  }

  public pobierzDaneZalogowanegoUzytkownika() {
    return this.pobierzCiasteczko<DaneZalogowanegoUzytkownika>(Ciasteczko.DaneZalogowanegoUzytkownika);
  }

  public ustawCiasteczko(ciasteczko: Ciasteczko, dane: any) {
    var istnieja = localStorage.getItem(ciasteczko.toString());
    var wartoscJson = JSON.stringify(dane);
    if (istnieja) {
      localStorage.removeItem(ciasteczko.toString());
    }
    localStorage.setItem(ciasteczko.toString(), wartoscJson);
  }

  public pobierzCiasteczko<T>(ciasteczko: Ciasteczko): T {
    if (!localStorage.getItem(ciasteczko.toString())) {
      return null;
    }
    var wartoscJosn = localStorage.getItem(ciasteczko.toString());
    var obj = JSON.parse(wartoscJosn) as T;
    return obj;
  }

  public wyczyscCiasteczka() {
    localStorage.removeItem(Ciasteczko.DaneZalogowanegoUzytkownika.toString());
  }
}

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService,
    private menuService: MenuService
  ) { }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const isLoggedIn = this.authService.isLoggedIn;
    const isLoginForm = route.routeConfig?.path === Routing.Zaloguj;

    if (isLoginForm) {
      this.menuService.schowajMenu();
    }
    else {
      this.menuService.pokazMenu();
    }

    if (isLoggedIn && isLoginForm) {
      this.router.navigate([Routing.KonfiguracjeGield]);
      return false;
    }


    if (!isLoggedIn && !isLoginForm) {
      this.router.navigate([Routing.Zaloguj]);
    }

    return isLoggedIn || isLoginForm;
  }
}
