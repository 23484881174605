<div class="modal-content" *ngIf="czyZaladowanoDane">
  <div class="modal-body">
    <button
      aria-label="Close"
      class="close"
      data-dismiss="modal"
      type="button"
      (click)="activeModal.dismiss()"
    ></button>
    <div class="ft__01 text-center py-4">Edytuj konfigurację</div>
    <div class="text-center mb-2"></div>
    <form action="">
      <div class="input_container mb-4">
        <select [(ngModel)]="model.gieldaId" name="gielda">
          <option [ngValue]="undefined" [disabled]="true">Giełda</option>
          <option [ngValue]="gielda.id" *ngFor="let gielda of gieldy">
            {{ gielda.gielda }} - {{ gielda.rodzajKonta }} -
            {{ gielda.czyjeKonto }}
          </option>
        </select>
      </div>

      <div class="chb por mb-3">
        <input
          id="cb_04"
          type="checkbox"
          [(ngModel)]="model.czyAktywna"
          name="czyAktywna"
        />
        <label class="fs__chb" for="cb_04">Czy aktywna</label>
      </div>

      <div class="input_container mb-4">
        <select [(ngModel)]="model.paraKrypto" name="paraKrypto">
          <option [ngValue]="undefined" [disabled]="true">Krypto</option>
          <option [ngValue]="krypto.key" *ngFor="let krypto of dostepneKrypto">
            {{ krypto.value }}
          </option>
        </select>
      </div>

      <div class="input_container mb-4">
        <select [(ngModel)]="model.wersjaBota" name="wersjaBota">
          <option [ngValue]="undefined" [disabled]="true">Wersja bota</option>
          <option ngValue="v1">v1 - strategia pod bessę</option>
          <option ngValue="v2">
            v2 - strategia pod bessę + predykcja ceny z AI
          </option>
        </select>
      </div>

      <div
        class="input_container pb-3"
        [ngClass]="czyBladWalidacyjny('maxGodzinPozycji') ? 'error' : ''"
      >
        <input
          type="number"
          placeholder="Max godzin pozycji"
          [(ngModel)]="model.maxGodzinPozycji"
          name="maxGodzinPozycji"
        />
        <div
          class="error-info fs__error pt-2"
          *ngIf="czyBladWalidacyjny('maxGodzinPozycji')"
        >
          {{ pobierzBladWalidacyjny("maxGodzinPozycji") }}
        </div>
      </div>

      <div
        class="input_container pb-3"
        [ngClass]="czyBladWalidacyjny('tpl') ? 'error' : ''"
      >
        <input
          type="number"
          placeholder="Long take profit %"
          [(ngModel)]="model.tpl"
          name="tpl"
        />
        <div
          class="error-info fs__error pt-2"
          *ngIf="czyBladWalidacyjny('tpl')"
        >
          {{ pobierzBladWalidacyjny("tpl") }}
        </div>
      </div>
      <div
        class="input_container pb-3"
        [ngClass]="czyBladWalidacyjny('sll') ? 'error' : ''"
      >
        <input
          type="number"
          placeholder="Long stop loss %"
          [(ngModel)]="model.sll"
          name="sll"
        />
        <div
          class="error-info fs__error pt-2"
          *ngIf="czyBladWalidacyjny('sll')"
        >
          {{ pobierzBladWalidacyjny("sll") }}
        </div>
      </div>
      <div
        class="input_container pb-3"
        [ngClass]="czyBladWalidacyjny('tps') ? 'error' : ''"
      >
        <input
          type="number"
          placeholder="Short take profit %"
          [(ngModel)]="model.tps"
          name="tps"
        />
        <div
          class="error-info fs__error pt-2"
          *ngIf="czyBladWalidacyjny('tps')"
        >
          {{ pobierzBladWalidacyjny("tps") }}
        </div>
      </div>
      <div
        class="input_container pb-3"
        [ngClass]="czyBladWalidacyjny('sls') ? 'error' : ''"
      >
        <input
          type="number"
          placeholder="Short stop loss %"
          [(ngModel)]="model.sls"
          name="sls"
        />
        <div
          class="error-info fs__error pt-2"
          *ngIf="czyBladWalidacyjny('sls')"
        >
          {{ pobierzBladWalidacyjny("sls") }}
        </div>
      </div>

      <div
        class="input_container pb-3"
        [ngClass]="czyBladWalidacyjny('dzwignia') ? 'error' : ''"
      >
        <input
          type="number"
          max="23"
          min="1"
          placeholder="Dźwignia"
          [(ngModel)]="model.dzwignia"
          name="dzwignia"
        />
        <div
          class="error-info fs__error pt-2"
          *ngIf="czyBladWalidacyjny('dzwignia')"
        >
          {{ pobierzBladWalidacyjny("dzwignia") }}
        </div>
      </div>
      <div
        class="input_container pb-3"
        [ngClass]="czyBladWalidacyjny('procentKapitalu') ? 'error' : ''"
      >
        <input
          type="number"
          max="100"
          min="1"
          placeholder="Procent kapitału"
          [(ngModel)]="model.procentKapitalu"
          name="procentKapitalu"
        />
        <div
          class="error-info fs__error pt-2"
          *ngIf="czyBladWalidacyjny('procentKapitalu')"
        >
          {{ pobierzBladWalidacyjny("procentKapitalu") }}
        </div>
      </div>

      <div
        class="input_container pb-3"
        [ngClass]="czyBladWalidacyjny('macdSL') ? 'error' : ''"
      >
        <input
          type="number"
          placeholder="MSL"
          [(ngModel)]="model.macdSL"
          name="macdSL"
        />
        <div
          class="error-info fs__error pt-2"
          *ngIf="czyBladWalidacyjny('macdSL')"
        >
          {{ pobierzBladWalidacyjny("macdSL") }}
        </div>
      </div>
      <div
        class="input_container pb-3"
        [ngClass]="czyBladWalidacyjny('macdLS') ? 'error' : ''"
      >
        <input
          type="number"
          placeholder="MLS"
          [(ngModel)]="model.macdLS"
          name="macdLS"
        />
        <div
          class="error-info fs__error pt-2"
          *ngIf="czyBladWalidacyjny('macdLS')"
        >
          {{ pobierzBladWalidacyjny("macdLS") }}
        </div>
      </div>
      <div
        class="input_container pb-3"
        [ngClass]="czyBladWalidacyjny('macdSS') ? 'error' : ''"
      >
        <input
          type="number"
          placeholder="MSS"
          [(ngModel)]="model.macdSS"
          name="macdSS"
        />
        <div
          class="error-info fs__error pt-2"
          *ngIf="czyBladWalidacyjny('macdSS')"
        >
          {{ pobierzBladWalidacyjny("macdSS") }}
        </div>
      </div>
      <div
        class="input_container pb-3"
        [ngClass]="czyBladWalidacyjny('rsiL') ? 'error' : ''"
      >
        <input
          type="number"
          max="100"
          min="1"
          placeholder="RSI"
          [(ngModel)]="model.rsiL"
          name="rsiL"
        />
        <div
          class="error-info fs__error pt-2"
          *ngIf="czyBladWalidacyjny('rsiL')"
        >
          {{ pobierzBladWalidacyjny("rsiL") }}
        </div>
      </div>

      <div class="chb por mb-3">
        <input
          id="cb_05"
          type="checkbox"
          [(ngModel)]="model.tylkoAnalizaSygnalow"
          name="tylkoAnalizaSygnalow"
        />
        <label class="fs__chb" for="cb_05">Tylko analiza sygnałów</label>
      </div>

      <div
        class="input_container pb-3"
        [ngClass]="czyBladWalidacyjny('maxWielkoscPozycji') ? 'error' : ''"
      >
        <input
          type="number"
          max="100"
          min="1"
          placeholder="Max wielkość pozycji"
          [(ngModel)]="model.maxWielkoscPozycji"
          name="maxWielkoscPozycji"
        />
        <div
          class="error-info fs__error pt-2"
          *ngIf="czyBladWalidacyjny('maxWielkoscPozycji')"
        >
          {{ pobierzBladWalidacyjny("maxWielkoscPozycji") }}
        </div>
      </div>
      <div
        class="input_container pb-3"
        [ngClass]="
          czyBladWalidacyjny('odkladanyProcentNaKoniecMiesiaca') ? 'error' : ''
        "
      >
        <input
          type="number"
          max="100"
          min="1"
          placeholder="Odkładany procent na koniec miesiąca"
          [(ngModel)]="model.odkladanyProcentNaKoniecMiesiaca"
          name="odkladanyProcentNaKoniecMiesiaca"
        />
        <div
          class="error-info fs__error pt-2"
          *ngIf="czyBladWalidacyjny('odkladanyProcentNaKoniecMiesiaca')"
        >
          {{ pobierzBladWalidacyjny("odkladanyProcentNaKoniecMiesiaca") }}
        </div>
      </div>
      <div
        class="input_container pb-3"
        [ngClass]="czyBladWalidacyjny('odJakiejKwotyOdkladac') ? 'error' : ''"
      >
        <input
          type="number"
          max="100"
          min="1"
          placeholder="Od jakiej kwoty odkładać"
          [(ngModel)]="model.odJakiejKwotyOdkladac"
          name="odJakiejKwotyOdkladac"
        />
        <div
          class="error-info fs__error pt-2"
          *ngIf="czyBladWalidacyjny('odJakiejKwotyOdkladac')"
        >
          {{ pobierzBladWalidacyjny("odJakiejKwotyOdkladac") }}
        </div>
      </div>

      <div *ngIf="model.wersjaBota == 'v2'">
        <div
          class="input_container pb-3"
          [ngClass]="czyBladWalidacyjny('predykcjaWaga5m') ? 'error' : ''"
        >
          Waga 5m
          <input
            type="number"
            max="1"
            min="0"
            placeholder="Waga 5m"
            [(ngModel)]="model.predykcjaWaga5m"
            name="predykcjaWaga5m"
          />
          <div
            class="error-info fs__error pt-2"
            *ngIf="czyBladWalidacyjny('predykcjaWaga5m')"
          >
            {{ pobierzBladWalidacyjny("predykcjaWaga5m") }}
          </div>
        </div>
        <div
          class="input_container pb-3"
          [ngClass]="czyBladWalidacyjny('predykcjaWaga15m') ? 'error' : ''"
        >
          Waga 15m
          <input
            type="number"
            max="1"
            min="0"
            placeholder="Waga 15m"
            [(ngModel)]="model.predykcjaWaga15m"
            name="predykcjaWaga15m"
          />
          <div
            class="error-info fs__error pt-2"
            *ngIf="czyBladWalidacyjny('predykcjaWaga15m')"
          >
            {{ pobierzBladWalidacyjny("predykcjaWaga15m") }}
          </div>
        </div>
        <div
          class="input_container pb-3"
          [ngClass]="czyBladWalidacyjny('predykcjaWaga1h') ? 'error' : ''"
        >
          Waga 1h
          <input
            type="number"
            max="1"
            min="0"
            placeholder="Waga 1h"
            [(ngModel)]="model.predykcjaWaga1h"
            name="predykcjaWaga1h"
          />
          <div
            class="error-info fs__error pt-2"
            *ngIf="czyBladWalidacyjny('predykcjaWaga1h')"
          >
            {{ pobierzBladWalidacyjny("predykcjaWaga1h") }}
          </div>
        </div>
        <div
          class="input_container pb-3"
          [ngClass]="czyBladWalidacyjny('predykcjaWaga4h') ? 'error' : ''"
        >
          Waga 4h
          <input
            type="number"
            max="1"
            min="0"
            placeholder="Waga 4h"
            [(ngModel)]="model.predykcjaWaga4h"
            name="predykcjaWaga4h"
          />
          <div
            class="error-info fs__error pt-2"
            *ngIf="czyBladWalidacyjny('predykcjaWaga4h')"
          >
            {{ pobierzBladWalidacyjny("predykcjaWaga4h") }}
          </div>
        </div>
        <div
          class="input_container pb-3"
          [ngClass]="czyBladWalidacyjny('predykcjaWaga1d') ? 'error' : ''"
        >
          Waga 1d
          <input
            type="number"
            max="1"
            min="0"
            placeholder="Waga 1d"
            [(ngModel)]="model.predykcjaWaga1d"
            name="predykcjaWaga1d"
          />
          <div
            class="error-info fs__error pt-2"
            *ngIf="czyBladWalidacyjny('predykcjaWaga1d')"
          >
            {{ pobierzBladWalidacyjny("predykcjaWaga1d") }}
          </div>
        </div>
        <div
          class="input_container pb-3"
          [ngClass]="czyBladWalidacyjny('predykcjaWaga3d') ? 'error' : ''"
        >
          Waga 3d
          <input
            type="number"
            max="1"
            min="0"
            placeholder="Waga 3d"
            [(ngModel)]="model.predykcjaWaga3d"
            name="predykcjaWaga3d"
          />
          <div
            class="error-info fs__error pt-2"
            *ngIf="czyBladWalidacyjny('predykcjaWaga3d')"
          >
            {{ pobierzBladWalidacyjny("predykcjaWaga3d") }}
          </div>
        </div>
        <div
          class="input_container pb-3"
          [ngClass]="
            czyBladWalidacyjny('predykcjaMarginesBledu') ? 'error' : ''
          "
        >
          Margines błędu
          <input
            type="number"
            max="1"
            min="0"
            placeholder="Margines błędu"
            [(ngModel)]="model.predykcjaMarginesBledu"
            name="predykcjaMarginesBledu"
          />
          <div
            class="error-info fs__error pt-2"
            *ngIf="czyBladWalidacyjny('predykcjaMarginesBledu')"
          >
            {{ pobierzBladWalidacyjny("predykcjaMarginesBledu") }}
          </div>
        </div>
        <div class="chb por mb-3">
          <input
            id="cb_czyPrzesuwacGranice"
            type="checkbox"
            [(ngModel)]="model.czyPrzesuwacGranice"
            name="czyPrzesuwacGranice"
          />
          <label class="fs__chb" for="cb_czyPrzesuwacGranice"
            >Czy przesuwać granice SL i TP</label
          >
        </div>

        <div
          *ngIf="model.czyPrzesuwacGranice"
          class="input_container pb-3"
          [ngClass]="
            czyBladWalidacyjny('przesuniecieGranicyTPLongProcent')
              ? 'error'
              : ''
          "
        >
          Long TP procent
          <input
            type="number"
            max="1"
            min="0"
            placeholder="Long TP procent"
            [(ngModel)]="model.przesuniecieGranicyTPLongProcent"
            name="przesuniecieGranicyTPLongProcent"
          />
          <div
            class="error-info fs__error pt-2"
            *ngIf="czyBladWalidacyjny('przesuniecieGranicyTPLongProcent')"
          >
            {{ pobierzBladWalidacyjny("przesuniecieGranicyTPLongProcent") }}
          </div>
        </div>

        <div
          *ngIf="model.czyPrzesuwacGranice"
          class="input_container pb-3"
          [ngClass]="
            czyBladWalidacyjny('przesuniecieGranicySLLongProcent')
              ? 'error'
              : ''
          "
        >
          Long SL procent
          <input
            type="number"
            max="1"
            min="0"
            placeholder="Long SL procent"
            [(ngModel)]="model.przesuniecieGranicySLLongProcent"
            name="przesuniecieGranicySLLongProcent"
          />
          <div
            class="error-info fs__error pt-2"
            *ngIf="czyBladWalidacyjny('przesuniecieGranicySLLongProcent')"
          >
            {{ pobierzBladWalidacyjny("przesuniecieGranicySLLongProcent") }}
          </div>
        </div>
        <div
          *ngIf="model.czyPrzesuwacGranice"
          class="input_container pb-3"
          [ngClass]="
            czyBladWalidacyjny('przesuniecieGranicyTPShortProcent')
              ? 'error'
              : ''
          "
        >
          Short TP procent
          <input
            type="number"
            max="1"
            min="0"
            placeholder="Short TP procent"
            [(ngModel)]="model.przesuniecieGranicyTPShortProcent"
            name="przesuniecieGranicyTPShortProcent"
          />
          <div
            class="error-info fs__error pt-2"
            *ngIf="czyBladWalidacyjny('przesuniecieGranicyTPShortProcent')"
          >
            {{ pobierzBladWalidacyjny("przesuniecieGranicyTPShortProcent") }}
          </div>
        </div>
        <div
          *ngIf="model.czyPrzesuwacGranice"
          class="input_container pb-3"
          [ngClass]="
            czyBladWalidacyjny('przesuniecieGranicySLShortProcent')
              ? 'error'
              : ''
          "
        >
          Short SL procent
          <input
            type="number"
            max="1"
            min="0"
            placeholder="Short SL procent"
            [(ngModel)]="model.przesuniecieGranicySLShortProcent"
            name="przesuniecieGranicySLShortProcent"
          />
          <div
            class="error-info fs__error pt-2"
            *ngIf="czyBladWalidacyjny('przesuniecieGranicySLShortProcent')"
          >
            {{ pobierzBladWalidacyjny("przesuniecieGranicySLShortProcent") }}
          </div>
        </div>
      </div>

      <div class="input_container pb-3">
        <button class="btn__primary" (click)="zapisz()">Zapisz</button>
      </div>
      <div class="input_container pb-3">
        <button class="btn__link" (click)="activeModal.dismiss()">
          Anuluj
        </button>
      </div>
    </form>
  </div>
</div>
