import { Injector, NgModule } from '@angular/core';

/** Moduł wstrzykiwacza zależności bez użycia konstruktora */
@NgModule()
export class AppInjectorModule {
  constructor(private injector: Injector) {
    AppInjector = this.injector;
  }
}

export let AppInjector: Injector;
