import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { PobierzKonfiguracjeGieldRezultat } from 'src/app/modele/futures/pobierzKonfiguracjeGieldRezultat';
import { FuturesService } from 'src/app/serwisy/futures/futures.service';
import { AuthService } from 'src/app/serwisy/ogolne/auth.service';
import { LoaderService } from 'src/app/serwisy/ogolne/loader.service';
import { BazowyComponent } from '../../bazowy.component';
import { PobierzAktualnePozycjeFuturesRezultat } from 'src/app/modele/futures/pobierzAktualnePozycjeFuturesRezultat';
import { RejestrFiltrowanyRequest } from 'src/app/modele/wspolne/rejestrFiltrowanyRequest';
import { PobierzAktualnePozycjeFuturesFiltry } from 'src/app/modele/futures/pobierzAktualnePozycjeFuturesFiltry';
import {
  KierunekSortowania,
  Sortowanie,
} from 'src/app/modele/wspolne/sortowanie';
import { Stronicowanie } from 'src/app/modele/wspolne/stronicowanie';
import { TakNieModalComponent } from '../../ogolne/tak-nie-modal/tak-nie-modal.component';
import { ModalOpcje } from 'src/app/modele/wspolne/modal-opcje.ogolne';
import { IdModel } from 'src/app/modele/wspolne/id.model';
import { Subscription, timer } from 'rxjs';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-aktualne-pozycje',
  templateUrl: './aktualne-pozycje.component.html',
  styleUrls: ['./aktualne-pozycje.component.css'],
  providers: [DatePipe],
})
export class AktualnePozycjeComponent
  extends BazowyComponent
  implements OnInit, OnDestroy
{
  czyPokazacMenuSortowanie: boolean;
  czyPokazanoWyszukiwarkeMobile: boolean;
  subscription: Subscription;
  czyZaladowanoDane: boolean;
  calkowitaLiczbaElementow: BigInteger;
  rozmiarTabeli: number = 25;
  czyJestWiecejRekordow: boolean = true;
  pozycje: Array<PobierzAktualnePozycjeFuturesRezultat> = new Array();
  wyszukiwanie: RejestrFiltrowanyRequest<PobierzAktualnePozycjeFuturesFiltry> =
    new RejestrFiltrowanyRequest<PobierzAktualnePozycjeFuturesFiltry>();

  constructor(
    public router: Router,
    modalService: NgbModal,
    loaderSerwis: LoaderService,
    komunikatSerwis: ToastrService,
    public authService: AuthService,
    private datePipe: DatePipe,
    public futuresSerwis: FuturesService
  ) {
    super(authService, modalService, loaderSerwis, komunikatSerwis, router);
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.wyszukiwanie.filtry = new PobierzAktualnePozycjeFuturesFiltry();
      this.wyszukiwanie.sortowanie = new Sortowanie(
        'dataTransakcji',
        KierunekSortowania.desc
      );
      this.wyszukiwanie.stronicowanie = new Stronicowanie(false);
      this.wyszukiwanie.stronicowanie.iloscRekordow = this.rozmiarTabeli;
      this.wyszukiwanie.stronicowanie.rekordPoczatkowy = 0;
      this.wyszukiwanie.stronicowanie.strona = 1;

      this.pobierzDaneDoEkranu(true);

      this.subscription = timer(5000, 5000).subscribe((x) => {
        this.pobierzDaneDoEkranu(false);
      });
    });
  }

  pobierzDaneDoEkranu(zLadowaczem: boolean) {
    this.futuresSerwis
      .pobierzAktualnePozycjeFutures(this.wyszukiwanie, zLadowaczem)
      .subscribe((rezultat) => {
        if (this.czyRezultatOk(rezultat)) {
          this.pozycje = rezultat.listaElementow;
          this.czyZaladowanoDane = true;
          this.calkowitaLiczbaElementow = rezultat.calkowitaIloscRekordow;
          this.czyJestWiecejRekordow = rezultat.czyJestWiecejRekordow;
          return;
        }

        this.wyswietlKomunikat(rezultat.wiadomosc, true);
        return;
      });
  }

  zamknijPozycje(id: string) {
    this.modalReference = this.modalService.open(
      TakNieModalComponent,
      new ModalOpcje(true, 'static')
    );
    this.modalReference.componentInstance.naglowek = 'Zamykanie pozycji';
    this.modalReference.componentInstance.pytanie =
      'Czy na pewno chcesz zamknąć pozycję?';
    this.modalReference.result.then(
      (result) => {
        if (result) {
          var model = new IdModel();
          model.id = id;
          this.futuresSerwis.sprzedajPozycje(model).subscribe((rezultat) => {
            if (this.czyRezultatOk(rezultat)) {
              this.wyswietlKomunikat(
                'Zapisano poprawnie - pozycja jest w trakcie zamykania'
              );
              return;
            }

            this.wyswietlKomunikat(rezultat.wiadomosc, true);
            return;
          });
        }
      },
      (onRejected) => {}
    );
  }

  procent(pozycja: PobierzAktualnePozycjeFuturesRezultat) {
    if (
      pozycja.rodzajTransakcji == 'Long' &&
      pozycja.aktualnaCena - pozycja.cenaSrednia > 0
    ) {
      return ((pozycja.aktualnaCena / pozycja.cenaSrednia) * 100 - 100).toFixed(
        2
      );
    }

    if (
      pozycja.rodzajTransakcji == 'Long' &&
      pozycja.aktualnaCena - pozycja.cenaSrednia < 0
    ) {
      return ((pozycja.cenaSrednia / pozycja.aktualnaCena) * 100 - 100).toFixed(
        2
      );
    }

    if (
      pozycja.rodzajTransakcji == 'Short' &&
      pozycja.aktualnaCena - pozycja.cenaSrednia < 0
    ) {
      return ((pozycja.cenaSrednia / pozycja.aktualnaCena) * 100 - 100).toFixed(
        2
      );
    }

    if (
      pozycja.rodzajTransakcji == 'Short' &&
      pozycja.aktualnaCena - pozycja.cenaSrednia > 0
    ) {
      return ((pozycja.aktualnaCena / pozycja.cenaSrednia) * 100 - 100).toFixed(
        2
      );
    }

    return '0';
  }

  pozycjaNaPlus(pozycja: PobierzAktualnePozycjeFuturesRezultat) {
    if (
      pozycja.rodzajTransakcji == 'Long' &&
      pozycja.aktualnaCena - pozycja.cenaSrednia > 0
    ) {
      return true;
    }

    if (
      pozycja.rodzajTransakcji == 'Short' &&
      pozycja.aktualnaCena - pozycja.cenaSrednia < 0
    ) {
      return true;
    }
    return false;
  }

  wynik(pozycja: PobierzAktualnePozycjeFuturesRezultat) {
    const procent = this.procent(pozycja);
    return (parseFloat(procent) * pozycja.cenaCalkowitaTransakcji) / 100;
  }

  znakPozycji(pozycja) {
    return this.pozycjaNaPlus(pozycja) ? '+' : '-';
  }

  zamknijWszystko() {
    this.modalReference = this.modalService.open(
      TakNieModalComponent,
      new ModalOpcje(true, 'static')
    );
    this.modalReference.componentInstance.naglowek = 'Zamykanie pozycji';
    this.modalReference.componentInstance.pytanie =
      'Czy na pewno chcesz sprzedać otwarte pozycje?';
    this.modalReference.result.then(
      (result) => {
        if (result) {
          this.futuresSerwis.zamknijWszystkiePozycje().subscribe((rezultat) => {
            if (this.czyRezultatOk(rezultat)) {
              this.wyswietlKomunikat('Otwarte pozycje są w trakcie zamykania');
              return;
            }

            this.wyswietlKomunikat(rezultat.wiadomosc, true);
            return;
          });
        }
      },
      (onRejected) => {}
    );
  }

  zmienRozmiarTabeli() {
    this.wyszukiwanie.stronicowanie.iloscRekordow = this.rozmiarTabeli;
    this.pobierzDaneDoEkranu(true);
  }

  pobierzNumerRekorduPoczatek() {
    if (this.wyszukiwanie.stronicowanie == undefined) {
      return 1;
    }
    return this.wyszukiwanie.stronicowanie.rekordPoczatkowy + 1;
  }
  pobierzNumerRekorduKoniec() {
    if (this.wyszukiwanie.stronicowanie == undefined) {
      return 1;
    }
    return (
      this.wyszukiwanie.stronicowanie.rekordPoczatkowy + this.pozycje.length
    );
  }

  zmienStrone(oIle: number) {
    this.wyszukiwanie.stronicowanie.strona += oIle;
    this.wyszukiwanie.stronicowanie.rekordPoczatkowy =
      (this.wyszukiwanie.stronicowanie.strona - 1) *
      this.wyszukiwanie.stronicowanie.iloscRekordow;
    this.pobierzDaneDoEkranu(true);
  }

  pokazMenuSortowania() {
    this.czyPokazacMenuSortowanie = !this.czyPokazacMenuSortowanie;
  }

  pobierzKierunekSortowania(kolumna: string) {
    var kierunki = this.wyszukiwanie.sortowanie.listaSortowania;

    var kierunek = kierunki.find((m) => m.nazwaKolumny == kolumna);

    if (kierunek == undefined) {
      return '';
    }

    if (kierunek.kierunekSortowania == KierunekSortowania.asc) {
      return 'up';
    }
    if (kierunek.kierunekSortowania == KierunekSortowania.desc) {
      return 'down';
    }
  }

  zmienKierunekSortowania(kolumna: string) {
    this.czyPokazacMenuSortowanie = false;
    var kierunki = this.wyszukiwanie.sortowanie.listaSortowania;

    var kierunek = kierunki.find((m) => m.nazwaKolumny == kolumna);

    if (kierunek == undefined) {
      this.wyszukiwanie.sortowanie = new Sortowanie(
        kolumna,
        KierunekSortowania.asc
      );
      this.pobierzDaneDoEkranu(true);
      return;
    }

    if (kierunek.kierunekSortowania == KierunekSortowania.asc) {
      this.wyszukiwanie.sortowanie = new Sortowanie(
        kolumna,
        KierunekSortowania.desc
      );
      this.pobierzDaneDoEkranu(true);
      return;
    }
    if (kierunek.kierunekSortowania == KierunekSortowania.desc) {
      this.wyszukiwanie.sortowanie = new Sortowanie(
        'dataTransakcji',
        KierunekSortowania.asc
      );
      this.pobierzDaneDoEkranu(true);
      return;
    }
  }

  pobierzKolumneSortowania() {
    var kierunki = this.wyszukiwanie.sortowanie.listaSortowania;

    switch (kierunki[0].nazwaKolumny) {
      case 'gielda':
        return 'Giełda';
      case 'rodzajKonta':
        return 'Rodzaj konta';
      case 'czyjeKonto':
        return 'Czyje konto';
      case 'dataTransakcji':
        return 'Data transakcji';
      case 'rodzajTransakcji':
        return 'Rodzaj transakcji';
      case 'cenaSrednia':
        return 'Cena średnia';
      case 'ilosc':
        return 'Ilość';
      case 'cenaCalkowitaTransakcji':
        return 'Cena całkowita transakcji';
      case 'krypto':
        return 'Krypto';
      case 'planowanaCenaSprzedazy':
        return 'Planowana cena sprzedaży';
      case 'aktualnaCena':
        return 'Aktualna cena';
      case 'procent':
        return 'Procent';
      case 'wynik':
        return 'Wynik';
    }
  }

  pobierzKierunekSortowaniaMobile() {
    var kierunki = this.wyszukiwanie.sortowanie.listaSortowania;

    if (kierunki[0].kierunekSortowania == KierunekSortowania.asc) {
      return 'up';
    }
    if (kierunki[0].kierunekSortowania == KierunekSortowania.desc) {
      return '';
    }
  }

  zmienKierunekSortowaniaMobile() {
    var kierunki = this.wyszukiwanie.sortowanie.listaSortowania;

    var kierunek = kierunki[0];

    if (kierunek.kierunekSortowania == KierunekSortowania.asc) {
      this.wyszukiwanie.sortowanie = new Sortowanie(
        kierunek.nazwaKolumny,
        KierunekSortowania.desc
      );
      this.pobierzDaneDoEkranu(true);
      return;
    }
    if (kierunek.kierunekSortowania == KierunekSortowania.desc) {
      this.wyszukiwanie.sortowanie = new Sortowanie(
        kierunek.nazwaKolumny,
        KierunekSortowania.asc
      );
      this.pobierzDaneDoEkranu(true);
      return;
    }
  }
  wyczyscSortowanieMobile() {
    this.wyszukiwanie.sortowanie = new Sortowanie('Id', KierunekSortowania.asc);
    this.pobierzDaneDoEkranu(true);
  }

  filtrujPoTekscie(wartosc: string) {
    this.subscription?.unsubscribe();
    this.wyszukiwanie.stronicowanie.rekordPoczatkowy = 0;
    this.wyszukiwanie.stronicowanie.strona = 1;
    this.pobierzDaneDoEkranu(false);
  }

  czyPokazacWyczyscFiltry() {
    return (
      this.wyszukiwanie.filtry.cenaSrednia ||
      this.wyszukiwanie.filtry.rodzajTransakcji ||
      this.wyszukiwanie.filtry.dataTransakcji ||
      this.wyszukiwanie.filtry.czyjeKonto ||
      this.wyszukiwanie.filtry.gielda ||
      this.wyszukiwanie.filtry.rodzajKonta ||
      this.wyszukiwanie.filtry.ilosc ||
      this.wyszukiwanie.filtry.cenaCalkowitaTransakcji ||
      this.wyszukiwanie.filtry.krypto ||
      this.wyszukiwanie.filtry.planowanaCenaSprzedazy ||
      this.wyszukiwanie.filtry.aktualnaCena
    );
  }

  wyczyscFiltry() {
    this.wyszukiwanie.filtry.cenaSrednia = '';
    this.wyszukiwanie.filtry.rodzajTransakcji = '';
    this.wyszukiwanie.filtry.dataTransakcji = '';
    this.wyszukiwanie.filtry.czyjeKonto = '';
    this.wyszukiwanie.filtry.gielda = '';
    this.wyszukiwanie.filtry.rodzajKonta = '';
    this.wyszukiwanie.filtry.ilosc = '';
    this.wyszukiwanie.filtry.cenaCalkowitaTransakcji = '';
    this.wyszukiwanie.filtry.krypto = '';
    this.wyszukiwanie.filtry.planowanaCenaSprzedazy = '';
    this.wyszukiwanie.filtry.aktualnaCena = '';

    this.pobierzDaneDoEkranu(true);
  }

  pokazWyszukiwarkeMobile(czyPokazac: boolean) {
    this.czyPokazanoWyszukiwarkeMobile = czyPokazac;
  }

  formatujDate(data: Date) {
    return this.datePipe.transform(data, 'yyyy-MM-dd, HH:mm');
  }
}
