<div>
  <div class="container--main d-none d-md-block">
    <div class="ft__sort text-center pt-2">Testowanie konfiguracji</div>
  </div>
  <div class="float-container d-none d-md-block">
    <div class="float-child-left">
      <form action="">
        <div class="input_container mb-4">
          <select
            [(ngModel)]="model.krypto"
            name="krypto"
            (change)="wybierzKrypto()"
          >
            <option [ngValue]="undefined" [disabled]="true">Krypto</option>
            <option
              [ngValue]="krypto.key"
              *ngFor="let krypto of dostepneKrypto"
            >
              {{ krypto.value }}
            </option>
          </select>
        </div>
        <div class="input_container mb-4">
          <select [(ngModel)]="model.gieldaId" name="gielda">
            <option [ngValue]="undefined" [disabled]="true">Giełda</option>
            <option [ngValue]="gielda.id" *ngFor="let gielda of gieldy">
              {{ gielda.gielda }} - {{ gielda.rodzajKonta }} -
              {{ gielda.czyjeKonto }}
            </option>
          </select>
        </div>
        <div
          class="input_container pb-3"
          [ngClass]="czyBladWalidacyjny('dataOd') ? 'error' : ''"
        >
          Data od
          <input
            type="date"
            placeholder="Data od"
            [(ngModel)]="model.dataOd"
            name="dataOd"
          />
          <div
            class="error-info fs__error pt-2"
            *ngIf="czyBladWalidacyjny('dataOd')"
          >
            {{ pobierzBladWalidacyjny("dataOd") }}
          </div>
        </div>
        <div
          class="input_container pb-3"
          [ngClass]="czyBladWalidacyjny('dataDo') ? 'error' : ''"
        >
          Data do
          <input
            type="date"
            placeholder="Data do"
            [(ngModel)]="model.dataDo"
            name="dataDo"
          />
          <div
            class="error-info fs__error pt-2"
            *ngIf="czyBladWalidacyjny('dataDo')"
          >
            {{ pobierzBladWalidacyjny("dataDo") }}
          </div>
        </div>

        <div
          class="input_container pb-3"
          [ngClass]="czyBladWalidacyjny('tpl') ? 'error' : ''"
        >
          Long take profit %
          <input
            type="number"
            placeholder="Long take profit %"
            [(ngModel)]="model.tpl"
            name="tpl"
          />
          <div
            class="error-info fs__error pt-2"
            *ngIf="czyBladWalidacyjny('tpl')"
          >
            {{ pobierzBladWalidacyjny("tpl") }}
          </div>
        </div>
        <div
          class="input_container pb-3"
          [ngClass]="czyBladWalidacyjny('sll') ? 'error' : ''"
        >
          Long stop loss %
          <input
            type="number"
            placeholder="Long stop loss %"
            [(ngModel)]="model.sll"
            name="sll"
          />
          <div
            class="error-info fs__error pt-2"
            *ngIf="czyBladWalidacyjny('sll')"
          >
            {{ pobierzBladWalidacyjny("sll") }}
          </div>
        </div>
        <div
          class="input_container pb-3"
          [ngClass]="czyBladWalidacyjny('tps') ? 'error' : ''"
        >
          Short take profit %
          <input
            type="number"
            placeholder="Short take profit %"
            [(ngModel)]="model.tps"
            name="tps"
          />
          <div
            class="error-info fs__error pt-2"
            *ngIf="czyBladWalidacyjny('tps')"
          >
            {{ pobierzBladWalidacyjny("tps") }}
          </div>
        </div>
        <div
          class="input_container pb-3"
          [ngClass]="czyBladWalidacyjny('sls') ? 'error' : ''"
        >
          Short stop loss %
          <input
            type="number"
            placeholder="Short stop loss %"
            [(ngModel)]="model.sls"
            name="sls"
          />
          <div
            class="error-info fs__error pt-2"
            *ngIf="czyBladWalidacyjny('sls')"
          >
            {{ pobierzBladWalidacyjny("sls") }}
          </div>
        </div>
        <ng-select
          [items]="dzwignie"
          bindLabel="name"
          bindValue="id"
          placeholder="Dźwignie"
          appendTo="body"
          [multiple]="true"
          [(ngModel)]="model.dzwignie"
          [ngModelOptions]="{ standalone: true }"
        >
        </ng-select>

        <div
          class="input_container pb-3"
          [ngClass]="czyBladWalidacyjny('budzet') ? 'error' : ''"
        >
          Budżet $
          <input
            type="number"
            placeholder="Budżet"
            [(ngModel)]="model.budzet"
            name="budzet"
          />
          <div
            class="error-info fs__error pt-2"
            *ngIf="czyBladWalidacyjny('budzet')"
          >
            {{ pobierzBladWalidacyjny("budzet") }}
          </div>
        </div>

        <div
          class="input_container pb-3"
          [ngClass]="czyBladWalidacyjny('procentKapitalu') ? 'error' : ''"
        >
          Procent kapitału %
          <input
            type="number"
            placeholder="Procent kapitału"
            [(ngModel)]="model.procentKapitalu"
            name="procentKapitalu"
          />
          <div
            class="error-info fs__error pt-2"
            *ngIf="czyBladWalidacyjny('procentKapitalu')"
          >
            {{ pobierzBladWalidacyjny("procentKapitalu") }}
          </div>
        </div>
        <div
          class="input_container pb-3"
          [ngClass]="czyBladWalidacyjny('maxGodzinPozycji') ? 'error' : ''"
        >
          Max godzin pozycji
          <input
            type="number"
            placeholder="Max godzin pozycji"
            [(ngModel)]="model.maxGodzinPozycji"
            name="sll"
          />
          <div
            class="error-info fs__error pt-2"
            *ngIf="czyBladWalidacyjny('maxGodzinPozycji')"
          >
            {{ pobierzBladWalidacyjny("maxGodzinPozycji") }}
          </div>
        </div>
        <div
          class="input_container pb-3"
          [ngClass]="czyBladWalidacyjny('odJakiejKwotyOdkladac') ? 'error' : ''"
        >
          Od jakiej kwoty odkładać $
          <input
            type="number"
            placeholder="Od jakiej kwoty odkładać"
            [(ngModel)]="model.odJakiejKwotyOdkladac"
            name="odJakiejKwotyOdkladac"
          />
          <div
            class="error-info fs__error pt-2"
            *ngIf="czyBladWalidacyjny('odJakiejKwotyOdkladac')"
          >
            {{ pobierzBladWalidacyjny("odJakiejKwotyOdkladac") }}
          </div>
        </div>

        <div
          class="input_container pb-3"
          [ngClass]="
            czyBladWalidacyjny('odkladanyProcentNaKoniecMiesiaca')
              ? 'error'
              : ''
          "
        >
          Odkładany procent na koniec miesiąca %
          <input
            type="number"
            placeholder="Odkładany procent na koniec miesiąca"
            [(ngModel)]="model.odkladanyProcentNaKoniecMiesiaca"
            name="odkladanyProcentNaKoniecMiesiaca"
          />
          <div
            class="error-info fs__error pt-2"
            *ngIf="czyBladWalidacyjny('odkladanyProcentNaKoniecMiesiaca')"
          >
            {{ pobierzBladWalidacyjny("odkladanyProcentNaKoniecMiesiaca") }}
          </div>
        </div>
        <div
          class="input_container pb-3"
          [ngClass]="czyBladWalidacyjny('maxWielkoscPozycji') ? 'error' : ''"
        >
          Max wielkość pozycji $
          <input
            type="number"
            placeholder="Max wielkość pozycji"
            [(ngModel)]="model.maxWielkoscPozycji"
            name="maxWielkoscPozycji"
          />
          <div
            class="error-info fs__error pt-2"
            *ngIf="czyBladWalidacyjny('maxWielkoscPozycji')"
          >
            {{ pobierzBladWalidacyjny("maxWielkoscPozycji") }}
          </div>
        </div>
        <div
          class="input_container pb-3"
          [ngClass]="czyBladWalidacyjny('rsiL') ? 'error' : ''"
        >
          RSI
          <input
            type="number"
            placeholder="RSI"
            [(ngModel)]="model.rsiL"
            name="rsiL"
          />
          <div
            class="error-info fs__error pt-2"
            *ngIf="czyBladWalidacyjny('rsiL')"
          >
            {{ pobierzBladWalidacyjny("rsiL") }}
          </div>
        </div>

        <div class="input_container pb-3">
          macdSL
          <input
            type="number"
            placeholder="macdSL"
            [(ngModel)]="model.macdSL"
            name="macdSL"
          />
        </div>
        <div class="input_container pb-3">
          macdSS
          <input
            type="number"
            placeholder="macdSS"
            [(ngModel)]="model.macdSS"
            name="macdSS"
          />
        </div>
        <div class="input_container pb-3">
          macdLS
          <input
            type="number"
            placeholder="macdLS"
            [(ngModel)]="model.macdLS"
            name="macdLS"
          />
        </div>
      </form>
    </div>

    <div class="float-child-right">
      <div [innerText]="rezultat"></div>
    </div>
  </div>

  <!-- mobile -->
  <div class="container-fluid d-md-none pb-5">
    <div class="pt-3 mt-1">
      <div class="ft__sort text-center">Testowanie konfiguracji</div>
    </div>

    <div class="box__mobiled mb-4">
      <form action="">
        <div class="input_container mb-4">
          <select
            [(ngModel)]="model.krypto"
            name="krypto"
            (change)="wybierzKrypto()"
          >
            <option [ngValue]="undefined" [disabled]="true">Krypto</option>
            <option
              [ngValue]="krypto.key"
              *ngFor="let krypto of dostepneKrypto"
            >
              {{ krypto.value }}
            </option>
          </select>
        </div>
        <div class="input_container mb-4">
          <select [(ngModel)]="model.gieldaId" name="gielda">
            <option [ngValue]="undefined" [disabled]="true">Giełda</option>
            <option [ngValue]="gielda.id" *ngFor="let gielda of gieldy">
              {{ gielda.gielda }} - {{ gielda.rodzajKonta }} -
              {{ gielda.czyjeKonto }}
            </option>
          </select>
        </div>
        <div
          class="input_container pb-3"
          [ngClass]="czyBladWalidacyjny('dataOd') ? 'error' : ''"
        >
          Data od
          <input
            type="date"
            placeholder="Data od"
            [(ngModel)]="model.dataOd"
            name="dataOd"
          />
          <div
            class="error-info fs__error pt-2"
            *ngIf="czyBladWalidacyjny('dataOd')"
          >
            {{ pobierzBladWalidacyjny("dataOd") }}
          </div>
        </div>
        <div
          class="input_container pb-3"
          [ngClass]="czyBladWalidacyjny('dataDo') ? 'error' : ''"
        >
          Data do
          <input
            type="date"
            placeholder="Data do"
            [(ngModel)]="model.dataDo"
            name="dataDo"
          />
          <div
            class="error-info fs__error pt-2"
            *ngIf="czyBladWalidacyjny('dataDo')"
          >
            {{ pobierzBladWalidacyjny("dataDo") }}
          </div>
        </div>

        <div
          class="input_container pb-3"
          [ngClass]="czyBladWalidacyjny('tpl') ? 'error' : ''"
        >
          Long take profit %
          <input
            type="number"
            placeholder="Long take profit %"
            [(ngModel)]="model.tpl"
            name="tpl"
          />
          <div
            class="error-info fs__error pt-2"
            *ngIf="czyBladWalidacyjny('tpl')"
          >
            {{ pobierzBladWalidacyjny("tpl") }}
          </div>
        </div>
        <div
          class="input_container pb-3"
          [ngClass]="czyBladWalidacyjny('sll') ? 'error' : ''"
        >
          Long stop loss %
          <input
            type="number"
            placeholder="Long stop loss %"
            [(ngModel)]="model.sll"
            name="sll"
          />
          <div
            class="error-info fs__error pt-2"
            *ngIf="czyBladWalidacyjny('sll')"
          >
            {{ pobierzBladWalidacyjny("sll") }}
          </div>
        </div>
        <div
          class="input_container pb-3"
          [ngClass]="czyBladWalidacyjny('tps') ? 'error' : ''"
        >
          Short take profit %
          <input
            type="number"
            placeholder="Short take profit %"
            [(ngModel)]="model.tps"
            name="tps"
          />
          <div
            class="error-info fs__error pt-2"
            *ngIf="czyBladWalidacyjny('tps')"
          >
            {{ pobierzBladWalidacyjny("tps") }}
          </div>
        </div>
        <div
          class="input_container pb-3"
          [ngClass]="czyBladWalidacyjny('sls') ? 'error' : ''"
        >
          Short stop loss %
          <input
            type="number"
            placeholder="Short stop loss %"
            [(ngModel)]="model.sls"
            name="sls"
          />
          <div
            class="error-info fs__error pt-2"
            *ngIf="czyBladWalidacyjny('sls')"
          >
            {{ pobierzBladWalidacyjny("sls") }}
          </div>
        </div>
        <ng-select
          [items]="dzwignie"
          bindLabel="name"
          bindValue="id"
          placeholder="Dźwignie"
          appendTo="body"
          [multiple]="true"
          [(ngModel)]="model.dzwignie"
          [ngModelOptions]="{ standalone: true }"
        >
        </ng-select>

        <div
          class="input_container pb-3"
          [ngClass]="czyBladWalidacyjny('budzet') ? 'error' : ''"
        >
          Budżet $
          <input
            type="number"
            placeholder="Budżet"
            [(ngModel)]="model.budzet"
            name="budzet"
          />
          <div
            class="error-info fs__error pt-2"
            *ngIf="czyBladWalidacyjny('budzet')"
          >
            {{ pobierzBladWalidacyjny("budzet") }}
          </div>
        </div>

        <div
          class="input_container pb-3"
          [ngClass]="czyBladWalidacyjny('procentKapitalu') ? 'error' : ''"
        >
          Procent kapitału %
          <input
            type="number"
            placeholder="Procent kapitału"
            [(ngModel)]="model.procentKapitalu"
            name="procentKapitalu"
          />
          <div
            class="error-info fs__error pt-2"
            *ngIf="czyBladWalidacyjny('procentKapitalu')"
          >
            {{ pobierzBladWalidacyjny("procentKapitalu") }}
          </div>
        </div>
        <div
          class="input_container pb-3"
          [ngClass]="czyBladWalidacyjny('maxGodzinPozycji') ? 'error' : ''"
        >
          Max godzin pozycji
          <input
            type="number"
            placeholder="Max godzin pozycji"
            [(ngModel)]="model.maxGodzinPozycji"
            name="sll"
          />
          <div
            class="error-info fs__error pt-2"
            *ngIf="czyBladWalidacyjny('maxGodzinPozycji')"
          >
            {{ pobierzBladWalidacyjny("maxGodzinPozycji") }}
          </div>
        </div>
        <div
          class="input_container pb-3"
          [ngClass]="czyBladWalidacyjny('odJakiejKwotyOdkladac') ? 'error' : ''"
        >
          Od jakiej kwoty odkładać $
          <input
            type="number"
            placeholder="Od jakiej kwoty odkładać"
            [(ngModel)]="model.odJakiejKwotyOdkladac"
            name="odJakiejKwotyOdkladac"
          />
          <div
            class="error-info fs__error pt-2"
            *ngIf="czyBladWalidacyjny('odJakiejKwotyOdkladac')"
          >
            {{ pobierzBladWalidacyjny("odJakiejKwotyOdkladac") }}
          </div>
        </div>

        <div
          class="input_container pb-3"
          [ngClass]="
            czyBladWalidacyjny('odkladanyProcentNaKoniecMiesiaca')
              ? 'error'
              : ''
          "
        >
          Odkładany procent na koniec miesiąca %
          <input
            type="number"
            placeholder="Odkładany procent na koniec miesiąca"
            [(ngModel)]="model.odkladanyProcentNaKoniecMiesiaca"
            name="odkladanyProcentNaKoniecMiesiaca"
          />
          <div
            class="error-info fs__error pt-2"
            *ngIf="czyBladWalidacyjny('odkladanyProcentNaKoniecMiesiaca')"
          >
            {{ pobierzBladWalidacyjny("odkladanyProcentNaKoniecMiesiaca") }}
          </div>
        </div>
        <div
          class="input_container pb-3"
          [ngClass]="czyBladWalidacyjny('maxWielkoscPozycji') ? 'error' : ''"
        >
          Max wielkość pozycji $
          <input
            type="number"
            placeholder="Max wielkość pozycji"
            [(ngModel)]="model.maxWielkoscPozycji"
            name="maxWielkoscPozycji"
          />
          <div
            class="error-info fs__error pt-2"
            *ngIf="czyBladWalidacyjny('maxWielkoscPozycji')"
          >
            {{ pobierzBladWalidacyjny("maxWielkoscPozycji") }}
          </div>
        </div>
        <div
          class="input_container pb-3"
          [ngClass]="czyBladWalidacyjny('rsiL') ? 'error' : ''"
        >
          RSI
          <input
            type="number"
            placeholder="RSI"
            [(ngModel)]="model.rsiL"
            name="rsiL"
          />
          <div
            class="error-info fs__error pt-2"
            *ngIf="czyBladWalidacyjny('rsiL')"
          >
            {{ pobierzBladWalidacyjny("rsiL") }}
          </div>
        </div>

        <div class="input_container pb-3">
          macdSL
          <input
            type="number"
            placeholder="macdSL"
            [(ngModel)]="model.macdSL"
            name="macdSL"
          />
        </div>
        <div class="input_container pb-3">
          macdSS
          <input
            type="number"
            placeholder="macdSS"
            [(ngModel)]="model.macdSS"
            name="macdSS"
          />
        </div>
        <div class="input_container pb-3">
          macdLS
          <input
            type="number"
            placeholder="macdLS"
            [(ngModel)]="model.macdLS"
            name="macdLS"
          />
        </div>
      </form>

      <div class="float-child-right-mobile" *ngIf="rezultat">
        <div [innerText]="rezultat"></div>
      </div>
    </div>
  </div>
</div>
<div class="bootom__buttons px-4">
  <div
    class="container--mw600 flex-column flex-sm-row d-flex justify-content-between align-items-center"
  >
    <button
      class="btn__link order-1 order-sm-0"
      (click)="uzupelnijDaneKrypto()"
    >
      Uzupełnij dane krypto
    </button>
    <button class="btn__blue order-0 order-sm-1" (click)="testuj()">
      Testuj
    </button>
  </div>
</div>
