<div class="modal-content">
  <div class="modal-body">
    <button
      aria-label="Close"
      class="close"
      data-dismiss="modal"
      type="button"
      (click)="activeModal.dismiss()"
    ></button>
    <div class="ft__01 text-center py-4">Dodaj konfigurację</div>
    <div class="text-center mb-2"></div>
    <form action="">
      <div class="input_container mb-4">
        <select [(ngModel)]="model.gielda" name="gielda">
          <option [ngValue]="undefined" [disabled]="true">Giełda</option>
          <option [ngValue]="gielda.key" *ngFor="let gielda of gieldy">
            {{ gielda.value }}
          </option>
        </select>
      </div>
      <div class="input_container mb-4">
        <select [(ngModel)]="model.adres" name="adres">
          <option [ngValue]="undefined" [disabled]="true">Adres API</option>
          <option [ngValue]="adres.key" *ngFor="let adres of adresy">
            {{ adres.value }}
          </option>
        </select>
      </div>
      <div
        class="input_container pb-3"
        [ngClass]="czyBladWalidacyjny('rodzajKonta') ? 'error' : ''"
      >
        <input
          type="text"
          placeholder="Rodzaj konta"
          [(ngModel)]="model.rodzajKonta"
          name="rodzajKonta"
        />
        <div
          class="error-info fs__error pt-2"
          *ngIf="czyBladWalidacyjny('rodzajKonta')"
        >
          {{ pobierzBladWalidacyjny("rodzajKonta") }}
        </div>
      </div>

      <div class="input_container mb-4">
        <select [(ngModel)]="model.czyjeKonto" name="czyjeKonto">
          <option [ngValue]="undefined" [disabled]="true">Czyje konto</option>
          <option [ngValue]="konto.key" *ngFor="let konto of czyjeKonta">
            {{ konto.value }}
          </option>
        </select>
      </div>

      <div class="chb por mb-3">
        <input
          id="cb_04"
          type="checkbox"
          [(ngModel)]="model.czyAktywna"
          name="czyAktywna"
        />
        <label class="fs__chb" for="cb_04">Czy aktywna</label>
      </div>

      <div
        class="input_container pb-3"
        [ngClass]="czyBladWalidacyjny('klucz') ? 'error' : ''"
      >
        <input
          type="text"
          placeholder="Klucz"
          [(ngModel)]="model.klucz"
          name="klucz"
        />
        <div
          class="error-info fs__error pt-2"
          *ngIf="czyBladWalidacyjny('klucz')"
        >
          {{ pobierzBladWalidacyjny("klucz") }}
        </div>
      </div>

      <div
        class="input_container pb-3"
        [ngClass]="czyBladWalidacyjny('secret') ? 'error' : ''"
      >
        <input
          type="text"
          placeholder="Secret"
          [(ngModel)]="model.secret"
          name="secret"
        />
        <div
          class="error-info fs__error pt-2"
          *ngIf="czyBladWalidacyjny('secret')"
        >
          {{ pobierzBladWalidacyjny("secret") }}
        </div>
      </div>

      <div class="input_container pb-3">
        <button class="btn__primary" (click)="zapisz()">Zapisz</button>
      </div>
      <div class="input_container pb-3">
        <button class="btn__link" (click)="activeModal.dismiss()">
          Anuluj
        </button>
      </div>
    </form>
  </div>
</div>
