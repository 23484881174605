<div *ngIf="czyZaladowanoDane">
  <div class="container--main d-none d-md-block">
    <div class="ft__sort text-center pt-4">Aktualne pozycje</div>
  </div>

  <div class="container-fluid d-none d-md-block pt-4">
    <div class="box__scroll py-3">
      <div
        class="box__scroll--top d-flex justify-content-between align-items-center mb-3"
      >
        <div class="input_container">
          <button
            class="btn__clear--filter"
            (click)="wyczyscFiltry()"
            *ngIf="czyPokazacWyczyscFiltry()"
          >
            Wyczyść filtry
          </button>
        </div>
      </div>
      <div class="tableFixHead tableFixHead--3">
        <table class="table">
          <thead>
            <tr>
              <th>
                Giełda
                <button
                  class="btn__input--sort--bottom--gray"
                  [ngClass]="pobierzKierunekSortowania('gielda')"
                  (click)="zmienKierunekSortowania('gielda')"
                ></button>
                <div class="input_container input_container--mini">
                  <input
                    class="pr-4"
                    type="text"
                    placeholder="Szukaj"
                    [(ngModel)]="wyszukiwanie.filtry.gielda"
                    (keyup)="filtrujPoTekscie('')"
                  />
                  <button
                    class="btn__input--search"
                    *ngIf="!wyszukiwanie.filtry.gielda"
                  ></button>
                  <button
                    class="btn__input--clear"
                    *ngIf="wyszukiwanie.filtry.gielda"
                    (click)="
                      this.wyszukiwanie.filtry.gielda = '';
                      pobierzDaneDoEkranu(false)
                    "
                  ></button>
                </div>
              </th>
              <th>
                Rodzaj konta
                <button
                  class="btn__input--sort--bottom--gray"
                  [ngClass]="pobierzKierunekSortowania('rodzajKonta')"
                  (click)="zmienKierunekSortowania('rodzajKonta')"
                ></button>
                <div class="input_container input_container--mini">
                  <input
                    class="pr-4"
                    type="text"
                    placeholder="Szukaj"
                    [(ngModel)]="wyszukiwanie.filtry.rodzajKonta"
                    (keyup)="
                      filtrujPoTekscie(this.wyszukiwanie.filtry.rodzajKonta)
                    "
                  />
                  <button
                    class="btn__input--search"
                    *ngIf="!wyszukiwanie.filtry.rodzajKonta"
                  ></button>
                  <button
                    class="btn__input--clear"
                    *ngIf="wyszukiwanie.filtry.rodzajKonta"
                    (click)="
                      this.wyszukiwanie.filtry.rodzajKonta = '';
                      pobierzDaneDoEkranu(false)
                    "
                  ></button>
                </div>
              </th>
              <th>
                Czyje konto
                <button
                  class="btn__input--sort--bottom--gray"
                  [ngClass]="pobierzKierunekSortowania('czyjeKonto')"
                  (click)="zmienKierunekSortowania('czyjeKonto')"
                ></button>
                <div class="input_container input_container--mini">
                  <input
                    class="pr-4"
                    type="text"
                    placeholder="Szukaj"
                    [(ngModel)]="wyszukiwanie.filtry.czyjeKonto"
                    (keyup)="
                      filtrujPoTekscie(this.wyszukiwanie.filtry.czyjeKonto)
                    "
                  />
                  <button
                    class="btn__input--search"
                    *ngIf="!wyszukiwanie.filtry.czyjeKonto"
                  ></button>
                  <button
                    class="btn__input--clear"
                    *ngIf="wyszukiwanie.filtry.czyjeKonto"
                    (click)="
                      this.wyszukiwanie.filtry.czyjeKonto = '';
                      pobierzDaneDoEkranu(false)
                    "
                  ></button>
                </div>
              </th>
              <th>
                Data transakcji
                <button
                  class="btn__input--sort--bottom--gray"
                  [ngClass]="pobierzKierunekSortowania('dataTransakcji')"
                  (click)="zmienKierunekSortowania('dataTransakcji')"
                ></button>
                <div class="input_container input_container--mini">
                  <input
                    class="pr-4"
                    type="text"
                    placeholder="Szukaj"
                    [(ngModel)]="wyszukiwanie.filtry.dataTransakcji"
                    (keyup)="
                      filtrujPoTekscie(this.wyszukiwanie.filtry.dataTransakcji)
                    "
                  />
                  <button
                    class="btn__input--search"
                    *ngIf="!wyszukiwanie.filtry.dataTransakcji"
                  ></button>
                  <button
                    class="btn__input--clear"
                    *ngIf="wyszukiwanie.filtry.dataTransakcji"
                    (click)="
                      this.wyszukiwanie.filtry.dataTransakcji = '';
                      pobierzDaneDoEkranu(false)
                    "
                  ></button>
                </div>
              </th>
              <th>
                Rodzaj transakcji
                <button
                  class="btn__input--sort--bottom--gray"
                  [ngClass]="pobierzKierunekSortowania('rodzajTransakcji')"
                  (click)="zmienKierunekSortowania('rodzajTransakcji')"
                ></button>
                <div class="input_container input_container--mini">
                  <input
                    class="pr-4"
                    type="text"
                    placeholder="Szukaj"
                    [(ngModel)]="wyszukiwanie.filtry.rodzajTransakcji"
                    (keyup)="
                      filtrujPoTekscie(
                        this.wyszukiwanie.filtry.rodzajTransakcji
                      )
                    "
                  />
                  <button
                    class="btn__input--search"
                    *ngIf="!wyszukiwanie.filtry.rodzajTransakcji"
                  ></button>
                  <button
                    class="btn__input--clear"
                    *ngIf="wyszukiwanie.filtry.rodzajTransakcji"
                    (click)="
                      this.wyszukiwanie.filtry.rodzajTransakcji = '';
                      pobierzDaneDoEkranu(false)
                    "
                  ></button>
                </div>
              </th>
              <th>
                Cena średnia
                <button
                  class="btn__input--sort--bottom--gray"
                  [ngClass]="pobierzKierunekSortowania('cenaSrednia')"
                  (click)="zmienKierunekSortowania('cenaSrednia')"
                ></button>
                <div class="input_container input_container--mini">
                  <input
                    class="pr-4"
                    type="text"
                    placeholder="Szukaj"
                    [(ngModel)]="wyszukiwanie.filtry.cenaSrednia"
                    (keyup)="
                      filtrujPoTekscie(this.wyszukiwanie.filtry.cenaSrednia)
                    "
                  />
                  <button
                    class="btn__input--search"
                    *ngIf="!wyszukiwanie.filtry.cenaSrednia"
                  ></button>
                  <button
                    class="btn__input--clear"
                    *ngIf="wyszukiwanie.filtry.cenaSrednia"
                    (click)="
                      this.wyszukiwanie.filtry.cenaSrednia = '';
                      pobierzDaneDoEkranu(false)
                    "
                  ></button>
                </div>
              </th>
              <th>
                Ilość
                <button
                  class="btn__input--sort--bottom--gray"
                  [ngClass]="pobierzKierunekSortowania('ilosc')"
                  (click)="zmienKierunekSortowania('ilosc')"
                ></button>
                <div class="input_container input_container--mini">
                  <input
                    class="pr-4"
                    type="text"
                    placeholder="Szukaj"
                    [(ngModel)]="wyszukiwanie.filtry.ilosc"
                    (keyup)="filtrujPoTekscie(this.wyszukiwanie.filtry.ilosc)"
                  />
                  <button
                    class="btn__input--search"
                    *ngIf="!wyszukiwanie.filtry.ilosc"
                  ></button>
                  <button
                    class="btn__input--clear"
                    *ngIf="wyszukiwanie.filtry.ilosc"
                    (click)="
                      this.wyszukiwanie.filtry.ilosc = '';
                      pobierzDaneDoEkranu(false)
                    "
                  ></button>
                </div>
              </th>
              <th>
                Cena całkowita transakcji
                <button
                  class="btn__input--sort--bottom--gray"
                  [ngClass]="
                    pobierzKierunekSortowania('cenaCalkowitaTransakcji')
                  "
                  (click)="zmienKierunekSortowania('cenaCalkowitaTransakcji')"
                ></button>
                <div class="input_container input_container--mini">
                  <input
                    class="pr-4"
                    type="text"
                    placeholder="Szukaj"
                    [(ngModel)]="wyszukiwanie.filtry.cenaCalkowitaTransakcji"
                    (keyup)="
                      filtrujPoTekscie(
                        this.wyszukiwanie.filtry.cenaCalkowitaTransakcji
                      )
                    "
                  />
                  <button
                    class="btn__input--search"
                    *ngIf="!wyszukiwanie.filtry.cenaCalkowitaTransakcji"
                  ></button>
                  <button
                    class="btn__input--clear"
                    *ngIf="wyszukiwanie.filtry.cenaCalkowitaTransakcji"
                    (click)="
                      this.wyszukiwanie.filtry.cenaCalkowitaTransakcji = '';
                      pobierzDaneDoEkranu(false)
                    "
                  ></button>
                </div>
              </th>
              <th>
                Krypto
                <button
                  class="btn__input--sort--bottom--gray"
                  [ngClass]="pobierzKierunekSortowania('krypto')"
                  (click)="zmienKierunekSortowania('krypto')"
                ></button>
                <div class="input_container input_container--mini">
                  <input
                    class="pr-4"
                    type="text"
                    placeholder="Szukaj"
                    [(ngModel)]="wyszukiwanie.filtry.krypto"
                    (keyup)="filtrujPoTekscie(this.wyszukiwanie.filtry.krypto)"
                  />
                  <button
                    class="btn__input--search"
                    *ngIf="!wyszukiwanie.filtry.krypto"
                  ></button>
                  <button
                    class="btn__input--clear"
                    *ngIf="wyszukiwanie.filtry.krypto"
                    (click)="
                      this.wyszukiwanie.filtry.krypto = '';
                      pobierzDaneDoEkranu(false)
                    "
                  ></button>
                </div>
              </th>
              <th>
                Planowana cena sprzedaży
                <button
                  class="btn__input--sort--bottom--gray"
                  [ngClass]="
                    pobierzKierunekSortowania('planowanaCenaSprzedazy')
                  "
                  (click)="zmienKierunekSortowania('planowanaCenaSprzedazy')"
                ></button>
                <div class="input_container input_container--mini">
                  <input
                    class="pr-4"
                    type="text"
                    placeholder="Szukaj"
                    [(ngModel)]="wyszukiwanie.filtry.planowanaCenaSprzedazy"
                    (keyup)="
                      filtrujPoTekscie(
                        this.wyszukiwanie.filtry.planowanaCenaSprzedazy
                      )
                    "
                  />
                  <button
                    class="btn__input--search"
                    *ngIf="!wyszukiwanie.filtry.planowanaCenaSprzedazy"
                  ></button>
                  <button
                    class="btn__input--clear"
                    *ngIf="wyszukiwanie.filtry.planowanaCenaSprzedazy"
                    (click)="
                      this.wyszukiwanie.filtry.planowanaCenaSprzedazy = '';
                      pobierzDaneDoEkranu(false)
                    "
                  ></button>
                </div>
              </th>
              <th>
                Aktualna cena
                <button
                  class="btn__input--sort--bottom--gray"
                  [ngClass]="pobierzKierunekSortowania('aktualnaCena')"
                  (click)="zmienKierunekSortowania('aktualnaCena')"
                ></button>
                <div class="input_container input_container--mini">
                  <input
                    class="pr-4"
                    type="text"
                    placeholder="Szukaj"
                    [(ngModel)]="wyszukiwanie.filtry.aktualnaCena"
                    (keyup)="
                      filtrujPoTekscie(this.wyszukiwanie.filtry.aktualnaCena)
                    "
                  />
                  <button
                    class="btn__input--search"
                    *ngIf="!wyszukiwanie.filtry.aktualnaCena"
                  ></button>
                  <button
                    class="btn__input--clear"
                    *ngIf="wyszukiwanie.filtry.aktualnaCena"
                    (click)="
                      this.wyszukiwanie.filtry.aktualnaCena = '';
                      pobierzDaneDoEkranu(false)
                    "
                  ></button>
                </div>
              </th>
              <th>
                Procent
                <button
                  class="btn__input--sort--bottom--gray"
                  [ngClass]="pobierzKierunekSortowania('czyAktywna')"
                  (click)="zmienKierunekSortowania('czyAktywna')"
                ></button>
                <div class="input_container input_container--mini">
                  <input
                    class="pr-4"
                    type="text"
                    placeholder="Szukaj"
                    [(ngModel)]="wyszukiwanie.filtry.czyAktywna"
                    (keyup)="
                      filtrujPoTekscie(this.wyszukiwanie.filtry.czyAktywna)
                    "
                  />
                  <button
                    class="btn__input--search"
                    *ngIf="!wyszukiwanie.filtry.czyAktywna"
                  ></button>
                  <button
                    class="btn__input--clear"
                    *ngIf="wyszukiwanie.filtry.czyAktywna"
                    (click)="
                      this.wyszukiwanie.filtry.czyAktywna = '';
                      pobierzDaneDoEkranu(false)
                    "
                  ></button>
                </div>
              </th>
              <th>
                Wynik
                <button
                  class="btn__input--sort--bottom--gray"
                  [ngClass]="pobierzKierunekSortowania('czyAktywna')"
                  (click)="zmienKierunekSortowania('czyAktywna')"
                ></button>
                <div class="input_container input_container--mini">
                  <input
                    class="pr-4"
                    type="text"
                    placeholder="Szukaj"
                    [(ngModel)]="wyszukiwanie.filtry.czyAktywna"
                    (keyup)="
                      filtrujPoTekscie(this.wyszukiwanie.filtry.czyAktywna)
                    "
                  />
                  <button
                    class="btn__input--search"
                    *ngIf="!wyszukiwanie.filtry.czyAktywna"
                  ></button>
                  <button
                    class="btn__input--clear"
                    *ngIf="wyszukiwanie.filtry.czyAktywna"
                    (click)="
                      this.wyszukiwanie.filtry.czyAktywna = '';
                      pobierzDaneDoEkranu(false)
                    "
                  ></button>
                </div>
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody class="fz12" *ngIf="czySaWynikiNaLiscie(pozycje)">
            <tr
              *ngFor="let pozycja of pozycje"
              class="pointer project-row"
              [ngClass]="
                pozycjaNaPlus(pozycja) ? 'pozycja-na-plus' : 'pozycja-na-minus'
              "
            >
              <td>{{ pozycja.gielda }}</td>
              <td>{{ pozycja.rodzajKonta }}</td>
              <td>{{ pozycja.czyjeKonto }}</td>
              <td>{{ formatujDate(pozycja.dataTransakcji) }}</td>
              <td>{{ pozycja.rodzajTransakcji }}</td>
              <td>{{ pozycja.cenaSrednia | currency }}</td>
              <td>{{ pozycja.ilosc }}</td>
              <td>{{ pozycja.cenaCalkowitaTransakcji | currency }}</td>
              <td>{{ pozycja.krypto }}</td>
              <td>{{ pozycja.planowanaCenaSprzedazy | currency }}</td>
              <td>{{ pozycja.aktualnaCena | currency }}</td>
              <td>{{ znakPozycji(pozycja) }}{{ procent(pozycja) }}%</td>
              <td>{{ znakPozycji(pozycja) }}{{ wynik(pozycja) | currency }}</td>
              <td>
                <button
                  class="btn__dolar"
                  (click)="zamknijPozycje(pozycja.id)"
                ></button>
              </td>
            </tr>
          </tbody>
        </table>
        <div
          class="ft__03 text-gray text-center my-5 py-5"
          *ngIf="!czySaWynikiNaLiscie(pozycje)"
        >
          <div>Brak pozycji dla wybranych filtrów</div>
        </div>
      </div>
    </div>
    <div
      class="paginate float-right d-flex align-items-center pt-4 pb-2 pt-lg-2 mt-1"
      *ngIf="czySaWynikiNaLiscie(pozycje)"
    >
      <div>Pokaż wyniki</div>
      <select
        class="paginate__select mx-2"
        name=""
        id=""
        (change)="zmienRozmiarTabeli()"
        [(ngModel)]="rozmiarTabeli"
      >
        <option [ngValue]="25">25</option>
        <option [ngValue]="50">50</option>
        <option [ngValue]="100">100</option>
      </select>
      <div class="px-2">
        {{ pobierzNumerRekorduPoczatek() }}-{{ pobierzNumerRekorduKoniec() }} z
        {{ calkowitaLiczbaElementow }}
      </div>
      <div class="d-flex">
        <button
          class="btn__left"
          *ngIf="this.wyszukiwanie.stronicowanie.strona > 1"
          (click)="zmienStrone(-1)"
        ></button>
        <div class="px-2">{{ this.wyszukiwanie.stronicowanie.strona }}</div>
        <button
          class="btn__right"
          *ngIf="czyJestWiecejRekordow"
          (click)="zmienStrone(1)"
        ></button>
      </div>
    </div>
  </div>

  <!-- mobile -->
  <div class="container-fluid d-md-none pb-5">
    <div class="pt-3 mt-1">
      <div class="ft__sort text-center">Aktualne pozycje</div>
    </div>

    <div class="filter-box d-flex justify-content-between pt-3 mb-3">
      <span></span>
      <div class="dropdown person_desktop text-right">
        <a
          class="btn__input--sort dropdown-toggle"
          [routerLink]=""
          (click)="pokazMenuSortowania()"
          role="button"
          id="dropdownSort"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          (clickOutside)="czyPokazacMenuSortowanie = false"
        >
        </a>
        <div
          class="dropdown-menu dropdown-menu-right"
          aria-labelledby="dropdownSort"
          *ngIf="czyPokazacMenuSortowanie"
          style="display: block; position: absolute"
        >
          <a
            class="dropdown-item"
            [routerLink]=""
            (click)="zmienKierunekSortowania('gielda')"
            >Giełda</a
          >
          <a
            class="dropdown-item"
            [routerLink]=""
            (click)="zmienKierunekSortowania('rodzajKonta')"
            >Rodzaj konta
          </a>
          <a
            class="dropdown-item"
            [routerLink]=""
            (click)="zmienKierunekSortowania('czyjeKonto')"
            >Czyje konto</a
          >
          <a
            class="dropdown-item"
            [routerLink]=""
            (click)="zmienKierunekSortowania('dataTransakcji')"
            >Data transakcji</a
          >
          <a
            class="dropdown-item"
            [routerLink]=""
            (click)="zmienKierunekSortowania('rodzajTransakcji')"
            >Rodzaj transakcj</a
          >

          <a
            class="dropdown-item"
            [routerLink]=""
            (click)="zmienKierunekSortowania('cenaSrednia')"
            >Cena średnia</a
          >
          <a
            class="dropdown-item"
            [routerLink]=""
            (click)="zmienKierunekSortowania('ilosc')"
            >Ilość</a
          >
          <a
            class="dropdown-item"
            [routerLink]=""
            (click)="zmienKierunekSortowania('cenaCalkowitaTransakcji')"
            >Cena całkowita transakcji</a
          >
          <a
            class="dropdown-item"
            [routerLink]=""
            (click)="zmienKierunekSortowania('krypto')"
            >Krypto</a
          >
          <a
            class="dropdown-item"
            [routerLink]=""
            (click)="zmienKierunekSortowania('planowanaCenaSprzedazy')"
            >Planowana cena sprzedaży</a
          >
          <a
            class="dropdown-item"
            [routerLink]=""
            (click)="zmienKierunekSortowania('aktualnaCena')"
            >Aktualna cena</a
          >
          <a
            class="dropdown-item"
            [routerLink]=""
            (click)="zmienKierunekSortowania('procent')"
            >Procent</a
          >
          <a
            class="dropdown-item"
            [routerLink]=""
            (click)="zmienKierunekSortowania('wynik')"
            >Wynik</a
          >
        </div>
      </div>
    </div>

    <div
      class="filter-select pt-3 pb-2 d-flex flex-column mb-3"
      *ngIf="pobierzKolumneSortowania()"
    >
      <div class="d-flex pb-2">
        <div class="filter_item">
          <button class="btn__x" (click)="wyczyscSortowanieMobile()"></button>
          <span class="fs__sort"
            >Sortowanie: {{ pobierzKolumneSortowania() }}</span
          >
        </div>
        <button
          class="btn__input--sort--bottom ml-3"
          [ngClass]="pobierzKierunekSortowaniaMobile()"
          (click)="zmienKierunekSortowaniaMobile()"
        ></button>
      </div>
    </div>

    <div
      class="input_container mb-3 text-right"
      *ngIf="!czyPokazanoWyszukiwarkeMobile"
    >
      <button
        class="btn__search"
        (click)="pokazWyszukiwarkeMobile(true)"
      ></button>
    </div>
    <div
      class="searchBox d-flex flex-wrap por pb-5"
      *ngIf="czyPokazanoWyszukiwarkeMobile"
    >
      <button
        class="btn__input--clear"
        (click)="pokazWyszukiwarkeMobile(false)"
      ></button>
    </div>

    <div
      class="box__mobile box__mobile--form mb-3"
      *ngIf="czyPokazanoWyszukiwarkeMobile"
    >
      <div class="input_container mb-3">
        <input
          class="pr-4"
          type="text"
          placeholder="Giełda"
          [(ngModel)]="wyszukiwanie.filtry.gielda"
        />
        <button
          class="btn__input--search"
          *ngIf="!wyszukiwanie.filtry.gielda"
        ></button>
        <button
          class="btn__input--clear"
          *ngIf="wyszukiwanie.filtry.gielda"
          (click)="this.wyszukiwanie.filtry.gielda = ''"
        ></button>
      </div>
      <div class="input_container mb-3">
        <input
          class="pr-4"
          type="text"
          placeholder="Rodzaj konta"
          [(ngModel)]="wyszukiwanie.filtry.rodzajKonta"
        />
        <button
          class="btn__input--search"
          *ngIf="!wyszukiwanie.filtry.rodzajKonta"
        ></button>
        <button
          class="btn__input--clear"
          *ngIf="wyszukiwanie.filtry.rodzajKonta"
          (click)="this.wyszukiwanie.filtry.rodzajKonta = ''"
        ></button>
      </div>
      <div class="input_container mb-3">
        <input
          class="pr-4"
          type="text"
          placeholder="Czyje konto"
          [(ngModel)]="wyszukiwanie.filtry.czyjeKonto"
        />
        <button
          class="btn__input--search"
          *ngIf="!wyszukiwanie.filtry.czyjeKonto"
        ></button>
        <button
          class="btn__input--clear"
          *ngIf="wyszukiwanie.filtry.czyjeKonto"
          (click)="this.wyszukiwanie.filtry.czyjeKonto = ''"
        ></button>
      </div>
      <div class="input_container mb-3">
        <input
          class="pr-4"
          type="text"
          placeholder="Data transakcji"
          [(ngModel)]="wyszukiwanie.filtry.dataTransakcji"
        />
        <button
          class="btn__input--search"
          *ngIf="!wyszukiwanie.filtry.dataTransakcji"
        ></button>
        <button
          class="btn__input--clear"
          *ngIf="wyszukiwanie.filtry.dataTransakcji"
          (click)="this.wyszukiwanie.filtry.dataTransakcji = ''"
        ></button>
      </div>
      <div class="input_container mb-3">
        <input
          class="pr-4"
          type="text"
          placeholder="Rodzaj transakcji"
          [(ngModel)]="wyszukiwanie.filtry.rodzajTransakcji"
        />
        <button
          class="btn__input--search"
          *ngIf="!wyszukiwanie.filtry.rodzajTransakcji"
        ></button>
        <button
          class="btn__input--clear"
          *ngIf="wyszukiwanie.filtry.rodzajTransakcji"
          (click)="this.wyszukiwanie.filtry.rodzajTransakcji = ''"
        ></button>
      </div>

      <div class="input_container mb-3">
        <input
          class="pr-4"
          type="text"
          placeholder="Cena średnia"
          [(ngModel)]="wyszukiwanie.filtry.cenaSrednia"
        />
        <button
          class="btn__input--search"
          *ngIf="!wyszukiwanie.filtry.cenaSrednia"
        ></button>
        <button
          class="btn__input--clear"
          *ngIf="wyszukiwanie.filtry.cenaSrednia"
          (click)="this.wyszukiwanie.filtry.cenaSrednia = ''"
        ></button>
      </div>
      <div class="input_container mb-3">
        <input
          class="pr-4"
          type="text"
          placeholder="Ilość"
          [(ngModel)]="wyszukiwanie.filtry.ilosc"
        />
        <button
          class="btn__input--search"
          *ngIf="!wyszukiwanie.filtry.ilosc"
        ></button>
        <button
          class="btn__input--clear"
          *ngIf="wyszukiwanie.filtry.ilosc"
          (click)="this.wyszukiwanie.filtry.ilosc = ''"
        ></button>
      </div>
      <div class="input_container mb-3">
        <input
          class="pr-4"
          type="text"
          placeholder="Cena całkowita transakcji"
          [(ngModel)]="wyszukiwanie.filtry.cenaCalkowitaTransakcji"
        />
        <button
          class="btn__input--search"
          *ngIf="!wyszukiwanie.filtry.cenaCalkowitaTransakcji"
        ></button>
        <button
          class="btn__input--clear"
          *ngIf="wyszukiwanie.filtry.cenaCalkowitaTransakcji"
          (click)="this.wyszukiwanie.filtry.cenaCalkowitaTransakcji = ''"
        ></button>
      </div>
      <div class="input_container mb-3">
        <input
          class="pr-4"
          type="text"
          placeholder="Krypto"
          [(ngModel)]="wyszukiwanie.filtry.krypto"
        />
        <button
          class="btn__input--search"
          *ngIf="!wyszukiwanie.filtry.krypto"
        ></button>
        <button
          class="btn__input--clear"
          *ngIf="wyszukiwanie.filtry.krypto"
          (click)="this.wyszukiwanie.filtry.krypto = ''"
        ></button>
      </div>
      <div class="input_container mb-3">
        <input
          class="pr-4"
          type="text"
          placeholder="Planowana cena sprzedaży"
          [(ngModel)]="wyszukiwanie.filtry.planowanaCenaSprzedazy"
        />
        <button
          class="btn__input--search"
          *ngIf="!wyszukiwanie.filtry.planowanaCenaSprzedazy"
        ></button>
        <button
          class="btn__input--clear"
          *ngIf="wyszukiwanie.filtry.planowanaCenaSprzedazy"
          (click)="this.wyszukiwanie.filtry.planowanaCenaSprzedazy = ''"
        ></button>
      </div>
      <div class="input_container mb-3">
        <input
          class="pr-4"
          type="text"
          placeholder="Aktualna cena"
          [(ngModel)]="wyszukiwanie.filtry.aktualnaCena"
        />
        <button
          class="btn__input--search"
          *ngIf="!wyszukiwanie.filtry.aktualnaCena"
        ></button>
        <button
          class="btn__input--clear"
          *ngIf="wyszukiwanie.filtry.aktualnaCena"
          (click)="this.wyszukiwanie.filtry.aktualnaCena = ''"
        ></button>
      </div>
      <div class="input_container mb-3">
        <input
          class="pr-4"
          type="text"
          placeholder="Procent"
          [(ngModel)]="wyszukiwanie.filtry.procent"
        />
        <button
          class="btn__input--search"
          *ngIf="!wyszukiwanie.filtry.procent"
        ></button>
        <button
          class="btn__input--clear"
          *ngIf="wyszukiwanie.filtry.procent"
          (click)="this.wyszukiwanie.filtry.procent = ''"
        ></button>
      </div>
      <div class="input_container mb-3">
        <input
          class="pr-4"
          type="text"
          placeholder="Wynik"
          [(ngModel)]="wyszukiwanie.filtry.wynik"
        />
        <button
          class="btn__input--search"
          *ngIf="!wyszukiwanie.filtry.wynik"
        ></button>
        <button
          class="btn__input--clear"
          *ngIf="wyszukiwanie.filtry.wynik"
          (click)="this.wyszukiwanie.filtry.wynik = ''"
        ></button>
      </div>
    </div>

    <button
      class="btn__border mt-4 mb-4"
      (click)="pobierzDaneDoEkranu(true); pokazWyszukiwarkeMobile(false)"
      *ngIf="czyPokazanoWyszukiwarkeMobile"
    >
      Szukaj
    </button>

    <button
      class="btn__clear--filter mt-2 mb-4"
      (click)="wyczyscFiltry(); pokazWyszukiwarkeMobile(false)"
      *ngIf="czyPokazacWyczyscFiltry()"
    >
      Wyczyść filtry
    </button>

    <div
      class="no-results text-center ft__no-results"
      *ngIf="!czySaWynikiNaLiscie(pozycje)"
    >
      <div>Brak pozycji<br />dla wybranych filtrów</div>
    </div>

    <div class="box__mobile mb-3" *ngFor="let pozycja of pozycje">
      <div
        class="d-flex justify-content-between align-items-center pb-2 mb-2 pt-2"
      >
        <span class="fs__pname">Giełda {{ pozycja.gielda }}</span>
      </div>
      <div
        class="d-flex justify-content-between align-items-center pb-2 mr-3 mb-2 m_border"
      >
        <span class="fs__pname--2">Rodzaj konta</span>
        <span class="fs__pname--3 text-lg2">{{ pozycja.rodzajKonta }}</span>
      </div>
      <div
        class="d-flex justify-content-between align-items-center pb-2 mr-3 mb-2 m_border"
      >
        <span class="fs__pname--2">Czyje konto</span>
        <span class="fs__pname--3 text-lg2">{{ pozycja.czyjeKonto }}</span>
      </div>
      <div
        class="d-flex justify-content-between align-items-center pb-2 mr-3 mb-2 m_border"
      >
        <span class="fs__pname--2">Data transakcji</span>
        <span class="fs__pname--3 text-lg2">{{
          formatujDate(pozycja.dataTransakcji)
        }}</span>
      </div>
      <div
        class="d-flex justify-content-between align-items-center pb-2 mr-3 mb-2 m_border"
      >
        <span class="fs__pname--2">Rodzaj transakcji</span>
        <span class="fs__pname--3 text-lg2">{{
          pozycja.rodzajTransakcji
        }}</span>
      </div>
      <div
        class="d-flex justify-content-between align-items-center pb-2 mr-3 mb-2 m_border"
      >
        <span class="fs__pname--2">Cena średnia</span>
        <span class="fs__pname--3 text-lg2">{{
          pozycja.cenaSrednia | currency
        }}</span>
      </div>

      <div
        class="d-flex justify-content-between align-items-center pb-2 mr-3 mb-2 m_border"
      >
        <span class="fs__pname--2">Ilość</span>
        <span class="fs__pname--3 text-lg2">{{ pozycja.ilosc }}</span>
      </div>
      <div
        class="d-flex justify-content-between align-items-center pb-2 mr-3 mb-2 m_border"
      >
        <span class="fs__pname--2">Cena całkowita transakcji</span>
        <span class="fs__pname--3 text-lg2">{{
          pozycja.cenaCalkowitaTransakcji | currency
        }}</span>
      </div>
      <div
        class="d-flex justify-content-between align-items-center pb-2 mr-3 mb-2 m_border"
      >
        <span class="fs__pname--2">Krypto</span>
        <span class="fs__pname--3 text-lg2">{{ pozycja.krypto }}</span>
      </div>
      <div
        class="d-flex justify-content-between align-items-center pb-2 mr-3 mb-2 m_border"
      >
        <span class="fs__pname--2">Planowana cena sprzedaży</span>
        <span class="fs__pname--3 text-lg2">{{
          pozycja.planowanaCenaSprzedazy | currency
        }}</span>
      </div>
      <div
        class="d-flex justify-content-between align-items-center pb-2 mr-3 mb-2 m_border"
      >
        <span class="fs__pname--2">Aktualna cena</span>
        <span class="fs__pname--3 text-lg2">{{
          pozycja.aktualnaCena | currency
        }}</span>
      </div>
      <div
        class="d-flex justify-content-between align-items-center pb-2 mr-3 mb-2 m_border"
      >
        <span class="fs__pname--2">Procent</span>
        <span class="fs__pname--3 text-lg2"
          >{{ znakPozycji(pozycja) }}{{ procent(pozycja) }}%</span
        >
      </div>
      <div
        class="d-flex justify-content-between align-items-center pb-2 mr-3 mb-2 m_border"
      >
        <span class="fs__pname--2">Wynik</span>
        <span class="fs__pname--3 text-lg2"
          >{{ znakPozycji(pozycja) }}{{ wynik(pozycja) | currency }}</span
        >
      </div>

      <div
        class="d-flex justify-content-between align-items-center pb-2 mr-3 mb-2 m_border"
      >
        <span class="fs__pname--2">Zamknij pozycję</span>
        <button
          class="btn__dolar"
          (click)="zamknijPozycje(pozycja.id)"
        ></button>
      </div>
    </div>

    <div
      class="paginate float-right d-flex align-items-center pb-2 pt-lg-4"
      *ngIf="czySaWynikiNaLiscie(pozycje)"
    >
      <div>Pokaż wyniki</div>
      <select
        class="paginate__select mx-2"
        name=""
        id=""
        (change)="zmienRozmiarTabeli()"
        [(ngModel)]="rozmiarTabeli"
      >
        <option [ngValue]="25">25</option>
        <option [ngValue]="50">50</option>
        <option [ngValue]="100">100</option>
      </select>
      <div class="px-2">
        {{ pobierzNumerRekorduPoczatek() }}-{{ pobierzNumerRekorduKoniec() }} z
        {{ calkowitaLiczbaElementow }}
      </div>
      <div class="d-flex">
        <button
          class="btn__left"
          *ngIf="this.wyszukiwanie.stronicowanie.strona > 1"
          (click)="zmienStrone(-1)"
        ></button>
        <div class="px-2">{{ this.wyszukiwanie.stronicowanie.strona }}</div>
        <button
          class="btn__right"
          *ngIf="czyJestWiecejRekordow"
          (click)="zmienStrone(1)"
        ></button>
      </div>
    </div>
  </div>
</div>
<div class="bootom__buttons px-4">
  <div
    class="container--mw600 flex-column flex-sm-row d-flex justify-content-between align-items-center"
  >
    <button
      class="btn__link order-1 order-sm-0"
      (click)="zamknijWszystko()"
      *ngIf="czySaWynikiNaLiscie(pozycje)"
    >
      Zamknij wszystki pozycje
    </button>
  </div>
</div>
