import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { BazowyComponent } from 'src/app/komponenty/bazowy.component';
import { PobierzKonfiguracjeGieldRezultat } from 'src/app/modele/futures/pobierzKonfiguracjeGieldRezultat';
import { TestujKonfiguracjeModel } from 'src/app/modele/testy/testujKonfiguracjeModel';
import { FuturesService } from 'src/app/serwisy/futures/futures.service';
import { AuthService } from 'src/app/serwisy/ogolne/auth.service';
import { LoaderService } from 'src/app/serwisy/ogolne/loader.service';
import { UzupelnijDaneKryptoModel } from 'src/app/modele/testy/uzupelnijDaneKryptoModel';
import { RejestrFiltrowanyRequest } from 'src/app/modele/wspolne/rejestrFiltrowanyRequest';
import { PobierzAktualnePozycjeFuturesFiltry } from 'src/app/modele/futures/pobierzAktualnePozycjeFuturesFiltry';
import { Stronicowanie } from 'src/app/modele/wspolne/stronicowanie';
import {
  KierunekSortowania,
  Sortowanie,
} from 'src/app/modele/wspolne/sortowanie';
import { PobierzKonfiguracjeGieldFiltry } from 'src/app/modele/futures/pobierzKonfiguracjeGieldFiltry';

@Component({
  selector: 'app-testowanie-konfiguracji',
  templateUrl: './testowanie-konfiguracji.component.html',
  styleUrls: ['./testowanie-konfiguracji.component.css'],
})
export class TestowanieKonfiguracjiComponent
  extends BazowyComponent
  implements OnInit
{
  rezultat: string = '';
  model: TestujKonfiguracjeModel = new TestujKonfiguracjeModel();
  gieldy: Array<PobierzKonfiguracjeGieldRezultat> = [];
  dropdownSettings = {};
  dostepneKrypto: Array<{ value: string; key: string }> = [
    { key: 'ETHUSDT', value: 'ETHUSDT' },
    { key: 'BTCUSDT', value: 'BTCUSDT' },
    { key: 'ADAUSDT', value: 'ADAUSDT' },
    // { key: 'BNBUSDT', value: 'BNBUSDT' },
  ];
  dzwignie = [
    { id: 1, name: '1' },
    { id: 2, name: '2' },
    { id: 3, name: '3' },
    { id: 4, name: '4' },
    { id: 5, name: '5' },
    { id: 6, name: '6' },
    { id: 7, name: '7' },
    { id: 8, name: '8' },
    { id: 9, name: '9' },
    { id: 10, name: '10' },
    { id: 11, name: '11' },
    { id: 12, name: '12' },
    { id: 13, name: '13' },
    { id: 14, name: '14' },
    { id: 15, name: '15' },
    { id: 16, name: '16' },
    { id: 17, name: '17' },
    { id: 18, name: '18' },
    { id: 19, name: '19' },
    { id: 20, name: '20' },
    { id: 21, name: '21' },
    { id: 22, name: '22' },
    { id: 23, name: '23' },
    { id: 24, name: '24' },
    { id: 25, name: '25' },
    { id: 26, name: '26' },
    { id: 27, name: '27' },
    { id: 28, name: '28' },
    { id: 29, name: '29' },
    { id: 30, name: '30' },
  ];

  constructor(
    public router: Router,
    modalService: NgbModal,
    loaderSerwis: LoaderService,
    komunikatSerwis: ToastrService,
    public authService: AuthService,
    public futuresSerwis: FuturesService
  ) {
    super(authService, modalService, loaderSerwis, komunikatSerwis, router);
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.pobierzGieldy();
      this.model.dataOd = '2022-05-01';

      this.dropdownSettings = {
        singleSelection: false,
        idField: 'item_id',
        textField: 'item_text',
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        itemsShowLimit: 30,
        allowSearchFilter: true,
      };

      this.ustawWartosciStartowe('ETHUSDT');
    });
  }

  pobierzGieldy() {
    var model = new RejestrFiltrowanyRequest<PobierzKonfiguracjeGieldFiltry>();
    model.stronicowanie = new Stronicowanie(true);
    model.sortowanie = new Sortowanie('czyjeKonto', KierunekSortowania.asc);

    this.futuresSerwis
      .pobierzKonfiguracjeGield(model, true)
      .subscribe((rezultat) => {
        if (this.czyRezultatOk(rezultat)) {
          this.gieldy = rezultat.listaElementow;
          return;
        }

        this.wyswietlKomunikat(rezultat.wiadomosc, true);
        return;
      });
  }

  wybierzKrypto() {
    this.ustawWartosciStartowe(this.model.krypto);
  }
  onItemSelect(item: any) {
    console.log(item);
  }
  onSelectAll(items: any) {
    console.log(items);
  }

  ustawWartosciStartowe(krypto) {
    this.model.dzwignie = [23, 20, 19, 18, 17, 15, 14, 13, 12, 11];
    this.model.budzet = 220;
    this.model.procentKapitalu = 80;
    this.model.maxGodzinPozycji = 48;
    this.model.odJakiejKwotyOdkladac = 5000000;
    this.model.odkladanyProcentNaKoniecMiesiaca = 10;
    this.model.krypto = krypto;

    if (krypto == 'ETHUSDT') {
      this.model.tps = 1.76;
      this.model.sls = 1.52;
      this.model.tpl = 2.19;
      this.model.sll = 1.92;
      this.model.macdSL = -0.00047;
      this.model.macdSS = 0.0065;
      this.model.macdLS = 0.00047;
      this.model.rsiL = 42.6;
      this.model.maxWielkoscPozycji = 500000;
    }
    if (krypto == 'BTCUSDT') {
      this.model.tps = 1.94;
      this.model.sls = 1.64;
      this.model.tpl = 1.6;
      this.model.sll = 1.56;
      this.model.macdSL = -0.0004;
      this.model.macdSS = 0.0015;
      this.model.macdLS = 0.00006;
      this.model.rsiL = 41.9;
      this.model.maxWielkoscPozycji = 3000000;
    }
    if (krypto == 'ADAUSDT') {
      this.model.tps = 1.76;
      this.model.sls = 1.52;
      this.model.tpl = 2.19;
      this.model.sll = 1.92;
      this.model.macdSL = -0.00047;
      this.model.macdSS = 0.0065;
      this.model.macdLS = 0.00047;
      this.model.rsiL = 42.6;
      this.model.maxWielkoscPozycji = 300000;
    }
  }

  testuj() {
    this.rezultat = '';
    this.futuresSerwis.testujKonfiguracje(this.model).subscribe((rezultat) => {
      if (this.czyRezultatOk(rezultat)) {
        this.rezultat = rezultat.element.rezultat;
        return;
      }

      this.wyswietlKomunikat(rezultat.wiadomosc, true);
      return;
    });
  }

  uzupelnijDaneKrypto() {
    var model = new UzupelnijDaneKryptoModel();
    model.dataOd = this.model.dataOd;
    model.krypto = this.model.krypto;
    model.gieldaId = this.model.gieldaId;
    this.futuresSerwis.uzupelnijDaneKrypto(model).subscribe((rezultat) => {
      if (this.czyRezultatOk(rezultat)) {
        this.wyswietlKomunikat('Ceny kryptowaluty uzupełniane są w tle');
        return;
      }

      this.wyswietlKomunikat(rezultat.wiadomosc, true);
      return;
    });
  }
}
