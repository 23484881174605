import { Component, OnDestroy, OnInit } from '@angular/core';
import { BazowyComponent } from '../../bazowy.component';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { PobierzKonfiguracjeGieldRezultat } from 'src/app/modele/futures/pobierzKonfiguracjeGieldRezultat';
import { ModalOpcje } from 'src/app/modele/wspolne/modal-opcje.ogolne';
import { FuturesService } from 'src/app/serwisy/futures/futures.service';
import { AuthService } from 'src/app/serwisy/ogolne/auth.service';
import { LoaderService } from 'src/app/serwisy/ogolne/loader.service';
import { DodajKonfiguracjeGieldComponent } from '../modale/dodaj-konfiguracje-gield/dodaj-konfiguracje-gield.component';
import { PobierzKonfiguracjeFuturesRezultat } from 'src/app/modele/futures/pobierzKonfiguracjeFuturesRezultat';
import { DodajKonfiguracjeFuturesComponent } from '../modale/dodaj-konfiguracje-futures/dodaj-konfiguracje-futures.component';
import { EdytujKonfiguracjeFuturesComponent } from '../modale/edytuj-konfiguracje-futures/edytuj-konfiguracje-futures.component';
import { WejdzWPozycjeComponent } from '../modale/wejdz-w-pozycje/wejdz-w-pozycje.component';
import { Subscription, timer } from 'rxjs';
import { TakNieModalComponent } from '../../ogolne/tak-nie-modal/tak-nie-modal.component';
import { RejestrFiltrowanyRequest } from 'src/app/modele/wspolne/rejestrFiltrowanyRequest';
import { PobierzKonfiguracjeFuturesFiltr } from 'src/app/modele/futures/pobierzKonfiguracjeFuturesFiltr';
import {
  Sortowanie,
  KierunekSortowania,
} from 'src/app/modele/wspolne/sortowanie';
import { Stronicowanie } from 'src/app/modele/wspolne/stronicowanie';

@Component({
  selector: 'app-konfiguracje-futures',
  templateUrl: './konfiguracje-futures.component.html',
  styleUrls: ['./konfiguracje-futures.component.css'],
})
export class KonfiguracjeFuturesComponent
  extends BazowyComponent
  implements OnInit, OnDestroy
{
  subscription: Subscription;
  czyZaladowanoDane: boolean;
  calkowitaLiczbaElementow: BigInteger;
  rozmiarTabeli: number = 25;
  czyJestWiecejRekordow: boolean = true;
  konfiguracje: Array<PobierzKonfiguracjeFuturesRezultat> = new Array();
  modalReference: any;
  modalService: any;
  czyPokazanoWyszukiwarkeMobile: boolean;

  gieldy: Array<PobierzKonfiguracjeGieldRezultat> = new Array();
  czyPokazacMenuSortowanie: boolean;
  wyszukiwanie: RejestrFiltrowanyRequest<PobierzKonfiguracjeFuturesFiltr> =
    new RejestrFiltrowanyRequest<PobierzKonfiguracjeFuturesFiltr>();

  constructor(
    public router: Router,
    modalService: NgbModal,
    loaderSerwis: LoaderService,
    komunikatSerwis: ToastrService,
    public authService: AuthService,
    public futuresSerwis: FuturesService
  ) {
    super(authService, modalService, loaderSerwis, komunikatSerwis, router);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
  ngOnInit(): void {
    setTimeout(() => {
      this.wyszukiwanie.filtry = new PobierzKonfiguracjeFuturesFiltr();
      this.wyszukiwanie.sortowanie = new Sortowanie(
        'czyjeKonto',
        KierunekSortowania.asc
      );
      this.wyszukiwanie.stronicowanie = new Stronicowanie(false);
      this.wyszukiwanie.stronicowanie.iloscRekordow = this.rozmiarTabeli;
      this.wyszukiwanie.stronicowanie.rekordPoczatkowy = 0;
      this.wyszukiwanie.stronicowanie.strona = 1;

      this.pobierzDaneDoEkranu(true);
      this.subscription = timer(10000, 10000).subscribe((x) => {
        this.pobierzDaneDoEkranu(false);
      });
    });
  }

  pobierzDaneDoEkranu(zLadowaczem: boolean) {
    this.futuresSerwis
      .pobierzKonfiguracjeFutures(this.wyszukiwanie, zLadowaczem)
      .subscribe((rezultat) => {
        if (this.czyRezultatOk(rezultat)) {
          this.konfiguracje = rezultat.listaElementow;
          this.czyZaladowanoDane = true;
          this.calkowitaLiczbaElementow = rezultat.calkowitaIloscRekordow;
          this.czyJestWiecejRekordow = rezultat.czyJestWiecejRekordow;
          return;
        }

        this.wyswietlKomunikat(rezultat.wiadomosc, true);
        return;
      });
  }

  dodajKonfiguracje() {
    this.modalReference = this.modalService.open(
      DodajKonfiguracjeFuturesComponent,
      new ModalOpcje(true, 'static')
    );
    this.modalReference.result.then(
      (result) => {
        if (result) {
          this.pobierzDaneDoEkranu(true);
        }
      },
      (onRejected) => {}
    );
  }

  edytuj(id: string) {
    this.modalReference = this.modalService.open(
      EdytujKonfiguracjeFuturesComponent,
      new ModalOpcje(true, 'static')
    );
    this.modalReference.componentInstance.model = {
      ...this.konfiguracje.find((m) => m.id == id),
    };
    this.modalReference.result.then(
      (result) => {
        if (result) {
          this.pobierzDaneDoEkranu(true);
        }
      },
      (onRejected) => {}
    );
  }

  otworzPozycje(id: string) {
    this.modalReference = this.modalService.open(
      WejdzWPozycjeComponent,
      new ModalOpcje(true, 'static')
    );
    this.modalReference.componentInstance.id = id;
    this.modalReference.result.then(
      (result) => {
        if (result) {
          this.pobierzDaneDoEkranu(true);
        }
      },
      (onRejected) => {}
    );
  }

  zamknijWszystko() {
    this.modalReference = this.modalService.open(
      TakNieModalComponent,
      new ModalOpcje(true, 'static')
    );
    this.modalReference.componentInstance.naglowek =
      'Stopowanie wszystkich konfiguracji';
    this.modalReference.componentInstance.pytanie =
      'Czy na pewno chcesz zastopować wszystkie konfiguracje i sprzedać otwarte pozycje?';
    this.modalReference.result.then(
      (result) => {
        if (result) {
          this.futuresSerwis
            .zastopujWszystkieKonfiguracje()
            .subscribe((rezultat) => {
              if (this.czyRezultatOk(rezultat)) {
                this.wyswietlKomunikat(
                  'Zastopowano konfiguracje. Otwarte pozycje są w trakcie zamykania'
                );
                return;
              }

              this.wyswietlKomunikat(rezultat.wiadomosc, true);
              return;
            });
        }
      },
      (onRejected) => {}
    );
  }

  zmienRozmiarTabeli() {
    this.wyszukiwanie.stronicowanie.iloscRekordow = this.rozmiarTabeli;
    this.pobierzDaneDoEkranu(true);
  }

  pobierzNumerRekorduPoczatek() {
    if (this.wyszukiwanie.stronicowanie == undefined) {
      return 1;
    }
    return this.wyszukiwanie.stronicowanie.rekordPoczatkowy + 1;
  }
  pobierzNumerRekorduKoniec() {
    if (this.wyszukiwanie.stronicowanie == undefined) {
      return 1;
    }
    return (
      this.wyszukiwanie.stronicowanie.rekordPoczatkowy +
      this.konfiguracje.length
    );
  }

  zmienStrone(oIle: number) {
    this.wyszukiwanie.stronicowanie.strona += oIle;
    this.wyszukiwanie.stronicowanie.rekordPoczatkowy =
      (this.wyszukiwanie.stronicowanie.strona - 1) *
      this.wyszukiwanie.stronicowanie.iloscRekordow;
    this.pobierzDaneDoEkranu(true);
  }

  pokazMenuSortowania() {
    this.czyPokazacMenuSortowanie = !this.czyPokazacMenuSortowanie;
  }

  pobierzKierunekSortowania(kolumna: string) {
    var kierunki = this.wyszukiwanie.sortowanie.listaSortowania;

    var kierunek = kierunki.find((m) => m.nazwaKolumny == kolumna);

    if (kierunek == undefined) {
      return '';
    }

    if (kierunek.kierunekSortowania == KierunekSortowania.asc) {
      return 'up';
    }
    if (kierunek.kierunekSortowania == KierunekSortowania.desc) {
      return 'down';
    }
  }

  zmienKierunekSortowania(kolumna: string) {
    this.czyPokazacMenuSortowanie = false;
    var kierunki = this.wyszukiwanie.sortowanie.listaSortowania;

    var kierunek = kierunki.find((m) => m.nazwaKolumny == kolumna);

    if (kierunek == undefined) {
      this.wyszukiwanie.sortowanie = new Sortowanie(
        kolumna,
        KierunekSortowania.asc
      );
      this.pobierzDaneDoEkranu(true);
      return;
    }

    if (kierunek.kierunekSortowania == KierunekSortowania.asc) {
      this.wyszukiwanie.sortowanie = new Sortowanie(
        kolumna,
        KierunekSortowania.desc
      );
      this.pobierzDaneDoEkranu(true);
      return;
    }
    if (kierunek.kierunekSortowania == KierunekSortowania.desc) {
      this.wyszukiwanie.sortowanie = new Sortowanie(
        'czyjeKonto',
        KierunekSortowania.asc
      );
      this.pobierzDaneDoEkranu(true);
      return;
    }
  }

  pobierzKolumneSortowania() {
    var kierunki = this.wyszukiwanie.sortowanie.listaSortowania;

    switch (kierunki[0].nazwaKolumny) {
      case 'gielda':
        return 'Giełda';
      case 'rodzajKonta':
        return 'Rodzaj konta';
      case 'czyjeKonto':
        return 'Czyje konto';
      case 'czyAktywna':
        return 'Czy aktywna';
      case 'paraKrypto':
        return 'Krypto';
      case 'maxGodzinPozycji':
        return 'Max h pozycji';
      case 'tpl':
        return 'LTP';
      case 'sll':
        return 'LSL';
      case 'tps':
        return 'STP';
      case 'sls':
        return 'SSL';
      case 'dzwignia':
        return 'Dźwignia';
      case 'procentKapitalu':
        return 'Kapitał';
      case 'macdSL':
        return 'MSL';
      case 'macdLS':
        return 'MLS';

      case 'macdSS':
        return 'MSS';
      case 'rsiL':
        return 'RSIL';
      case 'tylkoAnalizaSygnalow':
        return 'Analiza sygnałów';
      case 'czyMaAktualnieOtwortaPozycje':
        return 'Pozycja';
      case 'maxWielkoscPozycji':
        return 'Max wielkość pozycji';
      case 'odkladanyProcentNaKoniecMiesiaca':
        return 'Odkładany procent na koniec miesiąca';
      case 'odJakiejKwotyOdkladac':
        return 'Od jakiej kwoty odkładać';
    }
  }

  pobierzKierunekSortowaniaMobile() {
    var kierunki = this.wyszukiwanie.sortowanie.listaSortowania;

    if (kierunki[0].kierunekSortowania == KierunekSortowania.asc) {
      return 'up';
    }
    if (kierunki[0].kierunekSortowania == KierunekSortowania.desc) {
      return '';
    }
  }

  zmienKierunekSortowaniaMobile() {
    var kierunki = this.wyszukiwanie.sortowanie.listaSortowania;

    var kierunek = kierunki[0];

    if (kierunek.kierunekSortowania == KierunekSortowania.asc) {
      this.wyszukiwanie.sortowanie = new Sortowanie(
        kierunek.nazwaKolumny,
        KierunekSortowania.desc
      );
      this.pobierzDaneDoEkranu(true);
      return;
    }
    if (kierunek.kierunekSortowania == KierunekSortowania.desc) {
      this.wyszukiwanie.sortowanie = new Sortowanie(
        kierunek.nazwaKolumny,
        KierunekSortowania.asc
      );
      this.pobierzDaneDoEkranu(true);
      return;
    }
  }
  wyczyscSortowanieMobile() {
    this.wyszukiwanie.sortowanie = new Sortowanie('Id', KierunekSortowania.asc);
    this.pobierzDaneDoEkranu(true);
  }

  filtrujPoTekscie(wartosc: string) {
    this.subscription?.unsubscribe();
    this.wyszukiwanie.stronicowanie.rekordPoczatkowy = 0;
    this.wyszukiwanie.stronicowanie.strona = 1;
    this.pobierzDaneDoEkranu(false);
  }

  czyPokazacWyczyscFiltry() {
    return (
      this.wyszukiwanie.filtry.paraKrypto ||
      this.wyszukiwanie.filtry.maxGodzinPozycji ||
      this.wyszukiwanie.filtry.czyAktywna ||
      this.wyszukiwanie.filtry.czyjeKonto ||
      this.wyszukiwanie.filtry.gielda ||
      this.wyszukiwanie.filtry.rodzajKonta ||
      this.wyszukiwanie.filtry.sls ||
      this.wyszukiwanie.filtry.tps ||
      this.wyszukiwanie.filtry.sll ||
      this.wyszukiwanie.filtry.tpl ||
      this.wyszukiwanie.filtry.dzwignia ||
      this.wyszukiwanie.filtry.procentKapitalu ||
      this.wyszukiwanie.filtry.macdSL ||
      this.wyszukiwanie.filtry.macdLS ||
      this.wyszukiwanie.filtry.rsiL ||
      this.wyszukiwanie.filtry.macdSS ||
      this.wyszukiwanie.filtry.tylkoAnalizaSygnalow ||
      this.wyszukiwanie.filtry.czyMaAktualnieOtwortaPozycje ||
      this.wyszukiwanie.filtry.maxWielkoscPozycji ||
      this.wyszukiwanie.filtry.odkladanyProcentNaKoniecMiesiaca ||
      this.wyszukiwanie.filtry.odJakiejKwotyOdkladac
    );
  }

  wyczyscFiltry() {
    this.wyszukiwanie.filtry.paraKrypto = '';
    this.wyszukiwanie.filtry.maxGodzinPozycji = '';
    this.wyszukiwanie.filtry.czyAktywna = '';
    this.wyszukiwanie.filtry.czyjeKonto = '';
    this.wyszukiwanie.filtry.gielda = '';
    this.wyszukiwanie.filtry.rodzajKonta = '';
    this.wyszukiwanie.filtry.sls = '';
    this.wyszukiwanie.filtry.tps = '';
    this.wyszukiwanie.filtry.sll = '';
    this.wyszukiwanie.filtry.tpl = '';
    this.wyszukiwanie.filtry.dzwignia = '';
    this.wyszukiwanie.filtry.procentKapitalu = '';
    this.wyszukiwanie.filtry.macdSL = '';
    this.wyszukiwanie.filtry.macdLS = '';
    this.wyszukiwanie.filtry.rsiL = '';
    this.wyszukiwanie.filtry.macdSS = '';
    this.wyszukiwanie.filtry.tylkoAnalizaSygnalow = '';
    this.wyszukiwanie.filtry.czyMaAktualnieOtwortaPozycje = '';
    this.wyszukiwanie.filtry.maxWielkoscPozycji = '';
    this.wyszukiwanie.filtry.odkladanyProcentNaKoniecMiesiaca = '';
    this.wyszukiwanie.filtry.odJakiejKwotyOdkladac = '';

    this.pobierzDaneDoEkranu(true);
  }

  pokazWyszukiwarkeMobile(czyPokazac: boolean) {
    this.czyPokazanoWyszukiwarkeMobile = czyPokazac;
  }
}
