export enum Routing {
  Zaloguj = 'login',
  KonfiguracjeGield = 'konfiguracje-gield',
  KonfiguracjeFutures = 'konfiguracje-futures',
  AktualnePozycje = 'aktualne-pozycje',
  HistorycznePozycje = 'historyczne-pozycje',
  CenyLive = 'ceny-live',
  Testy = 'testy',
  SzukanieNajlepszejKonfiguracji = 'szukanie-najlepszej-konfiguracji',
  TestowanieKonfiguracji = 'testowanie-konfiguracji',
  Statystyki = 'statystyki',
}
