import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PojedynczyElementResponse } from 'src/app/modele/wspolne/pojedynczyElement';
import { SerwisBazowy } from '../bazowy.service';
import { AuthService } from '../ogolne/auth.service';
import { ErrorService } from '../ogolne/error.service';
import { LoadingService } from '../ogolne/loading.service';
import { PobierzKonfiguracjeFuturesRezultat } from 'src/app/modele/futures/pobierzKonfiguracjeFuturesRezultat';
import { RejestrFiltrowanyRequest } from 'src/app/modele/wspolne/rejestrFiltrowanyRequest';
import { PobierzAktualnePozycjeFuturesRezultat } from 'src/app/modele/futures/pobierzAktualnePozycjeFuturesRezultat';
import { PobierzAktualnePozycjeFuturesFiltry } from 'src/app/modele/futures/pobierzAktualnePozycjeFuturesFiltry';
import { PobierzKonfiguracjeGieldRezultat } from 'src/app/modele/futures/pobierzKonfiguracjeGieldRezultat';
import { ListaWynikowResponse } from 'src/app/modele/wspolne/listaWynikowResponse';
import { PobierzHistorycznePozycjeFuturesFiltry } from 'src/app/modele/futures/pobierzHistorycznePozycjeFuturesFiltry';
import { PobierzHistorycznePozycjeFuturesRezultat } from 'src/app/modele/futures/pobierzHistorycznePozycjeFuturesRezultat';
import { DodajKonfiguracjeGieldModel } from 'src/app/modele/futures/dodajKonfiguracjeGieldModel';
import { ResponseBazowy } from 'src/app/modele/wspolne/responseBazowy';
import { DodajKonfiguracjeFuturesModel } from 'src/app/modele/futures/dodajKonfiguracjeFuturesModel';
import { EdytujKonfiguracjeFuturesModel } from 'src/app/modele/futures/edytujKonfiguracjeFuturesModel';
import { EdytujKonfiguracjeGieldModel } from 'src/app/modele/futures/edytujKonfiguracjeGieldModel';
import { WejdzWPozycjeModel } from 'src/app/modele/futures/wejdzWPozycjeModel';
import { IdModel } from 'src/app/modele/wspolne/id.model';
import { PobierzCenyLiveFiltry } from 'src/app/modele/ceny/pobierzCenyLiveFiltry';
import { PobierzCenyLiveRezultat } from 'src/app/modele/ceny/pobierzCenyLiveRezultat';
import { StronicowanaListaWynikowResponse } from 'src/app/modele/wspolne/stronicowanaListaWynikowResponse';
import { TestujKonfiguracjeModel } from 'src/app/modele/testy/testujKonfiguracjeModel';
import { TestujKonfiguracjeRezultat } from 'src/app/modele/testy/testujKonfiguracjeRezultat';
import { UzupelnijDaneKryptoModel } from 'src/app/modele/testy/uzupelnijDaneKryptoModel';
import { SzukanieNajlepszejKonfiguracjiModel } from 'src/app/modele/testy/szukanieNajlepszejKonfiguracjiModel';
import { PobierzWynikiSzukaniaNajlepszejKonfiguracjiRezultat } from 'src/app/modele/testy/pobierzWynikiSzukaniaNajlepszejKonfiguracjiRezultat';
import { PobierzWynikiSzukaniaNajlepszejKonfiguracjiModel } from 'src/app/modele/testy/pobierzWynikiSzukaniaNajlepszejKonfiguracjiModel';
import { PobierzStatystkiKonfiguracjiRezultat } from 'src/app/modele/statystyki/pobierzStatystkiKonfiguracjiRezultat';
import { PobierzStatystkiKonfiguracjiFiltr } from 'src/app/modele/statystyki/pobierzStatystkiKonfiguracjiFiltr';
import { PobierzKonfiguracjeGieldFiltry } from 'src/app/modele/futures/pobierzKonfiguracjeGieldFiltry';
import { PobierzKonfiguracjeFuturesFiltr } from 'src/app/modele/futures/pobierzKonfiguracjeFuturesFiltr';
import { PobierzKonfiguracjeDoSzukaniaNajlepszejKonfiguracjiRezultat } from 'src/app/modele/testy/pobierzKonfiguracjeDoSzukaniaNajlepszejKonfiguracjiRezultat';

@Injectable({
  providedIn: 'root',
})
export class FuturesService extends SerwisBazowy {
  constructor(
    httpClient: HttpClient,
    authService: AuthService,
    errorService: ErrorService,
    loaderService: LoadingService
  ) {
    super(httpClient, authService, errorService, loaderService);
  }

  pobierzKonfiguracjeGield(
    model: RejestrFiltrowanyRequest<PobierzKonfiguracjeGieldFiltry>,
    zLadowaczem: boolean
  ) {
    return this.wykonajAkcje<
      RejestrFiltrowanyRequest<PobierzKonfiguracjeGieldFiltry>,
      StronicowanaListaWynikowResponse<PobierzKonfiguracjeGieldRezultat>
    >(this.AdresApi + 'Futures/PobierzKonfiguracjeGield', model, zLadowaczem);
  }

  pobierzKonfiguracjeFutures(
    model: RejestrFiltrowanyRequest<PobierzKonfiguracjeFuturesFiltr>,
    zLadowaczem: boolean
  ) {
    return this.wykonajAkcje<
      RejestrFiltrowanyRequest<PobierzKonfiguracjeFuturesFiltr>,
      StronicowanaListaWynikowResponse<PobierzKonfiguracjeFuturesRezultat>
    >(this.AdresApi + 'Futures/PobierzKonfiguracjeFutures', model, zLadowaczem);
  }

  pobierzAktualnePozycjeFutures(
    model: RejestrFiltrowanyRequest<PobierzAktualnePozycjeFuturesFiltry>,
    zLadowaczem: boolean
  ) {
    return this.wykonajAkcje<
      RejestrFiltrowanyRequest<PobierzAktualnePozycjeFuturesFiltry>,
      StronicowanaListaWynikowResponse<PobierzAktualnePozycjeFuturesRezultat>
    >(
      this.AdresApi + 'Futures/PobierzAktualnePozycjeFutures',
      model,
      zLadowaczem
    );
  }

  pobierzHistorycznePozycjeFutures(
    model: RejestrFiltrowanyRequest<PobierzHistorycznePozycjeFuturesFiltry>,
    zLadowaczem: boolean
  ) {
    return this.wykonajAkcje<
      RejestrFiltrowanyRequest<PobierzHistorycznePozycjeFuturesFiltry>,
      StronicowanaListaWynikowResponse<PobierzHistorycznePozycjeFuturesRezultat>
    >(
      this.AdresApi + 'Futures/PobierzHistorycznePozycjeFutures',
      model,
      zLadowaczem
    );
  }

  dodajKonfiguracjeGield(model: DodajKonfiguracjeGieldModel) {
    return this.wykonajAkcje<DodajKonfiguracjeGieldModel, ResponseBazowy>(
      this.AdresApi + 'Futures/DodajKonfiguracjeGield',
      model
    );
  }

  dodajKonfiguracjeFutures(model: DodajKonfiguracjeFuturesModel) {
    return this.wykonajAkcje<DodajKonfiguracjeFuturesModel, ResponseBazowy>(
      this.AdresApi + 'Futures/DodajKonfiguracjeFutures',
      model
    );
  }

  edytujKonfiguracjeGield(model: EdytujKonfiguracjeGieldModel) {
    return this.wykonajAkcje<EdytujKonfiguracjeGieldModel, ResponseBazowy>(
      this.AdresApi + 'Futures/EdytujKonfiguracjeGield',
      model
    );
  }

  edytujKonfiguracjeFutures(model: EdytujKonfiguracjeFuturesModel) {
    return this.wykonajAkcje<EdytujKonfiguracjeFuturesModel, ResponseBazowy>(
      this.AdresApi + 'Futures/EdytujKonfiguracjeFutures',
      model
    );
  }

  wejdzWPozycje(model: WejdzWPozycjeModel) {
    return this.wykonajAkcje<WejdzWPozycjeModel, ResponseBazowy>(
      this.AdresApi + 'Futures/WejdzWPozycje',
      model
    );
  }

  sprzedajPozycje(model: IdModel) {
    return this.wykonajAkcje<IdModel, ResponseBazowy>(
      this.AdresApi + 'Futures/SprzedajPozycje',
      model
    );
  }

  pobierzCenyLive(
    model: RejestrFiltrowanyRequest<PobierzCenyLiveFiltry>,
    zLadowaczem: boolean
  ) {
    return this.wykonajAkcje<
      RejestrFiltrowanyRequest<PobierzCenyLiveFiltry>,
      StronicowanaListaWynikowResponse<PobierzCenyLiveRezultat>
    >(this.AdresApi + 'Futures/PobierzCenyLive', model, zLadowaczem);
  }

  testujKonfiguracje(model: TestujKonfiguracjeModel) {
    return this.wykonajAkcje<
      TestujKonfiguracjeModel,
      PojedynczyElementResponse<TestujKonfiguracjeRezultat>
    >(this.AdresApi + 'Futures/TestujKonfiguracje', model);
  }

  uzupelnijDaneKrypto(model: UzupelnijDaneKryptoModel) {
    return this.wykonajAkcje<UzupelnijDaneKryptoModel, ResponseBazowy>(
      this.AdresApi + 'Futures/UzupelnijDaneKrypto',
      model
    );
  }

  szukajNajlepszychKonfiguracji(model: SzukanieNajlepszejKonfiguracjiModel) {
    return this.wykonajAkcje<
      SzukanieNajlepszejKonfiguracjiModel,
      ResponseBazowy
    >(this.AdresApi + 'Futures/SzukajNajlepszychKonfiguracji', model);
  }

  pobierzWynikiSzukaniaNajlepszejKonfiguracji(
    model: PobierzWynikiSzukaniaNajlepszejKonfiguracjiModel
  ) {
    return this.wykonajAkcje<
      PobierzWynikiSzukaniaNajlepszejKonfiguracjiModel,
      PojedynczyElementResponse<PobierzWynikiSzukaniaNajlepszejKonfiguracjiRezultat>
    >(
      this.AdresApi + 'Futures/PobierzWynikiSzukaniaNajlepszejKonfiguracji',
      model,
      false
    );
  }

  zastopujSzukaniaNajlepszejKonfiguracji() {
    return this.wykonajAkcjeBezModelu<ResponseBazowy>(
      this.AdresApi + 'Futures/ZastopujSzukaniaNajlepszejKonfiguracji'
    );
  }

  pobierzStatystkiKonfiguracji(
    model: RejestrFiltrowanyRequest<PobierzStatystkiKonfiguracjiFiltr>,
    zLadowaczem: boolean
  ) {
    return this.wykonajAkcje<
      RejestrFiltrowanyRequest<PobierzStatystkiKonfiguracjiFiltr>,
      ListaWynikowResponse<PobierzStatystkiKonfiguracjiRezultat>
    >(
      this.AdresApi + 'Futures/PobierzStatystkiKonfiguracji',
      model,
      zLadowaczem
    );
  }

  zamknijWszystkiePozycje() {
    return this.wykonajAkcjeBezModelu<ResponseBazowy>(
      this.AdresApi + 'Futures/ZamknijWszystkiePozycje'
    );
  }

  zastopujWszystkieKonfiguracje() {
    return this.wykonajAkcjeBezModelu<ResponseBazowy>(
      this.AdresApi + 'Futures/ZastopujWszystkieKonfiguracje'
    );
  }

  odlozTestowo() {
    return this.wykonajAkcjeBezModelu<ResponseBazowy>(
      this.AdresApi + 'Futures/OdlozTestowo'
    );
  }

  pobierzKonfiguracjeDoSzukaniaNajlepszejKonfiguracji(zLadowaczem: boolean) {
    return this.wykonajAkcjeBezModelu<
      ListaWynikowResponse<PobierzKonfiguracjeDoSzukaniaNajlepszejKonfiguracjiRezultat>
    >(
      this.AdresApi +
        'Futures/PobierzKonfiguracjeDoSzukaniaNajlepszejKonfiguracji',
      zLadowaczem
    );
  }
}
