
import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'kalendarz-modal-content',
    templateUrl: './kalendarz-modal.html',
    providers: [DatePipe]
})
export class KalendarzModalContent implements OnInit {
    @Input() name;
    @Input() data;

    kalendarz: Kalendarz = new Kalendarz();

    constructor(public activeModal: NgbActiveModal, private router: Router, private datePipie: DatePipe) {

    }

    ngOnInit(): void {
        setTimeout(() => {
            if (this.data == undefined) {
                this.data = new Date();
            }
            this.budujKalendarz(this.data);
        });

    }

    public budujKalendarz(data: Date) {
        this.kalendarz = new Kalendarz();
        var pierwszyDzien = this.ustalPierwszyDzienMiesiaca(data);
        var ostatniDzien = this.ustalOstatniDzienMiesiaca(data);
        var pierwszePole = pierwszyDzien.getDay();
        pierwszePole = pierwszePole == 0 ? 7 : pierwszePole;
        var ostatniePole = pierwszePole + ostatniDzien.getDate() - 1;
        var dlugoscListy = ostatniePole > 35 ? 42 : 35;
        var poprzedniMiesiac = new Date(data.getFullYear(), data.getMonth() - 1, 1);
        var ostatnieDzienPM = this.ustalOstatniDzienMiesiaca(poprzedniMiesiac).getDate();

        for (let i = 1; i <= dlugoscListy; i++) {

            if (i < pierwszePole) {
                this.kalendarz.dniMiesiaca.push(new DzienMiesiaca(i, ostatnieDzienPM - pierwszePole + 1 + i, false, false));
                continue;
            }
            if (i > ostatniePole) {

                this.kalendarz.dniMiesiaca.push(new DzienMiesiaca(i, i - ostatniePole, false, false));
                continue;
            }


            this.kalendarz.dniMiesiaca.push(new DzienMiesiaca(i, i - pierwszePole + 1, true,
                this.czyAktualnyDzien(i - pierwszePole + 1, data)));
        }

        this.kalendarz.data = data;
        this.kalendarz.nazwaMiesiaca = new NazwaMiesiaca(data.getMonth(), false).nazwaMiesiaca;
        this.kalendarz.rok = data.getFullYear();
    }

    public zmienMiesiac(oIle: number) {
        var data = new Date(this.kalendarz.data.getFullYear(), this.kalendarz.data.getMonth() + oIle, this.kalendarz.data.getDate());
        this.budujKalendarz(data);
    }

    public zamknijModal() {
        this.activeModal.dismiss();
    }

    public wybierzDzien(dzien: number) {
        var data = new Date(this.kalendarz.data.getFullYear(), this.kalendarz.data.getMonth(), dzien, 12, 0, 0, 0);
        this.activeModal.close(new WybranaData(data));
    }


    private ustalPierwszyDzienMiesiaca(data: Date) {
        return new Date(data.getFullYear(), data.getMonth(), 1);
    }

    private ustalOstatniDzienMiesiaca(data: Date) {
        return new Date(data.getFullYear(), data.getMonth() + 1, 0);
    }

    private czyAktualnyDzien(numerDnia: number, data: Date) {
        var aktualnaData = new Date();

        if (aktualnaData.getMonth() == data.getMonth() && aktualnaData.getFullYear() == data.getFullYear()
            && aktualnaData.getDate() == numerDnia) {
            return true;
        }
        return false;
    }


}

export class Kalendarz {
    nazwaMiesiaca: string;
    rok: number;
    data: Date;

    dniMiesiaca: Array<DzienMiesiaca> = new Array();
}

export class DzienMiesiaca {
    constructor(numerPola, dzienMiesiaca, czyAktualnyMiesiac, czyAktywnyDzien) {
        this.numerPola = numerPola;
        this.dzienMiesiaca = dzienMiesiaca;
        this.czyAktualnyMiesiac = czyAktualnyMiesiac;
        this.czyAktywnyDzien = czyAktywnyDzien;
    }
    numerPola: number;
    dzienMiesiaca: number;
    czyAktualnyMiesiac: boolean;
    czyAktywnyDzien: boolean;
}

export class WybranaData {
    constructor(data: Date) {
        this.ustawDate(data);
    }
    data: Date;
    dzien: string;
    nazwaMiesiaca: string;
    rok: number;

    private ustawDate(data: Date) {
        this.data = data;
        this.nazwaMiesiaca = new NazwaMiesiaca(data.getMonth(), true).nazwaMiesiaca;
        this.dzien = data.getDate() < 10 ? "0" + data.getDate() : data.getDate().toString();
        this.rok = data.getFullYear();
    }


}

export class NazwaMiesiaca {
    nazwaMiesiaca: string;

    constructor(miesiac: number, nazwaZData: boolean) {
        this.nazwaMiesiaca = this.ustalNazwe(miesiac, nazwaZData);
    }

    ustalNazwe(miesiac: number, nazwaZData: boolean) {
        switch (miesiac) {
            case 0:
                return !nazwaZData ? "styczeń" : "stycznia";
            case 1:
                return !nazwaZData ? "luty" : "lutego";
            case 2:
                return !nazwaZData ? "marzec" : "marca";
            case 3:
                return !nazwaZData ? "kwiecień" : "kwietnia";
            case 4:
                return !nazwaZData ? "maj" : "maja";
            case 5:
                return !nazwaZData ? "czerwiec" : "czerwca";
            case 6:
                return !nazwaZData ? "lipiec" : "lipca";
            case 7:
                return !nazwaZData ? "sierpień" : "sierpnia";
            case 8:
                return !nazwaZData ? "wrzesień" : "września";
            case 9:
                return !nazwaZData ? "październik" : "października";
            case 10:
                return !nazwaZData ? "listopad" : "listopada";
            case 11:
                return !nazwaZData ? "grudzień" : "grudnia";
            default:
                return "";
        }
    }
}