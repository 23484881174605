import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Subscription, timer } from 'rxjs';
import { PobierzKonfiguracjeFuturesRezultat } from 'src/app/modele/futures/pobierzKonfiguracjeFuturesRezultat';
import { FuturesService } from 'src/app/serwisy/futures/futures.service';
import { AuthService } from 'src/app/serwisy/ogolne/auth.service';
import { LoaderService } from 'src/app/serwisy/ogolne/loader.service';
import { BazowyComponent } from '../../bazowy.component';
import { PobierzCenyLiveRezultat } from 'src/app/modele/ceny/pobierzCenyLiveRezultat';
import { RejestrFiltrowanyRequest } from 'src/app/modele/wspolne/rejestrFiltrowanyRequest';
import { PobierzCenyLiveFiltry } from 'src/app/modele/ceny/pobierzCenyLiveFiltry';
import { Stronicowanie } from 'src/app/modele/wspolne/stronicowanie';
import {
  KierunekSortowania,
  Sortowanie,
} from 'src/app/modele/wspolne/sortowanie';

@Component({
  selector: 'app-ceny-live',
  templateUrl: './ceny-live.component.html',
  styleUrls: ['./ceny-live.component.css'],
})
export class CenyLiveComponent
  extends BazowyComponent
  implements OnInit, OnDestroy
{
  czyPokazacMenuSortowanie: boolean;
  czyPokazanoWyszukiwarkeMobile: boolean;
  subscription: Subscription;
  czyZaladowanoDane: boolean;
  calkowitaLiczbaElementow: BigInteger;
  rozmiarTabeli: number = 100;
  czyJestWiecejRekordow: boolean = true;
  ceny: Array<PobierzCenyLiveRezultat> = new Array();
  wyszukiwanie: RejestrFiltrowanyRequest<PobierzCenyLiveFiltry> =
    new RejestrFiltrowanyRequest<PobierzCenyLiveFiltry>();
  slideConfig = {
    slidesToShow: 2,
    slidesToScroll: 1,
    prevArrow: document.getElementsByClassName('btn__left'),
    nextArrow: document.getElementsByClassName('btn__right'),
    infinite: false,
    variableWidth: true,
    initialSlide: 3,
  };

  constructor(
    public router: Router,
    modalService: NgbModal,
    loaderSerwis: LoaderService,
    komunikatSerwis: ToastrService,
    public authService: AuthService,
    public futuresSerwis: FuturesService
  ) {
    super(authService, modalService, loaderSerwis, komunikatSerwis, router);
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
  ngOnInit(): void {
    setTimeout(() => {
      this.wyszukiwanie.stronicowanie = new Stronicowanie(false);
      this.wyszukiwanie.stronicowanie.iloscRekordow = this.rozmiarTabeli;
      this.wyszukiwanie.stronicowanie.rekordPoczatkowy = 0;
      this.wyszukiwanie.stronicowanie.strona = 1;
      this.wyszukiwanie.sortowanie = new Sortowanie(
        'Data',
        KierunekSortowania.desc
      );
      this.wyszukiwanie.filtry = new PobierzCenyLiveFiltry();
      this.wybierzKrypto('ETHUSDT');
    });
  }

  pobierzDaneDoEkranu(zLadowaczem: boolean) {
    this.futuresSerwis
      .pobierzCenyLive(this.wyszukiwanie, zLadowaczem)
      .subscribe((rezultat) => {
        if (this.czyRezultatOk(rezultat)) {
          this.ceny = rezultat.listaElementow;
          this.czyZaladowanoDane = true;
          this.calkowitaLiczbaElementow = rezultat.calkowitaIloscRekordow;
          this.czyJestWiecejRekordow = rezultat.czyJestWiecejRekordow;
          return;
        }

        this.wyswietlKomunikat(rezultat.wiadomosc, true);
        return;
      });
  }

  wybierzKrypto(krypto) {
    this.wyszukiwanie.filtry.krypto = krypto;

    this.pobierzDaneDoEkranu(true);

    this.subscription?.unsubscribe();
    this.subscription = timer(20000, 20000).subscribe((x) => {
      this.pobierzDaneDoEkranu(false);
    });
  }

  zmienRozmiarTabeli() {
    this.wyszukiwanie.stronicowanie.iloscRekordow = this.rozmiarTabeli;
    this.pobierzDaneDoEkranu(true);
  }

  pobierzNumerRekorduPoczatek() {
    if (this.wyszukiwanie.stronicowanie == undefined) {
      return 1;
    }
    return this.wyszukiwanie.stronicowanie.rekordPoczatkowy + 1;
  }
  pobierzNumerRekorduKoniec() {
    if (this.wyszukiwanie.stronicowanie == undefined) {
      return 1;
    }
    return this.wyszukiwanie.stronicowanie.rekordPoczatkowy + this.ceny.length;
  }

  zmienStrone(oIle: number) {
    this.wyszukiwanie.stronicowanie.strona += oIle;
    this.wyszukiwanie.stronicowanie.rekordPoczatkowy =
      (this.wyszukiwanie.stronicowanie.strona - 1) *
      this.wyszukiwanie.stronicowanie.iloscRekordow;
    this.pobierzDaneDoEkranu(true);
  }

  pokazMenuSortowania() {
    this.czyPokazacMenuSortowanie = !this.czyPokazacMenuSortowanie;
  }

  pobierzKierunekSortowania(kolumna: string) {
    var kierunki = this.wyszukiwanie.sortowanie.listaSortowania;

    var kierunek = kierunki.find((m) => m.nazwaKolumny == kolumna);

    if (kierunek == undefined) {
      return '';
    }

    if (kierunek.kierunekSortowania == KierunekSortowania.asc) {
      return 'up';
    }
    if (kierunek.kierunekSortowania == KierunekSortowania.desc) {
      return 'down';
    }
  }

  zmienKierunekSortowania(kolumna: string) {
    this.czyPokazacMenuSortowanie = false;
    var kierunki = this.wyszukiwanie.sortowanie.listaSortowania;

    var kierunek = kierunki.find((m) => m.nazwaKolumny == kolumna);

    if (kierunek == undefined) {
      this.wyszukiwanie.sortowanie = new Sortowanie(
        kolumna,
        KierunekSortowania.asc
      );
      this.pobierzDaneDoEkranu(true);
      return;
    }

    if (kierunek.kierunekSortowania == KierunekSortowania.asc) {
      this.wyszukiwanie.sortowanie = new Sortowanie(
        kolumna,
        KierunekSortowania.desc
      );
      this.pobierzDaneDoEkranu(true);
      return;
    }
    if (kierunek.kierunekSortowania == KierunekSortowania.desc) {
      this.wyszukiwanie.sortowanie = new Sortowanie(
        'Id',
        KierunekSortowania.asc
      );
      this.pobierzDaneDoEkranu(true);
      return;
    }
  }

  pobierzKolumneSortowania() {
    var kierunki = this.wyszukiwanie.sortowanie.listaSortowania;

    switch (kierunki[0].nazwaKolumny) {
      case 'gielda':
        return 'Giełda';
      case 'rodzajKonta':
        return 'Rodzaj konta';
      case 'czyjeKonto':
        return 'Czyje konto';
      case 'czyAktywna':
        return 'Czy aktywna';
      case 'adres':
        return 'Adres API';
      case 'budzet':
        return 'Budżet';
    }
  }

  pobierzKierunekSortowaniaMobile() {
    var kierunki = this.wyszukiwanie.sortowanie.listaSortowania;

    if (kierunki[0].kierunekSortowania == KierunekSortowania.asc) {
      return 'up';
    }
    if (kierunki[0].kierunekSortowania == KierunekSortowania.desc) {
      return '';
    }
  }

  zmienKierunekSortowaniaMobile() {
    var kierunki = this.wyszukiwanie.sortowanie.listaSortowania;

    var kierunek = kierunki[0];

    if (kierunek.kierunekSortowania == KierunekSortowania.asc) {
      this.wyszukiwanie.sortowanie = new Sortowanie(
        kierunek.nazwaKolumny,
        KierunekSortowania.desc
      );
      this.pobierzDaneDoEkranu(true);
      return;
    }
    if (kierunek.kierunekSortowania == KierunekSortowania.desc) {
      this.wyszukiwanie.sortowanie = new Sortowanie(
        kierunek.nazwaKolumny,
        KierunekSortowania.asc
      );
      this.pobierzDaneDoEkranu(true);
      return;
    }
  }
  wyczyscSortowanieMobile() {
    this.wyszukiwanie.sortowanie = new Sortowanie('Id', KierunekSortowania.asc);
    this.pobierzDaneDoEkranu(true);
  }

  filtrujPoTekscie(wartosc: string) {
    this.subscription?.unsubscribe();
    this.wyszukiwanie.stronicowanie.rekordPoczatkowy = 0;
    this.wyszukiwanie.stronicowanie.strona = 1;
    this.pobierzDaneDoEkranu(false);
  }

  // czyPokazacWyczyscFiltry() {
  //   return (
  //     this.wyszukiwanie.filtry.adres ||
  //     this.wyszukiwanie.filtry.budzet ||
  //     this.wyszukiwanie.filtry.czyAktywna ||
  //     this.wyszukiwanie.filtry.czyjeKonto ||
  //     this.wyszukiwanie.filtry.gielda ||
  //     this.wyszukiwanie.filtry.rodzajKonta
  //   );
  // }

  // wyczyscFiltry() {
  //   this.wyszukiwanie.filtry.adres = '';
  //   this.wyszukiwanie.filtry.budzet = '';
  //   this.wyszukiwanie.filtry.czyAktywna = '';
  //   this.wyszukiwanie.filtry.czyjeKonto = '';
  //   this.wyszukiwanie.filtry.gielda = '';
  //   this.wyszukiwanie.filtry.rodzajKonta = '';

  //   this.pobierzDaneDoEkranu(true);
  // }

  pokazWyszukiwarkeMobile(czyPokazac: boolean) {
    this.czyPokazanoWyszukiwarkeMobile = czyPokazac;
  }
}
