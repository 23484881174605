<div class="modal-content">
    <div class="modal-body">
        <button aria-label="Close" class="close" data-dismiss="modal" type="button"
            (click)="activeModal.dismiss()"></button>

        <div class="ft__03--2 text-center mt-3 pb-1 pt-5 pt-md-2">{{naglowek}}
        </div>

        <div class="ft__03--2 text-center pb-4 pt-0 pt-md-2">{{pytanie}}</div>

        <div class="row pb-3">
            <div class="col-6">
                <div class="input_container pb-3 pt-2">
                    <button class="btn__border" (click)="nie()">Nie</button>
                </div>
            </div>
            <div class="col-6">
                <div class="input_container pb-3 pt-2 ">
                    <button class="btn__border" (click)="tak()">TAK</button>
                </div>
            </div>
        </div>

    </div>
</div>