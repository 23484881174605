export class DodajKonfiguracjeFuturesModel {
  gieldaId: string;
  czyAktywna: boolean;
  paraKrypto: string;
  dataZakonczenia: Date;
  maxGodzinPozycji: number;
  sLS: number;
  tPS: number;
  sLL: number;
  tPL: number;
  dzwignia: number;
  procentKapitalu: number;
  macdSL: number;
  macdLS: number;
  rsiL: number;
  macdSS: number;
  tylkoAnalizaSygnalow: boolean;
  maxWielkoscPozycji: number;
  odkladanyProcentNaKoniecMiesiaca?: number;
  odJakiejKwotyOdkladac?: number;
  predykcjaWaga5m: number;
  predykcjaWaga15m: number;
  predykcjaWaga1h: number;
  predykcjaWaga4h: number;
  predykcjaWaga1d: number;
  predykcjaWaga3d: number;
  predykcjaMarginesBledu: number;
  wersjaBota: string;
  czyPrzesuwacGranice: boolean;
  przesuniecieGranicyTPLongProcent?: number;
  przesuniecieGranicySLLongProcent?: number;
  przesuniecieGranicyTPShortProcent?: number;
  przesuniecieGranicySLShortProcent?: number;
}
