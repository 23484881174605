export class PobierzCenyLiveFiltry {
  krypto: string;
  data: string;
  cenaOtwarcia: string;
  cenaMax: string;
  cenaMin: string;
  cenaZamkniecia: string;
  cenaSrednia: string;
  id: string;
  rsi14: string;
  ema: string;
  macdSignal: string;
  macdHistogram: string;
  macdLine: string;
  emaWartosc: string;
  emaF: string;
  emaFWartosc: string;
  gielda: string;
  rodzajKonta: string;
  czyjeKonto: string;
  sygnal: string;
}
