export class PobierzStatystkiKonfiguracjiFiltr {
  dataOd?: string;
  dataDo?: string;
  gielda: string;
  rodzajKonta: string;
  czyjeKonto: string;
  adres: string;
  pozycjeStratne: string;
  pozycjeZyskowne: string;
  skutecznosc: string;
  stracono: string;
  zarobiono: string;
  wszystkiePozycje: string;
}
