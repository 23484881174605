<div>
  <div class="container--main d-none d-md-block">
    <div class="ft__sort text-center pt-2">
      Szukanie najlepszej konfiguracji | Kombinacje:
      {{ licznikKombinacji }} Czas na wykonanie (h):
      {{ czasNaWykonanie | number : "1.1-2" }}
    </div>
  </div>

  <div class="float-container d-none d-md-block">
    <div class="float-child-left">
      <form action="">
        <div class="input_container mb-4">
          <select
            [(ngModel)]="model.krypto"
            name="krypto"
            (change)="wybierzKrypto()"
          >
            <option [ngValue]="undefined" [disabled]="true">Krypto</option>
            <option
              [ngValue]="krypto.key"
              *ngFor="let krypto of dostepneKrypto"
            >
              {{ krypto.value }}
            </option>
          </select>
        </div>
        <div class="input_container mb-4">
          <select [(ngModel)]="model.gieldaId" name="gielda">
            <option [ngValue]="undefined" [disabled]="true">Giełda</option>
            <option [ngValue]="gielda.id" *ngFor="let gielda of gieldy">
              {{ gielda.gielda }} - {{ gielda.rodzajKonta }} -
              {{ gielda.czyjeKonto }}
            </option>
          </select>
        </div>
        <div
          class="input_container pb-3"
          [ngClass]="czyBladWalidacyjny('dataOd') ? 'error' : ''"
        >
          Data od
          <input
            type="date"
            placeholder="Data od"
            [(ngModel)]="model.dataOd"
            name="dataOd"
          />
          <div
            class="error-info fs__error pt-2"
            *ngIf="czyBladWalidacyjny('dataOd')"
          >
            {{ pobierzBladWalidacyjny("dataOd") }}
          </div>
        </div>
        <div
          class="input_container pb-3"
          [ngClass]="czyBladWalidacyjny('dataDo') ? 'error' : ''"
        >
          Data do
          <input
            type="date"
            placeholder="Data do"
            [(ngModel)]="model.dataDo"
            name="dataDo"
          />
          <div
            class="error-info fs__error pt-2"
            *ngIf="czyBladWalidacyjny('dataDo')"
          >
            {{ pobierzBladWalidacyjny("dataDo") }}
          </div>
        </div>

        <div
          class="input_container pb-3"
          [ngClass]="czyBladWalidacyjny('tplOd') ? 'error' : ''"
        >
          Long take profit % - od
          <input
            type="number"
            placeholder="Long take profit %"
            [(ngModel)]="model.tplOd"
            name="tplOd"
          />
          <div
            class="error-info fs__error pt-2"
            *ngIf="czyBladWalidacyjny('tplOd')"
          >
            {{ pobierzBladWalidacyjny("tplOd") }}
          </div>
        </div>
        <div
          class="input_container pb-3"
          [ngClass]="czyBladWalidacyjny('tplDo') ? 'error' : ''"
        >
          Long take profit % - do
          <input
            type="number"
            placeholder="Long take profit %"
            [(ngModel)]="model.tplDo"
            name="tplDo"
          />
          <div
            class="error-info fs__error pt-2"
            *ngIf="czyBladWalidacyjny('tplDo')"
          >
            {{ pobierzBladWalidacyjny("tplDo") }}
          </div>
        </div>
        <div
          class="input_container pb-3"
          [ngClass]="czyBladWalidacyjny('tplKrok') ? 'error' : ''"
        >
          Long take profit - krok
          <input
            type="number"
            placeholder="Long take profit %"
            [(ngModel)]="model.tplKrok"
            name="tplKrok"
          />
          <div
            class="error-info fs__error pt-2"
            *ngIf="czyBladWalidacyjny('tplKrok')"
          >
            {{ pobierzBladWalidacyjny("tplKrok") }}
          </div>
        </div>

        <div
          class="input_container pb-3"
          [ngClass]="czyBladWalidacyjny('sllOd') ? 'error' : ''"
        >
          Long stop loss % - od
          <input
            type="number"
            placeholder="Long stop loss %"
            [(ngModel)]="model.sllOd"
            name="sllOd"
          />
          <div
            class="error-info fs__error pt-2"
            *ngIf="czyBladWalidacyjny('sllOd')"
          >
            {{ pobierzBladWalidacyjny("sllOd") }}
          </div>
        </div>
        <div
          class="input_container pb-3"
          [ngClass]="czyBladWalidacyjny('sllDo') ? 'error' : ''"
        >
          Long stop loss % - do
          <input
            type="number"
            placeholder="Long stop loss %"
            [(ngModel)]="model.sllDo"
            name="sllDo"
          />
          <div
            class="error-info fs__error pt-2"
            *ngIf="czyBladWalidacyjny('sllDo')"
          >
            {{ pobierzBladWalidacyjny("sllDo") }}
          </div>
        </div>
        <div
          class="input_container pb-3"
          [ngClass]="czyBladWalidacyjny('sllKrok') ? 'error' : ''"
        >
          Long stop loss % - krok
          <input
            type="number"
            placeholder="Long stop loss %"
            [(ngModel)]="model.sllKrok"
            name="sllKrok"
          />
          <div
            class="error-info fs__error pt-2"
            *ngIf="czyBladWalidacyjny('sllKrok')"
          >
            {{ pobierzBladWalidacyjny("sllKrok") }}
          </div>
        </div>

        <div
          class="input_container pb-3"
          [ngClass]="czyBladWalidacyjny('tpsOd') ? 'error' : ''"
        >
          Short take profit % - od
          <input
            type="number"
            placeholder="Short take profit %"
            [(ngModel)]="model.tpsOd"
            name="tpsOd"
          />
          <div
            class="error-info fs__error pt-2"
            *ngIf="czyBladWalidacyjny('tpsOd')"
          >
            {{ pobierzBladWalidacyjny("tpsOd") }}
          </div>
        </div>
        <div
          class="input_container pb-3"
          [ngClass]="czyBladWalidacyjny('tpsDo') ? 'error' : ''"
        >
          Short take profit % - do
          <input
            type="number"
            placeholder="Short take profit %"
            [(ngModel)]="model.tpsDo"
            name="tpsDo"
          />
          <div
            class="error-info fs__error pt-2"
            *ngIf="czyBladWalidacyjny('tpsDo')"
          >
            {{ pobierzBladWalidacyjny("tpsDo") }}
          </div>
        </div>
        <div
          class="input_container pb-3"
          [ngClass]="czyBladWalidacyjny('tpsKrok') ? 'error' : ''"
        >
          Short take profit % - krok
          <input
            type="number"
            placeholder="Short take profit %"
            [(ngModel)]="model.tpsKrok"
            name="tpsKrok"
          />
          <div
            class="error-info fs__error pt-2"
            *ngIf="czyBladWalidacyjny('tpsKrok')"
          >
            {{ pobierzBladWalidacyjny("tpsKrok") }}
          </div>
        </div>

        <div
          class="input_container pb-3"
          [ngClass]="czyBladWalidacyjny('slsOd') ? 'error' : ''"
        >
          Short stop loss % - od
          <input
            type="number"
            placeholder="Short stop loss %"
            [(ngModel)]="model.slsOd"
            name="slsOd"
          />
          <div
            class="error-info fs__error pt-2"
            *ngIf="czyBladWalidacyjny('slsOd')"
          >
            {{ pobierzBladWalidacyjny("slsOd") }}
          </div>
        </div>
        <div
          class="input_container pb-3"
          [ngClass]="czyBladWalidacyjny('slsDo') ? 'error' : ''"
        >
          Short stop loss % - do
          <input
            type="number"
            placeholder="Short stop loss %"
            [(ngModel)]="model.slsDo"
            name="slsDo"
          />
          <div
            class="error-info fs__error pt-2"
            *ngIf="czyBladWalidacyjny('slsDo')"
          >
            {{ pobierzBladWalidacyjny("slsDo") }}
          </div>
        </div>
        <div
          class="input_container pb-3"
          [ngClass]="czyBladWalidacyjny('slsKrok') ? 'error' : ''"
        >
          Short stop loss % - krok
          <input
            type="number"
            placeholder="Short stop loss %"
            [(ngModel)]="model.slsKrok"
            name="slsKrok"
          />
          <div
            class="error-info fs__error pt-2"
            *ngIf="czyBladWalidacyjny('slsKrok')"
          >
            {{ pobierzBladWalidacyjny("slsKrok") }}
          </div>
        </div>

        <div class="input_container pb-3">
          macdSL - od
          <input
            type="number"
            placeholder="Short stop loss %"
            [(ngModel)]="model.macdSLOd"
            name="macdSLOd"
          />
        </div>
        <div class="input_container pb-3">
          macdSL - do
          <input
            type="number"
            placeholder="Short stop loss %"
            [(ngModel)]="model.macdSLDo"
            name="macdSLDo"
          />
        </div>
        <div class="input_container pb-3">
          macdSL - krok
          <input
            type="number"
            placeholder="Short stop loss %"
            [(ngModel)]="model.macdSLKrok"
            name="macdSLKrok"
          />
        </div>
        <div class="input_container pb-3">
          macdSS - od
          <input
            type="number"
            placeholder="Short stop loss %"
            [(ngModel)]="model.macdSSOd"
            name="macdSSOd"
          />
        </div>
        <div class="input_container pb-3">
          macdSS - do
          <input
            type="number"
            placeholder="Short stop loss %"
            [(ngModel)]="model.macdSSDo"
            name="macdSSDo"
          />
        </div>
        <div class="input_container pb-3">
          macdSS - krok
          <input
            type="number"
            placeholder="Short stop loss %"
            [(ngModel)]="model.macdSSKrok"
            name="macdSSKrok"
          />
        </div>

        <div class="input_container pb-3">
          macdLS - od
          <input
            type="number"
            placeholder="Short stop loss %"
            [(ngModel)]="model.macdLSOd"
            name="macdLSOd"
          />
        </div>
        <div class="input_container pb-3">
          macdLS - do
          <input
            type="number"
            placeholder="Short stop loss %"
            [(ngModel)]="model.macdLSDo"
            name="macdLSDo"
          />
        </div>
        <div class="input_container pb-3">
          macdLS - krok
          <input
            type="number"
            placeholder="Short stop loss %"
            [(ngModel)]="model.macdLSKrok"
            name="macdLSKrok"
          />
        </div>

        <div
          class="input_container pb-3"
          [ngClass]="czyBladWalidacyjny('dzwignia') ? 'error' : ''"
        >
          Dźwignia
          <input
            type="number"
            placeholder="Dźwignia"
            [(ngModel)]="model.dzwignia"
            name="dzwignia"
          />
          <div
            class="error-info fs__error pt-2"
            *ngIf="czyBladWalidacyjny('dzwignia')"
          >
            {{ pobierzBladWalidacyjny("dzwignia") }}
          </div>
        </div>

        <div
          class="input_container pb-3"
          [ngClass]="czyBladWalidacyjny('budzet') ? 'error' : ''"
        >
          Budżet $
          <input
            type="number"
            placeholder="Budżet"
            [(ngModel)]="model.budzet"
            name="budzet"
          />
          <div
            class="error-info fs__error pt-2"
            *ngIf="czyBladWalidacyjny('budzet')"
          >
            {{ pobierzBladWalidacyjny("budzet") }}
          </div>
        </div>

        <div
          class="input_container pb-3"
          [ngClass]="czyBladWalidacyjny('procentKapitalu') ? 'error' : ''"
        >
          Procent kapitału %
          <input
            type="number"
            placeholder="Procent kapitału"
            [(ngModel)]="model.procentKapitalu"
            name="procentKapitalu"
          />
          <div
            class="error-info fs__error pt-2"
            *ngIf="czyBladWalidacyjny('procentKapitalu')"
          >
            {{ pobierzBladWalidacyjny("procentKapitalu") }}
          </div>
        </div>
        <div
          class="input_container pb-3"
          [ngClass]="czyBladWalidacyjny('maxGodzinPozycji') ? 'error' : ''"
        >
          Max godzin pozycji
          <input
            type="number"
            placeholder="Max godzin pozycji"
            [(ngModel)]="model.maxGodzinPozycji"
            name="sll"
          />
          <div
            class="error-info fs__error pt-2"
            *ngIf="czyBladWalidacyjny('maxGodzinPozycji')"
          >
            {{ pobierzBladWalidacyjny("maxGodzinPozycji") }}
          </div>
        </div>
        <div
          class="input_container pb-3"
          [ngClass]="czyBladWalidacyjny('odJakiejKwotyOdkladac') ? 'error' : ''"
        >
          Od jakiej kwoty odkładać $
          <input
            type="number"
            placeholder="Od jakiej kwoty odkładać"
            [(ngModel)]="model.odJakiejKwotyOdkladac"
            name="odJakiejKwotyOdkladac"
          />
          <div
            class="error-info fs__error pt-2"
            *ngIf="czyBladWalidacyjny('odJakiejKwotyOdkladac')"
          >
            {{ pobierzBladWalidacyjny("odJakiejKwotyOdkladac") }}
          </div>
        </div>

        <div
          class="input_container pb-3"
          [ngClass]="
            czyBladWalidacyjny('odkladanyProcentNaKoniecMiesiaca')
              ? 'error'
              : ''
          "
        >
          Odkładany procent na koniec miesiąca %
          <input
            type="number"
            placeholder="Odkładany procent na koniec miesiąca"
            [(ngModel)]="model.odkladanyProcentNaKoniecMiesiaca"
            name="odkladanyProcentNaKoniecMiesiaca"
          />
          <div
            class="error-info fs__error pt-2"
            *ngIf="czyBladWalidacyjny('odkladanyProcentNaKoniecMiesiaca')"
          >
            {{ pobierzBladWalidacyjny("odkladanyProcentNaKoniecMiesiaca") }}
          </div>
        </div>
        <div
          class="input_container pb-3"
          [ngClass]="czyBladWalidacyjny('maxWielkoscPozycji') ? 'error' : ''"
        >
          Max wielkość pozycji $
          <input
            type="number"
            placeholder="Max wielkość pozycji"
            [(ngModel)]="model.maxWielkoscPozycji"
            name="maxWielkoscPozycji"
          />
          <div
            class="error-info fs__error pt-2"
            *ngIf="czyBladWalidacyjny('maxWielkoscPozycji')"
          >
            {{ pobierzBladWalidacyjny("maxWielkoscPozycji") }}
          </div>
        </div>
        <div
          class="input_container pb-3"
          [ngClass]="czyBladWalidacyjny('rsiL') ? 'error' : ''"
        >
          RSI
          <input
            type="number"
            placeholder="RSI"
            [(ngModel)]="model.rsiL"
            name="rsiL"
          />
          <div
            class="error-info fs__error pt-2"
            *ngIf="czyBladWalidacyjny('rsiL')"
          >
            {{ pobierzBladWalidacyjny("rsiL") }}
          </div>
        </div>
      </form>
    </div>

    <div class="float-child-right">
      Licznik: {{ licznik }}, Ostatnia data modyfikacji:
      {{ ostatniaDataModyfikacji }}
      <div class="container-fluid d-none d-md-block">
        <div class="box__scroll py-3">
          <div class="tableFixHead tableFixHead--3" style="height: 70vh">
            <table class="table">
              <thead>
                <tr>
                  <th>TPS</th>
                  <th>SLS</th>
                  <th>TPL</th>
                  <th>SLL</th>
                  <th>macdSL</th>
                  <th>macdLS</th>
                  <th>macdSS</th>
                  <th>Pozycje stratne</th>
                  <th>Pozycje zyskowne</th>
                  <th>skuteczność %</th>
                  <th>Stracono</th>
                  <th>Zarobiono</th>
                  <th>Wynik</th>
                </tr>
              </thead>
              <tbody class="fz12" *ngIf="czySaWynikiNaLiscie(rezultaty)">
                <tr
                  *ngFor="let rezultat of rezultaty"
                  class="pointer project-row"
                >
                  <td>{{ rezultat.procentowyZyskSprzedazyShorta }}</td>
                  <td>{{ rezultat.stopLossProcentShort }}</td>
                  <td>{{ rezultat.procentowyZyskSprzedazyLonga }}</td>
                  <td>{{ rezultat.stopLossProcentLong }}</td>
                  <td>{{ rezultat.macdSL }}</td>
                  <td>{{ rezultat.macdLS }}</td>
                  <td>{{ rezultat.macdSS }}</td>
                  <td>{{ rezultat.pozycjeStratne }}</td>
                  <td>{{ rezultat.pozycjeZyskowne }}</td>
                  <td>{{ rezultat.skutecznosc | number : "1.1-2" }}</td>
                  <td>{{ rezultat.stracono | currency : "PLN" }}</td>
                  <td>{{ rezultat.zarobiono | currency : "PLN" }}</td>
                  <td>{{ rezultat.wynik | currency : "PLN" }}</td>
                </tr>
              </tbody>
            </table>
            <div
              class="ft__03 text-gray text-center my-5 py-5"
              *ngIf="!czySaWynikiNaLiscie(rezultaty)"
            >
              <div>Brak wyników dla wybranych filtrów</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- mobile -->
  <div class="container-fluid d-md-none pb-5">
    <div class="pt-3 mt-1">
      <div class="ft__sort text-center">
        Szukanie najlepszej konfiguracji | Kombinacje:
        {{ licznikKombinacji }} Czas na wykonanie (h):
        {{ czasNaWykonanie | number : "1.1-2" }}
      </div>
    </div>

    <div class="box__mobiled mb-4">
      <form action="">
        <div class="input_container mb-4">
          <select
            [(ngModel)]="model.krypto"
            name="krypto"
            (change)="wybierzKrypto()"
          >
            <option [ngValue]="undefined" [disabled]="true">Krypto</option>
            <option
              [ngValue]="krypto.key"
              *ngFor="let krypto of dostepneKrypto"
            >
              {{ krypto.value }}
            </option>
          </select>
        </div>
        <div class="input_container mb-4">
          <select [(ngModel)]="model.gieldaId" name="gielda">
            <option [ngValue]="undefined" [disabled]="true">Giełda</option>
            <option [ngValue]="gielda.id" *ngFor="let gielda of gieldy">
              {{ gielda.gielda }} - {{ gielda.rodzajKonta }} -
              {{ gielda.czyjeKonto }}
            </option>
          </select>
        </div>
        <div
          class="input_container pb-3"
          [ngClass]="czyBladWalidacyjny('dataOd') ? 'error' : ''"
        >
          Data od
          <input
            type="date"
            placeholder="Data od"
            [(ngModel)]="model.dataOd"
            name="dataOd"
          />
          <div
            class="error-info fs__error pt-2"
            *ngIf="czyBladWalidacyjny('dataOd')"
          >
            {{ pobierzBladWalidacyjny("dataOd") }}
          </div>
        </div>
        <div
          class="input_container pb-3"
          [ngClass]="czyBladWalidacyjny('dataDo') ? 'error' : ''"
        >
          Data do
          <input
            type="date"
            placeholder="Data do"
            [(ngModel)]="model.dataDo"
            name="dataDo"
          />
          <div
            class="error-info fs__error pt-2"
            *ngIf="czyBladWalidacyjny('dataDo')"
          >
            {{ pobierzBladWalidacyjny("dataDo") }}
          </div>
        </div>

        <div
          class="input_container pb-3"
          [ngClass]="czyBladWalidacyjny('tplOd') ? 'error' : ''"
        >
          Long take profit % - od
          <input
            type="number"
            placeholder="Long take profit %"
            [(ngModel)]="model.tplOd"
            name="tplOd"
          />
          <div
            class="error-info fs__error pt-2"
            *ngIf="czyBladWalidacyjny('tplOd')"
          >
            {{ pobierzBladWalidacyjny("tplOd") }}
          </div>
        </div>
        <div
          class="input_container pb-3"
          [ngClass]="czyBladWalidacyjny('tplDo') ? 'error' : ''"
        >
          Long take profit % - do
          <input
            type="number"
            placeholder="Long take profit %"
            [(ngModel)]="model.tplDo"
            name="tplDo"
          />
          <div
            class="error-info fs__error pt-2"
            *ngIf="czyBladWalidacyjny('tplDo')"
          >
            {{ pobierzBladWalidacyjny("tplDo") }}
          </div>
        </div>
        <div
          class="input_container pb-3"
          [ngClass]="czyBladWalidacyjny('tplKrok') ? 'error' : ''"
        >
          Long take profit - krok
          <input
            type="number"
            placeholder="Long take profit %"
            [(ngModel)]="model.tplKrok"
            name="tplKrok"
          />
          <div
            class="error-info fs__error pt-2"
            *ngIf="czyBladWalidacyjny('tplKrok')"
          >
            {{ pobierzBladWalidacyjny("tplKrok") }}
          </div>
        </div>

        <div
          class="input_container pb-3"
          [ngClass]="czyBladWalidacyjny('sllOd') ? 'error' : ''"
        >
          Long stop loss % - od
          <input
            type="number"
            placeholder="Long stop loss %"
            [(ngModel)]="model.sllOd"
            name="sllOd"
          />
          <div
            class="error-info fs__error pt-2"
            *ngIf="czyBladWalidacyjny('sllOd')"
          >
            {{ pobierzBladWalidacyjny("sllOd") }}
          </div>
        </div>
        <div
          class="input_container pb-3"
          [ngClass]="czyBladWalidacyjny('sllDo') ? 'error' : ''"
        >
          Long stop loss % - do
          <input
            type="number"
            placeholder="Long stop loss %"
            [(ngModel)]="model.sllDo"
            name="sllDo"
          />
          <div
            class="error-info fs__error pt-2"
            *ngIf="czyBladWalidacyjny('sllDo')"
          >
            {{ pobierzBladWalidacyjny("sllDo") }}
          </div>
        </div>
        <div
          class="input_container pb-3"
          [ngClass]="czyBladWalidacyjny('sllKrok') ? 'error' : ''"
        >
          Long stop loss % - krok
          <input
            type="number"
            placeholder="Long stop loss %"
            [(ngModel)]="model.sllKrok"
            name="sllKrok"
          />
          <div
            class="error-info fs__error pt-2"
            *ngIf="czyBladWalidacyjny('sllKrok')"
          >
            {{ pobierzBladWalidacyjny("sllKrok") }}
          </div>
        </div>

        <div
          class="input_container pb-3"
          [ngClass]="czyBladWalidacyjny('tpsOd') ? 'error' : ''"
        >
          Short take profit % - od
          <input
            type="number"
            placeholder="Short take profit %"
            [(ngModel)]="model.tpsOd"
            name="tpsOd"
          />
          <div
            class="error-info fs__error pt-2"
            *ngIf="czyBladWalidacyjny('tpsOd')"
          >
            {{ pobierzBladWalidacyjny("tpsOd") }}
          </div>
        </div>
        <div
          class="input_container pb-3"
          [ngClass]="czyBladWalidacyjny('tpsDo') ? 'error' : ''"
        >
          Short take profit % - do
          <input
            type="number"
            placeholder="Short take profit %"
            [(ngModel)]="model.tpsDo"
            name="tpsDo"
          />
          <div
            class="error-info fs__error pt-2"
            *ngIf="czyBladWalidacyjny('tpsDo')"
          >
            {{ pobierzBladWalidacyjny("tpsDo") }}
          </div>
        </div>
        <div
          class="input_container pb-3"
          [ngClass]="czyBladWalidacyjny('tpsKrok') ? 'error' : ''"
        >
          Short take profit % - krok
          <input
            type="number"
            placeholder="Short take profit %"
            [(ngModel)]="model.tpsKrok"
            name="tpsKrok"
          />
          <div
            class="error-info fs__error pt-2"
            *ngIf="czyBladWalidacyjny('tpsKrok')"
          >
            {{ pobierzBladWalidacyjny("tpsKrok") }}
          </div>
        </div>

        <div
          class="input_container pb-3"
          [ngClass]="czyBladWalidacyjny('slsOd') ? 'error' : ''"
        >
          Short stop loss % - od
          <input
            type="number"
            placeholder="Short stop loss %"
            [(ngModel)]="model.slsOd"
            name="slsOd"
          />
          <div
            class="error-info fs__error pt-2"
            *ngIf="czyBladWalidacyjny('slsOd')"
          >
            {{ pobierzBladWalidacyjny("slsOd") }}
          </div>
        </div>
        <div
          class="input_container pb-3"
          [ngClass]="czyBladWalidacyjny('slsDo') ? 'error' : ''"
        >
          Short stop loss % - do
          <input
            type="number"
            placeholder="Short stop loss %"
            [(ngModel)]="model.slsDo"
            name="slsDo"
          />
          <div
            class="error-info fs__error pt-2"
            *ngIf="czyBladWalidacyjny('slsDo')"
          >
            {{ pobierzBladWalidacyjny("slsDo") }}
          </div>
        </div>
        <div
          class="input_container pb-3"
          [ngClass]="czyBladWalidacyjny('slsKrok') ? 'error' : ''"
        >
          Short stop loss % - krok
          <input
            type="number"
            placeholder="Short stop loss %"
            [(ngModel)]="model.slsKrok"
            name="slsKrok"
          />
          <div
            class="error-info fs__error pt-2"
            *ngIf="czyBladWalidacyjny('slsKrok')"
          >
            {{ pobierzBladWalidacyjny("slsKrok") }}
          </div>
        </div>

        <div class="input_container pb-3">
          macdSL - od
          <input
            type="number"
            placeholder="Short stop loss %"
            [(ngModel)]="model.macdSLOd"
            name="macdSLOd"
          />
        </div>
        <div class="input_container pb-3">
          macdSL - do
          <input
            type="number"
            placeholder="Short stop loss %"
            [(ngModel)]="model.macdSLDo"
            name="macdSLDo"
          />
        </div>
        <div class="input_container pb-3">
          macdSL - krok
          <input
            type="number"
            placeholder="Short stop loss %"
            [(ngModel)]="model.macdSLKrok"
            name="macdSLKrok"
          />
        </div>
        <div class="input_container pb-3">
          macdSS - od
          <input
            type="number"
            placeholder="Short stop loss %"
            [(ngModel)]="model.macdSSOd"
            name="macdSSOd"
          />
        </div>
        <div class="input_container pb-3">
          macdSS - do
          <input
            type="number"
            placeholder="Short stop loss %"
            [(ngModel)]="model.macdSSDo"
            name="macdSSDo"
          />
        </div>
        <div class="input_container pb-3">
          macdSS - krok
          <input
            type="number"
            placeholder="Short stop loss %"
            [(ngModel)]="model.macdSSKrok"
            name="macdSSKrok"
          />
        </div>

        <div class="input_container pb-3">
          macdLS - od
          <input
            type="number"
            placeholder="Short stop loss %"
            [(ngModel)]="model.macdLSOd"
            name="macdLSOd"
          />
        </div>
        <div class="input_container pb-3">
          macdLS - do
          <input
            type="number"
            placeholder="Short stop loss %"
            [(ngModel)]="model.macdLSDo"
            name="macdLSDo"
          />
        </div>
        <div class="input_container pb-3">
          macdLS - krok
          <input
            type="number"
            placeholder="Short stop loss %"
            [(ngModel)]="model.macdLSKrok"
            name="macdLSKrok"
          />
        </div>

        <div
          class="input_container pb-3"
          [ngClass]="czyBladWalidacyjny('dzwignia') ? 'error' : ''"
        >
          Dźwignia
          <input
            type="number"
            placeholder="Dźwignia"
            [(ngModel)]="model.dzwignia"
            name="dzwignia"
          />
          <div
            class="error-info fs__error pt-2"
            *ngIf="czyBladWalidacyjny('dzwignia')"
          >
            {{ pobierzBladWalidacyjny("dzwignia") }}
          </div>
        </div>

        <div
          class="input_container pb-3"
          [ngClass]="czyBladWalidacyjny('budzet') ? 'error' : ''"
        >
          Budżet $
          <input
            type="number"
            placeholder="Budżet"
            [(ngModel)]="model.budzet"
            name="budzet"
          />
          <div
            class="error-info fs__error pt-2"
            *ngIf="czyBladWalidacyjny('budzet')"
          >
            {{ pobierzBladWalidacyjny("budzet") }}
          </div>
        </div>

        <div
          class="input_container pb-3"
          [ngClass]="czyBladWalidacyjny('procentKapitalu') ? 'error' : ''"
        >
          Procent kapitału %
          <input
            type="number"
            placeholder="Procent kapitału"
            [(ngModel)]="model.procentKapitalu"
            name="procentKapitalu"
          />
          <div
            class="error-info fs__error pt-2"
            *ngIf="czyBladWalidacyjny('procentKapitalu')"
          >
            {{ pobierzBladWalidacyjny("procentKapitalu") }}
          </div>
        </div>
        <div
          class="input_container pb-3"
          [ngClass]="czyBladWalidacyjny('maxGodzinPozycji') ? 'error' : ''"
        >
          Max godzin pozycji
          <input
            type="number"
            placeholder="Max godzin pozycji"
            [(ngModel)]="model.maxGodzinPozycji"
            name="sll"
          />
          <div
            class="error-info fs__error pt-2"
            *ngIf="czyBladWalidacyjny('maxGodzinPozycji')"
          >
            {{ pobierzBladWalidacyjny("maxGodzinPozycji") }}
          </div>
        </div>
        <div
          class="input_container pb-3"
          [ngClass]="czyBladWalidacyjny('odJakiejKwotyOdkladac') ? 'error' : ''"
        >
          Od jakiej kwoty odkładać $
          <input
            type="number"
            placeholder="Od jakiej kwoty odkładać"
            [(ngModel)]="model.odJakiejKwotyOdkladac"
            name="odJakiejKwotyOdkladac"
          />
          <div
            class="error-info fs__error pt-2"
            *ngIf="czyBladWalidacyjny('odJakiejKwotyOdkladac')"
          >
            {{ pobierzBladWalidacyjny("odJakiejKwotyOdkladac") }}
          </div>
        </div>

        <div
          class="input_container pb-3"
          [ngClass]="
            czyBladWalidacyjny('odkladanyProcentNaKoniecMiesiaca')
              ? 'error'
              : ''
          "
        >
          Odkładany procent na koniec miesiąca %
          <input
            type="number"
            placeholder="Odkładany procent na koniec miesiąca"
            [(ngModel)]="model.odkladanyProcentNaKoniecMiesiaca"
            name="odkladanyProcentNaKoniecMiesiaca"
          />
          <div
            class="error-info fs__error pt-2"
            *ngIf="czyBladWalidacyjny('odkladanyProcentNaKoniecMiesiaca')"
          >
            {{ pobierzBladWalidacyjny("odkladanyProcentNaKoniecMiesiaca") }}
          </div>
        </div>
        <div
          class="input_container pb-3"
          [ngClass]="czyBladWalidacyjny('maxWielkoscPozycji') ? 'error' : ''"
        >
          Max wielkość pozycji $
          <input
            type="number"
            placeholder="Max wielkość pozycji"
            [(ngModel)]="model.maxWielkoscPozycji"
            name="maxWielkoscPozycji"
          />
          <div
            class="error-info fs__error pt-2"
            *ngIf="czyBladWalidacyjny('maxWielkoscPozycji')"
          >
            {{ pobierzBladWalidacyjny("maxWielkoscPozycji") }}
          </div>
        </div>
        <div
          class="input_container pb-3"
          [ngClass]="czyBladWalidacyjny('rsiL') ? 'error' : ''"
        >
          RSI
          <input
            type="number"
            placeholder="RSI"
            [(ngModel)]="model.rsiL"
            name="rsiL"
          />
          <div
            class="error-info fs__error pt-2"
            *ngIf="czyBladWalidacyjny('rsiL')"
          >
            {{ pobierzBladWalidacyjny("rsiL") }}
          </div>
        </div>
      </form>
      <div class="float-child-right-mobile"></div>
    </div>

    <div
      class="box__mobile box__mobile--table mb-3"
      *ngIf="czySaWynikiNaLiscie(rezultaty)"
    >
      Licznik: {{ licznik }}, Ostatnia data modyfikacji:
      {{ ostatniaDataModyfikacji }}
      <div class="fixed_table_container">
        <table class="table">
          <thead>
            <tr>
              <th>Wynik</th>
              <th>skuteczność %</th>
              <th>TPS</th>
              <th>SLS</th>
              <th>TPL</th>
              <th>SLL</th>
              <th>macdSL</th>
              <th>macdLS</th>
              <th>macdSS</th>
              <th>Pozycje stratne</th>
              <th>Pozycje zyskowne</th>
              <th>Stracono</th>
              <th>Zarobiono</th>
            </tr>
          </thead>
          <tbody class="fz12" *ngIf="czySaWynikiNaLiscie(rezultaty)">
            <tr *ngFor="let rezultat of rezultaty" class="pointer project-row">
              <td>{{ rezultat.wynik | currency : "PLN" }}</td>
              <td>{{ rezultat.skutecznosc | number : "1.1-2" }}</td>
              <td>{{ rezultat.procentowyZyskSprzedazyShorta }}</td>
              <td>{{ rezultat.stopLossProcentShort }}</td>
              <td>{{ rezultat.procentowyZyskSprzedazyLonga }}</td>
              <td>{{ rezultat.stopLossProcentLong }}</td>
              <td>{{ rezultat.macdSL }}</td>
              <td>{{ rezultat.macdLS }}</td>
              <td>{{ rezultat.macdSS }}</td>
              <td>{{ rezultat.pozycjeStratne }}</td>
              <td>{{ rezultat.pozycjeZyskowne }}</td>
              <td>{{ rezultat.stracono | currency : "PLN" }}</td>
              <td>{{ rezultat.zarobiono | currency : "PLN" }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
<div class="bootom__buttons px-4">
  <div
    class="container--mw600 flex-column flex-sm-row d-flex justify-content-between align-items-center"
  >
    <button class="btn__link order-1 order-sm-0" (click)="zastopuj()">
      Przerwij szukanie
    </button>
    <button class="btn__blue order-0 order-sm-1" (click)="szukaj()">
      Szukaj
    </button>
  </div>
</div>
