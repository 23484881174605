import { Component, Input, OnInit } from '@angular/core';
import {
  KierunekSortowania,
  Sortowanie,
} from 'src/app/modele/wspolne/sortowanie';
import { Stronicowanie } from 'src/app/modele/wspolne/stronicowanie';

@Component({
  selector: 'app-tabela',
  templateUrl: './tabela.component.html',
  styleUrls: ['./tabela.component.css'],
})
export class TabelaComponent implements OnInit {
  // @Input() kolumnyFiltrowania: Array<KolumnaFiltrowania> = new Array();
  // @Input() rekordy: Array<KolumnaFiltrowania> = new Array();
  @Input() stronicowanie: Stronicowanie;
  @Input() sortowanie: Sortowanie;

  calkowitaLiczbaElementow: BigInteger;
  rozmiarTabeli: number = 25;
  czyJestWiecejRekordow: boolean = true;
  czyPokazacMenuSortowanie: boolean;
  kolumnyTabeli: Array<string> = new Array();
  czyPokazanoWyszukiwarkeMobile: boolean;
  czyPokazanoPodmenu: boolean;

  constructor() {}

  ngOnInit(): void {}

  zmienRozmiarTabeli() {
    this.stronicowanie.iloscRekordow = this.rozmiarTabeli;
    // this.pobierzDaneDoEkranu(true);
  }

  // pobierzNumerRekorduPoczatek() {
  //   if (this.stronicowanie == undefined) {
  //     return 1;
  //   }
  //   return this.stronicowanie.rekordPoczatkowy + 1;
  // }
  // pobierzNumerRekorduKoniec() {
  //   if (this.stronicowanie == undefined) {
  //     return 1;
  //   }
  //   return this.stronicowanie.rekordPoczatkowy + this.rekordy.length;
  // }

  // zmienStrone(oIle: number) {
  //   this.stronicowanie.strona += oIle;
  //   this.stronicowanie.rekordPoczatkowy =
  //     (this.stronicowanie.strona - 1) * this.stronicowanie.iloscRekordow;
  //   //this.pobierzDaneDoEkranu(true);
  // }

  // pobierzKierunekSortowania(kolumna: string) {
  //   var kierunki = this.sortowanie.listaSortowania;

  //   var kierunek = kierunki.find((m) => m.nazwaKolumny == kolumna);

  //   if (kierunek == undefined) {
  //     return '';
  //   }

  //   if (kierunek.kierunekSortowania == KierunekSortowania.asc) {
  //     return 'up';
  //   }
  //   if (kierunek.kierunekSortowania == KierunekSortowania.desc) {
  //     return 'down';
  //   }
  // }

  // zmienKierunekSortowania(kolumna: string) {
  //   this.czyPokazacMenuSortowanie = false;
  //   var kierunki = this.sortowanie.listaSortowania;

  //   var kierunek = kierunki.find((m) => m.nazwaKolumny == kolumna);

  //   if (kierunek == undefined) {
  //     this.sortowanie = new Sortowanie(kolumna, KierunekSortowania.asc);
  //     //this.pobierzDaneDoEkranu(true);
  //     return;
  //   }

  //   if (kierunek.kierunekSortowania == KierunekSortowania.asc) {
  //     this.sortowanie = new Sortowanie(kolumna, KierunekSortowania.desc);
  //     //this.pobierzDaneDoEkranu(true);
  //     return;
  //   }
  //   if (kierunek.kierunekSortowania == KierunekSortowania.desc) {
  //     this.sortowanie = new Sortowanie('id', KierunekSortowania.asc);
  //     // this.pobierzDaneDoEkranu(true);
  //     return;
  //   }
  // }

  // wyczyscFiltry() {
  //   this.kolumnyFiltrowania.forEach((k) => {
  //     k.wartoscSzukaj = '';
  //   });
  //   //this.pobierzDaneDoEkranu(true);
  // }

  // filtrujPoTekscie(event: any, kolumna: string) {
  //   this.stronicowanie.rekordPoczatkowy = 0;
  //   this.stronicowanie.strona = 1;
  //   var wartosc = event.target.value.toString();

  //   this.ustawWartoscFiltrowania(kolumna, wartosc);
  //   // this.subsription?.unsubscribe();
  //   // this.pobierzDaneDoEkranu(false);
  // }

  // ustawWartoscFiltrowania(kolumna: string, wartosc: string) {
  //   var kolumnaFiltrowania = this.kolumnyFiltrowania.find(
  //     (m) => m.kolumna == kolumna
  //   );

  //   if (kolumnaFiltrowania == undefined) {
  //     kolumnaFiltrowania = new KolumnaFiltrowania();
  //     kolumnaFiltrowania.kolumna = kolumna;
  //     kolumnaFiltrowania.wartoscSzukaj = wartosc;
  //     this.kolumnyFiltrowania.push(kolumnaFiltrowania);
  //   } else {
  //     this.kolumnyFiltrowania.forEach((k) => {
  //       if (k.kolumna == kolumna) {
  //         k.wartoscSzukaj = wartosc;
  //       }
  //     });
  //   }
  // }

  // czyPokazacPrzyciskCzyszczeniaFiltru(kolumna: string) {
  //   var kolumnaFiltrowania = this.kolumnyFiltrowania.find(
  //     (m) => m.kolumna == kolumna
  //   );
  //   return kolumnaFiltrowania != undefined && kolumnaFiltrowania.wartoscSzukaj;
  // }
  // wyczyscFiltrKolumny(kolumna: string) {
  //   this.kolumnyFiltrowania.forEach((k) => {
  //     if (k.kolumna == kolumna) {
  //       k.wartoscSzukaj = '';
  //     }
  //   });
  //   //this.pobierzDaneDoEkranu(false);
  // }

  // przygotujFiltry() {
  //   this.kolumnyFiltrowania = new Array();
  //   this.kolumnyTabeli.forEach((kolumna) => {
  //     if (kolumna == 'id') {
  //       return;
  //     }
  //     var kolumnaFiltrowania = new KolumnaFiltrowania();
  //     kolumnaFiltrowania.kolumna = kolumna;
  //     kolumnaFiltrowania.wartoscSzukaj = '';
  //     this.kolumnyFiltrowania.push(kolumnaFiltrowania);
  //   });
  // }

  // czyPokazacWyczyscFiltry() {
  //   return this.kolumnyFiltrowania.filter((m) => m.wartoscSzukaj).length > 0;
  // }
  // pokazWyszukiwarkeMobile(czyPokazac: boolean) {
  //   this.czyPokazanoWyszukiwarkeMobile = czyPokazac;
  //   this.czyPokazanoPodmenu = false;
  // }

  // pokazPodmenu() {
  //   this.czyPokazanoPodmenu = !this.czyPokazanoPodmenu;
  //   this.czyPokazanoWyszukiwarkeMobile = false;
  // }
}
