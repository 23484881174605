import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { BazowyComponent } from 'src/app/komponenty/bazowy.component';
import { DodajKonfiguracjeFuturesModel } from 'src/app/modele/futures/dodajKonfiguracjeFuturesModel';
import { PobierzKonfiguracjeGieldRezultat } from 'src/app/modele/futures/pobierzKonfiguracjeGieldRezultat';
import { WejdzWPozycjeModel } from 'src/app/modele/futures/wejdzWPozycjeModel';
import { FuturesService } from 'src/app/serwisy/futures/futures.service';
import { AuthService } from 'src/app/serwisy/ogolne/auth.service';
import { LoaderService } from 'src/app/serwisy/ogolne/loader.service';

@Component({
  selector: 'app-wejdz-w-pozycje',
  templateUrl: './wejdz-w-pozycje.component.html',
  styleUrls: ['./wejdz-w-pozycje.component.css'],
})
export class WejdzWPozycjeComponent extends BazowyComponent implements OnInit {
  id: string;
  model: WejdzWPozycjeModel = new WejdzWPozycjeModel();
  dostepneRodzaje: Array<{ value: string; key: string }> = [
    { key: 'Long', value: 'Long' },
    { key: 'Short', value: 'Short' },
  ];

  constructor(
    public router: Router,
    loaderSerwis: LoaderService,
    komunikatSerwis: ToastrService,
    public authService: AuthService,
    public futuresSerwis: FuturesService,
    modalService: NgbModal,
    public activeModal: NgbActiveModal
  ) {
    super(authService, modalService, loaderSerwis, komunikatSerwis, router);
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.model.idKonfiguracji = this.id;
    });
  }

  zapisz() {
    this.futuresSerwis.wejdzWPozycje(this.model).subscribe((rezultat) => {
      if (this.czyRezultatOk(rezultat)) {
        this.wyswietlKomunikat(
          'Zapisano poprawnie - pozycja jest w trakcie otwierania'
        );
        this.activeModal.close(true);
        return;
      }
      if (this.bladModelu(rezultat)) {
        this.bledyWalidacji = rezultat.bledyModelu;
        return;
      }

      this.wyswietlKomunikat(rezultat.wiadomosc, true);
      return;
    });
  }
}
