import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { RejestrFiltrowanyRequest } from 'src/app/modele/wspolne/rejestrFiltrowanyRequest';
import {
  Sortowanie,
  KierunekSortowania,
} from 'src/app/modele/wspolne/sortowanie';
import { Stronicowanie } from 'src/app/modele/wspolne/stronicowanie';
import { AuthService } from 'src/app/serwisy/ogolne/auth.service';
import { LoaderService } from 'src/app/serwisy/ogolne/loader.service';
import { BazowyComponent } from '../../bazowy.component';
import { FuturesService } from 'src/app/serwisy/futures/futures.service';
import { PobierzKonfiguracjeGieldRezultat } from 'src/app/modele/futures/pobierzKonfiguracjeGieldRezultat';
import { DodajKonfiguracjeGieldComponent } from '../modale/dodaj-konfiguracje-gield/dodaj-konfiguracje-gield.component';
import { ModalOpcje } from 'src/app/modele/wspolne/modal-opcje.ogolne';
import { EdytujKonfiguracjeGieldComponent } from '../modale/edytuj-konfiguracje-gield/edytuj-konfiguracje-gield.component';
import { PobierzKonfiguracjeGieldFiltry } from 'src/app/modele/futures/pobierzKonfiguracjeGieldFiltry';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-konfiguracje-gield',
  templateUrl: './konfiguracje-gield.component.html',
  styleUrls: ['./konfiguracje-gield.component.css'],
})
export class KonfiguracjeGieldComponent
  extends BazowyComponent
  implements OnInit
{
  czyPokazanoWyszukiwarkeMobile: boolean;
  subsription: Subscription | undefined;
  czyZaladowanoDane: boolean;
  calkowitaLiczbaElementow: BigInteger;
  rozmiarTabeli: number = 25;
  czyJestWiecejRekordow: boolean = true;
  gieldy: Array<PobierzKonfiguracjeGieldRezultat> = new Array();
  czyPokazacMenuSortowanie: boolean;
  wyszukiwanie: RejestrFiltrowanyRequest<PobierzKonfiguracjeGieldFiltry> =
    new RejestrFiltrowanyRequest<PobierzKonfiguracjeGieldFiltry>();

  constructor(
    public router: Router,
    modalService: NgbModal,
    loaderSerwis: LoaderService,
    komunikatSerwis: ToastrService,
    public authService: AuthService,
    public futuresSerwis: FuturesService
  ) {
    super(authService, modalService, loaderSerwis, komunikatSerwis, router);
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.wyszukiwanie.filtry = new PobierzKonfiguracjeGieldFiltry();
      this.wyszukiwanie.sortowanie = new Sortowanie(
        'czyjeKonto',
        KierunekSortowania.asc
      );
      this.wyszukiwanie.stronicowanie = new Stronicowanie(false);
      this.wyszukiwanie.stronicowanie.iloscRekordow = this.rozmiarTabeli;
      this.wyszukiwanie.stronicowanie.rekordPoczatkowy = 0;
      this.wyszukiwanie.stronicowanie.strona = 1;

      this.pobierzDaneDoEkranu(true);
    });
  }

  pobierzDaneDoEkranu(zLadowaczem: boolean) {
    this.subsription = this.futuresSerwis
      .pobierzKonfiguracjeGield(this.wyszukiwanie, zLadowaczem)
      .subscribe((rezultat) => {
        if (this.czyRezultatOk(rezultat)) {
          this.gieldy = rezultat.listaElementow;
          this.czyZaladowanoDane = true;
          this.calkowitaLiczbaElementow = rezultat.calkowitaIloscRekordow;
          this.czyJestWiecejRekordow = rezultat.czyJestWiecejRekordow;
          return;
        }

        this.wyswietlKomunikat(rezultat.wiadomosc, true);
        return;
      });
  }

  dodajKonfiguracje() {
    this.modalReference = this.modalService.open(
      DodajKonfiguracjeGieldComponent,
      new ModalOpcje(true, 'static')
    );
    this.modalReference.result.then(
      (result) => {
        if (result) {
          this.pobierzDaneDoEkranu(true);
        }
      },
      (onRejected) => {}
    );
  }
  edytuj(id: string) {
    this.modalReference = this.modalService.open(
      EdytujKonfiguracjeGieldComponent,
      new ModalOpcje(true, 'static')
    );
    this.modalReference.componentInstance.model = {
      ...this.gieldy.find((m) => m.id == id),
    };
    this.modalReference.result.then(
      (result) => {
        if (result) {
          this.pobierzDaneDoEkranu(true);
        }
      },
      (onRejected) => {}
    );
  }

  zmienRozmiarTabeli() {
    this.wyszukiwanie.stronicowanie.iloscRekordow = this.rozmiarTabeli;
    this.pobierzDaneDoEkranu(true);
  }

  pobierzNumerRekorduPoczatek() {
    if (this.wyszukiwanie.stronicowanie == undefined) {
      return 1;
    }
    return this.wyszukiwanie.stronicowanie.rekordPoczatkowy + 1;
  }
  pobierzNumerRekorduKoniec() {
    if (this.wyszukiwanie.stronicowanie == undefined) {
      return 1;
    }
    return (
      this.wyszukiwanie.stronicowanie.rekordPoczatkowy + this.gieldy.length
    );
  }

  zmienStrone(oIle: number) {
    this.wyszukiwanie.stronicowanie.strona += oIle;
    this.wyszukiwanie.stronicowanie.rekordPoczatkowy =
      (this.wyszukiwanie.stronicowanie.strona - 1) *
      this.wyszukiwanie.stronicowanie.iloscRekordow;
    this.pobierzDaneDoEkranu(true);
  }

  pokazMenuSortowania() {
    this.czyPokazacMenuSortowanie = !this.czyPokazacMenuSortowanie;
  }

  pobierzKierunekSortowania(kolumna: string) {
    var kierunki = this.wyszukiwanie.sortowanie.listaSortowania;

    var kierunek = kierunki.find((m) => m.nazwaKolumny == kolumna);

    if (kierunek == undefined) {
      return '';
    }

    if (kierunek.kierunekSortowania == KierunekSortowania.asc) {
      return 'up';
    }
    if (kierunek.kierunekSortowania == KierunekSortowania.desc) {
      return 'down';
    }
  }

  zmienKierunekSortowania(kolumna: string) {
    this.czyPokazacMenuSortowanie = false;
    var kierunki = this.wyszukiwanie.sortowanie.listaSortowania;

    var kierunek = kierunki.find((m) => m.nazwaKolumny == kolumna);

    if (kierunek == undefined) {
      this.wyszukiwanie.sortowanie = new Sortowanie(
        kolumna,
        KierunekSortowania.asc
      );
      this.pobierzDaneDoEkranu(true);
      return;
    }

    if (kierunek.kierunekSortowania == KierunekSortowania.asc) {
      this.wyszukiwanie.sortowanie = new Sortowanie(
        kolumna,
        KierunekSortowania.desc
      );
      this.pobierzDaneDoEkranu(true);
      return;
    }
    if (kierunek.kierunekSortowania == KierunekSortowania.desc) {
      this.wyszukiwanie.sortowanie = new Sortowanie(
        'czyjeKonto',
        KierunekSortowania.asc
      );
      this.pobierzDaneDoEkranu(true);
      return;
    }
  }

  pobierzKolumneSortowania() {
    var kierunki = this.wyszukiwanie.sortowanie.listaSortowania;

    switch (kierunki[0].nazwaKolumny) {
      case 'gielda':
        return 'Giełda';
      case 'rodzajKonta':
        return 'Rodzaj konta';
      case 'czyjeKonto':
        return 'Czyje konto';
      case 'czyAktywna':
        return 'Czy aktywna';
      case 'adres':
        return 'Adres API';
      case 'budzet':
        return 'Budżet';
    }
  }

  pobierzKierunekSortowaniaMobile() {
    var kierunki = this.wyszukiwanie.sortowanie.listaSortowania;

    if (kierunki[0].kierunekSortowania == KierunekSortowania.asc) {
      return 'up';
    }
    if (kierunki[0].kierunekSortowania == KierunekSortowania.desc) {
      return '';
    }
  }

  zmienKierunekSortowaniaMobile() {
    var kierunki = this.wyszukiwanie.sortowanie.listaSortowania;

    var kierunek = kierunki[0];

    if (kierunek.kierunekSortowania == KierunekSortowania.asc) {
      this.wyszukiwanie.sortowanie = new Sortowanie(
        kierunek.nazwaKolumny,
        KierunekSortowania.desc
      );
      this.pobierzDaneDoEkranu(true);
      return;
    }
    if (kierunek.kierunekSortowania == KierunekSortowania.desc) {
      this.wyszukiwanie.sortowanie = new Sortowanie(
        kierunek.nazwaKolumny,
        KierunekSortowania.asc
      );
      this.pobierzDaneDoEkranu(true);
      return;
    }
  }
  wyczyscSortowanieMobile() {
    this.wyszukiwanie.sortowanie = new Sortowanie('Id', KierunekSortowania.asc);
    this.pobierzDaneDoEkranu(true);
  }

  filtrujPoTekscie(wartosc: string) {
    this.subsription?.unsubscribe();
    this.wyszukiwanie.stronicowanie.rekordPoczatkowy = 0;
    this.wyszukiwanie.stronicowanie.strona = 1;
    this.pobierzDaneDoEkranu(false);
  }

  czyPokazacWyczyscFiltry() {
    return (
      this.wyszukiwanie.filtry.adres ||
      this.wyszukiwanie.filtry.budzet ||
      this.wyszukiwanie.filtry.czyAktywna ||
      this.wyszukiwanie.filtry.czyjeKonto ||
      this.wyszukiwanie.filtry.gielda ||
      this.wyszukiwanie.filtry.rodzajKonta
    );
  }

  wyczyscFiltry() {
    this.wyszukiwanie.filtry.adres = '';
    this.wyszukiwanie.filtry.budzet = '';
    this.wyszukiwanie.filtry.czyAktywna = '';
    this.wyszukiwanie.filtry.czyjeKonto = '';
    this.wyszukiwanie.filtry.gielda = '';
    this.wyszukiwanie.filtry.rodzajKonta = '';

    this.pobierzDaneDoEkranu(true);
  }

  pokazWyszukiwarkeMobile(czyPokazac: boolean) {
    this.czyPokazanoWyszukiwarkeMobile = czyPokazac;
  }
}
