export class TestujKonfiguracjeModel {
  dataOd: string;
  dataDo?: string;
  krypto: string;
  maxGodzinPozycji: number;
  sls: number;
  tps: number;
  sll: number;
  tpl: number;
  dzwignie: number[];
  procentKapitalu: number;
  macdSL: number;
  macdLS: number;
  rsiL: number;
  macdSS: number;
  maxWielkoscPozycji: number;
  odkladanyProcentNaKoniecMiesiaca?: number;
  odJakiejKwotyOdkladac?: number;
  gieldaId: string;
  budzet: number;
}
