import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { arrow } from '@popperjs/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription, timer } from 'rxjs';
import { BazowyComponent } from 'src/app/komponenty/bazowy.component';
import { PobierzKonfiguracjeGieldFiltry } from 'src/app/modele/futures/pobierzKonfiguracjeGieldFiltry';
import { PobierzKonfiguracjeGieldRezultat } from 'src/app/modele/futures/pobierzKonfiguracjeGieldRezultat';
import { PobierzKonfiguracjeDoSzukaniaNajlepszejKonfiguracjiRezultat } from 'src/app/modele/testy/pobierzKonfiguracjeDoSzukaniaNajlepszejKonfiguracjiRezultat';
import { PobierzWynikiSzukaniaNajlepszejKonfiguracjiModel } from 'src/app/modele/testy/pobierzWynikiSzukaniaNajlepszejKonfiguracjiModel';
import { Rezultat } from 'src/app/modele/testy/pobierzWynikiSzukaniaNajlepszejKonfiguracjiRezultat';
import { SzukanieNajlepszejKonfiguracjiModel } from 'src/app/modele/testy/szukanieNajlepszejKonfiguracjiModel';
import { RejestrFiltrowanyRequest } from 'src/app/modele/wspolne/rejestrFiltrowanyRequest';
import {
  KierunekSortowania,
  Sortowanie,
} from 'src/app/modele/wspolne/sortowanie';
import { Stronicowanie } from 'src/app/modele/wspolne/stronicowanie';
import { FuturesService } from 'src/app/serwisy/futures/futures.service';
import { AuthService } from 'src/app/serwisy/ogolne/auth.service';
import { LoaderService } from 'src/app/serwisy/ogolne/loader.service';

@Component({
  selector: 'app-szukanie-najlepszej-konfiguracji',
  templateUrl: './szukanie-najlepszej-konfiguracji.component.html',
  styleUrls: ['./szukanie-najlepszej-konfiguracji.component.css'],
})
export class SzukanieNajlepszejKonfiguracjiComponent
  extends BazowyComponent
  implements OnInit, OnDestroy
{
  licznikKombinacji: number = 0;
  licznik: number = 0;
  ostatniaDataModyfikacji: string;
  rezultaty: Array<Rezultat> = new Array();
  czasNaWykonanie: number = 0;
  model: SzukanieNajlepszejKonfiguracjiModel =
    new SzukanieNajlepszejKonfiguracjiModel();
  gieldy: Array<PobierzKonfiguracjeGieldRezultat> = [];
  dostepneKrypto: Array<{ value: string; key: string }> = [
    { key: 'ETHUSDT', value: 'ETHUSDT' },
    { key: 'BTCUSDT', value: 'BTCUSDT' },
    { key: 'ADAUSDT', value: 'ADAUSDT' },
    // { key: 'BNBUSDT', value: 'BNBUSDT' },
  ];
  subscription: Subscription;
  konfiguracje: Array<PobierzKonfiguracjeDoSzukaniaNajlepszejKonfiguracjiRezultat> =
    new Array();

  constructor(
    public router: Router,
    modalService: NgbModal,
    loaderSerwis: LoaderService,
    komunikatSerwis: ToastrService,
    public authService: AuthService,
    public futuresSerwis: FuturesService
  ) {
    super(authService, modalService, loaderSerwis, komunikatSerwis, router);
  }
  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.pobierzGieldy();
      this.model.dataOd = '2022-05-01';
      this.model.dataDo = '2023-01-01';
      this.model.gieldaId = 'epyufp2z';
      this.model.krypto = 'ADAUSDT';
      this.pobierzKonfiguracje();
    });
  }

  pobierzGieldy() {
    var model = new RejestrFiltrowanyRequest<PobierzKonfiguracjeGieldFiltry>();
    model.stronicowanie = new Stronicowanie(true);
    model.sortowanie = new Sortowanie('czyjeKonto', KierunekSortowania.asc);
    this.futuresSerwis
      .pobierzKonfiguracjeGield(model, true)
      .subscribe((rezultat) => {
        if (this.czyRezultatOk(rezultat)) {
          this.gieldy = rezultat.listaElementow;
          return;
        }

        this.wyswietlKomunikat(rezultat.wiadomosc, true);
        return;
      });
  }

  pobierzKonfiguracje() {
    this.futuresSerwis
      .pobierzKonfiguracjeDoSzukaniaNajlepszejKonfiguracji(true)
      .subscribe((rezultat) => {
        if (this.czyRezultatOk(rezultat)) {
          this.konfiguracje = rezultat.listaElementow;
          this.wybierzKrypto();
          return;
        }

        this.wyswietlKomunikat(rezultat.wiadomosc, true);
        return;
      });
  }

  wybierzKrypto() {
    this.ustawWartosciStartowe(this.model.krypto);
    this.subscription?.unsubscribe();
    this.subscription = timer(1, 10000).subscribe((x) => {
      this.przeliczLiczbeKombinacji();
      this.pobierzWyniki();
    });
  }

  ustawWartosciStartowe(krypto) {
    this.model.dzwignia = 17;
    this.model.budzet = 220;
    this.model.procentKapitalu = 80;
    this.model.maxGodzinPozycji = 48;
    this.model.odJakiejKwotyOdkladac = 5000000;
    this.model.odkladanyProcentNaKoniecMiesiaca = 10;
    this.model.krypto = krypto;

    var konf = this.konfiguracje.find((m) => m.krypto == krypto);
    if (konf) {
      this.model.tpsOd = konf.tpsOd;
      this.model.tpsDo = konf.tpsDo;
      this.model.tpsKrok = konf.tpsKrok;

      this.model.slsOd = konf.slsOd;
      this.model.slsDo = konf.slsDo;
      this.model.slsKrok = konf.slsKrok;

      this.model.tplOd = konf.tplOd;
      this.model.tplDo = konf.tplDo;
      this.model.tplKrok = konf.tplKrok;

      this.model.sllOd = konf.sllOd;
      this.model.sllDo = konf.sllDo;
      this.model.sllKrok = konf.sllKrok;

      this.model.macdSLOd = konf.macdSLOd;
      this.model.macdSLDo = konf.macdSLDo;
      this.model.macdSLKrok = konf.macdSLKrok;

      this.model.macdLSOd = konf.macdLSOd;
      this.model.macdLSDo = konf.macdLSDo;
      this.model.macdLSKrok = konf.macdLSKrok;

      this.model.macdSSOd = konf.macdSSOd;
      this.model.macdSSDo = konf.macdSSDo;
      this.model.macdSSKrok = konf.macdSSKrok;
      return;
    }
    if (krypto == 'ETHUSDT') {
      this.model.tpsOd = 1.86;
      this.model.tpsDo = 2.1;
      this.model.tpsKrok = 0.02;

      this.model.slsOd = 1.62;
      this.model.slsDo = 2.1;
      this.model.slsKrok = 0.02;

      this.model.tplOd = 1.6;
      this.model.tplDo = 2.1;
      this.model.tplKrok = 0.02;

      this.model.sllOd = 1.52;
      this.model.sllDo = 2.1;
      this.model.sllKrok = 0.02;

      this.model.macdSLOd = -0.0004;
      this.model.macdSLDo = -0.0005;
      this.model.macdSLKrok = 0.0001;

      this.model.macdLSOd = 0.00005;
      this.model.macdLSDo = 0.00006;
      this.model.macdLSKrok = 0.00001;

      this.model.macdSSOd = 0.0015;
      this.model.macdSSDo = 0.0017;
      this.model.macdSSKrok = 0.0001;

      this.model.rsiL = 42.6;
      this.model.maxWielkoscPozycji = 500000;
    }
    if (krypto == 'BTCUSDT') {
      this.model.tpsOd = 1.86;
      this.model.tpsDo = 2.1;
      this.model.tpsKrok = 0.02;

      this.model.slsOd = 1.62;
      this.model.slsDo = 2.1;
      this.model.slsKrok = 0.02;

      this.model.tplOd = 1.6;
      this.model.tplDo = 2.1;
      this.model.tplKrok = 0.02;

      this.model.sllOd = 1.52;
      this.model.sllDo = 2.1;
      this.model.sllKrok = 0.02;

      this.model.macdSLOd = -0.0004;
      this.model.macdSLDo = -0.0005;
      this.model.macdSLKrok = 0.0001;

      this.model.macdLSOd = 0.00005;
      this.model.macdLSDo = 0.00006;
      this.model.macdLSKrok = 0.00001;

      this.model.macdSSOd = 0.0015;
      this.model.macdSSDo = 0.0017;
      this.model.macdSSKrok = 0.0001;

      this.model.rsiL = 41.9;
      this.model.maxWielkoscPozycji = 3000000;
    }
    if (krypto == 'ADAUSDT') {
      this.model.tpsOd = 1.5;
      this.model.tpsDo = 2.2;
      this.model.tpsKrok = 0.04;

      this.model.slsOd = 1.4;
      this.model.slsDo = 2.1;
      this.model.slsKrok = 0.04;

      this.model.tplOd = 1.5;
      this.model.tplDo = 2.2;
      this.model.tplKrok = 0.04;

      this.model.sllOd = 1.4;
      this.model.sllDo = 2.1;
      this.model.sllKrok = 0.04;

      this.model.macdSLOd = -0.0013;
      this.model.macdSLDo = -0.003;
      this.model.macdSLKrok = 0.0003;

      this.model.macdLSOd = 0.0002;
      this.model.macdLSDo = 0.0006;
      this.model.macdLSKrok = 0.0002;

      this.model.macdSSOd = 0.0003;
      this.model.macdSSDo = 0.001;
      this.model.macdSSKrok = 0.0001;

      this.model.rsiL = 42;
      this.model.maxWielkoscPozycji = 50000;
    }
    this.przeliczLiczbeKombinacji();
  }

  szukaj() {
    this.subscription?.unsubscribe();
    this.futuresSerwis
      .szukajNajlepszychKonfiguracji(this.model)
      .subscribe((rezultat) => {
        if (this.czyRezultatOk(rezultat)) {
          this.wyswietlKomunikat(
            'Szukanie najlepszych konfiguracji zostało rozpoczęte w tle'
          );
          this.subscription = timer(1, 10000).subscribe((x) => {
            this.pobierzWyniki();
          });
          return;
        }

        this.wyswietlKomunikat(rezultat.wiadomosc, true);
        return;
      });
  }

  pobierzWyniki() {
    var model = new PobierzWynikiSzukaniaNajlepszejKonfiguracjiModel();
    model.krypto = this.model.krypto;
    model.gieldaId = this.model.gieldaId;
    this.futuresSerwis
      .pobierzWynikiSzukaniaNajlepszejKonfiguracji(model)
      .subscribe((rezultat) => {
        if (this.czyRezultatOk(rezultat)) {
          this.rezultaty = rezultat.element.rezultaty;
          this.licznik = rezultat.element.licznik;
          this.ostatniaDataModyfikacji =
            rezultat.element.ostatniaDataModyfikacji;
          return;
        }

        this.wyswietlKomunikat(rezultat.wiadomosc, true);
        return;
      });
  }

  zastopuj() {
    this.futuresSerwis
      .zastopujSzukaniaNajlepszejKonfiguracji()
      .subscribe((rezultat) => {
        if (this.czyRezultatOk(rezultat)) {
          this.wyswietlKomunikat('Szukanie w trakcie zatrzymywania');
          return;
        }

        this.wyswietlKomunikat(rezultat.wiadomosc, true);
        return;
      });
  }

  //todo dziala to tylko na zaladowanie strony
  przeliczLiczbeKombinacji() {
    var licznik = 0;

    for (
      var tps = this.model.tpsOd;
      tps <= this.model.tpsDo;
      tps += this.model.tpsKrok
    ) {
      for (
        var sls = this.model.slsOd;
        sls <= this.model.slsDo;
        sls += this.model.slsKrok
      ) {
        for (
          var tpl = this.model.tplOd;
          tpl <= this.model.tplDo;
          tpl += this.model.tplKrok
        ) {
          for (
            var sll = this.model.sllOd;
            sll <= this.model.sllDo;
            sll += this.model.sllKrok
          ) {
            if (sls >= tps || sll >= tpl) {
              continue;
            }

            // macdSL
            for (
              var macdSL = this.model.macdSLOd;
              macdSL >= this.model.macdSLDo;
              macdSL -= this.model.macdSLKrok
            ) {
              // macdLS
              for (
                var macdLS = this.model.macdLSOd;
                macdLS <= this.model.macdLSDo;
                macdLS += this.model.macdLSKrok
              ) {
                // macdSS
                for (
                  var macdSS = this.model.macdSSOd;
                  macdSS <= this.model.macdSSDo;
                  macdSS += this.model.macdSSKrok
                ) {
                  licznik++;
                }
              }
            }
          }
        }
      }
    }
    this.licznikKombinacji = licznik;
    this.czasNaWykonanie = licznik / 3500 / 60;
  }
}
