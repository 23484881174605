<div *ngIf="czyZaladowanoDane">
  <div class="container--main d-none d-md-block">
    <div class="ft__sort text-center pt-2">Konfiguracje futures</div>
  </div>

  <div class="container-fluid d-none d-md-block pt-2">
    <div class="box__scroll py-3">
      <div
        class="box__scroll--top d-flex justify-content-between align-items-center mb-3"
      >
        <div class="input_container">
          <button
            class="btn__clear--filter"
            (click)="wyczyscFiltry()"
            *ngIf="czyPokazacWyczyscFiltry()"
          >
            Wyczyść filtry
          </button>
        </div>
      </div>
      <div class="tableFixHead tableFixHead--3">
        <table class="table">
          <thead>
            <tr>
              <th>
                Giełda
                <button
                  class="btn__input--sort--bottom--gray"
                  [ngClass]="pobierzKierunekSortowania('gielda')"
                  (click)="zmienKierunekSortowania('gielda')"
                ></button>
                <div class="input_container input_container--mini">
                  <input
                    class="pr-4"
                    type="text"
                    placeholder="Szukaj"
                    [(ngModel)]="wyszukiwanie.filtry.gielda"
                    (keyup)="filtrujPoTekscie(this.wyszukiwanie.filtry.gielda)"
                  />
                  <button
                    class="btn__input--search"
                    *ngIf="!wyszukiwanie.filtry.gielda"
                  ></button>
                  <button
                    class="btn__input--clear"
                    *ngIf="wyszukiwanie.filtry.gielda"
                    (click)="
                      this.wyszukiwanie.filtry.gielda = '';
                      pobierzDaneDoEkranu(false)
                    "
                  ></button>
                </div>
              </th>
              <th>
                Rodzaj konta
                <button
                  class="btn__input--sort--bottom--gray"
                  [ngClass]="pobierzKierunekSortowania('rodzajKonta')"
                  (click)="zmienKierunekSortowania('rodzajKonta')"
                ></button>
                <div class="input_container input_container--mini">
                  <input
                    class="pr-4"
                    type="text"
                    placeholder="Szukaj"
                    [(ngModel)]="wyszukiwanie.filtry.rodzajKonta"
                    (keyup)="
                      filtrujPoTekscie(this.wyszukiwanie.filtry.rodzajKonta)
                    "
                  />
                  <button
                    class="btn__input--search"
                    *ngIf="!wyszukiwanie.filtry.rodzajKonta"
                  ></button>
                  <button
                    class="btn__input--clear"
                    *ngIf="wyszukiwanie.filtry.rodzajKonta"
                    (click)="
                      this.wyszukiwanie.filtry.rodzajKonta = '';
                      pobierzDaneDoEkranu(false)
                    "
                  ></button>
                </div>
              </th>

              <th>
                Czyje konto
                <button
                  class="btn__input--sort--bottom--gray"
                  [ngClass]="pobierzKierunekSortowania('czyjeKonto')"
                  (click)="zmienKierunekSortowania('czyjeKonto')"
                ></button>
                <div class="input_container input_container--mini">
                  <input
                    class="pr-4"
                    type="text"
                    placeholder="Szukaj"
                    [(ngModel)]="wyszukiwanie.filtry.czyjeKonto"
                    (keyup)="
                      filtrujPoTekscie(this.wyszukiwanie.filtry.czyjeKonto)
                    "
                  />
                  <button
                    class="btn__input--search"
                    *ngIf="!wyszukiwanie.filtry.czyjeKonto"
                  ></button>
                  <button
                    class="btn__input--clear"
                    *ngIf="wyszukiwanie.filtry.czyjeKonto"
                    (click)="
                      this.wyszukiwanie.filtry.czyjeKonto = '';
                      pobierzDaneDoEkranu(false)
                    "
                  ></button>
                </div>
              </th>
              <th>
                Aktywna
                <button
                  class="btn__input--sort--bottom--gray"
                  [ngClass]="pobierzKierunekSortowania('czyAktywna')"
                  (click)="zmienKierunekSortowania('czyAktywna')"
                ></button>
                <div class="input_container input_container--mini">
                  <input
                    class="pr-4"
                    type="text"
                    placeholder="Szukaj"
                    [(ngModel)]="wyszukiwanie.filtry.czyAktywna"
                    (keyup)="
                      filtrujPoTekscie(this.wyszukiwanie.filtry.czyAktywna)
                    "
                  />
                  <button
                    class="btn__input--search"
                    *ngIf="!wyszukiwanie.filtry.czyAktywna"
                  ></button>
                  <button
                    class="btn__input--clear"
                    *ngIf="wyszukiwanie.filtry.czyAktywna"
                    (click)="
                      this.wyszukiwanie.filtry.czyAktywna = '';
                      pobierzDaneDoEkranu(false)
                    "
                  ></button>
                </div>
              </th>
              <th>
                Krypto
                <button
                  class="btn__input--sort--bottom--gray"
                  [ngClass]="pobierzKierunekSortowania('paraKrypto')"
                  (click)="zmienKierunekSortowania('paraKrypto')"
                ></button>
                <div class="input_container input_container--mini">
                  <input
                    class="pr-4"
                    type="text"
                    placeholder="Szukaj"
                    [(ngModel)]="wyszukiwanie.filtry.paraKrypto"
                    (keyup)="
                      filtrujPoTekscie(this.wyszukiwanie.filtry.paraKrypto)
                    "
                  />
                  <button
                    class="btn__input--search"
                    *ngIf="!wyszukiwanie.filtry.paraKrypto"
                  ></button>
                  <button
                    class="btn__input--clear"
                    *ngIf="wyszukiwanie.filtry.paraKrypto"
                    (click)="
                      this.wyszukiwanie.filtry.paraKrypto = '';
                      pobierzDaneDoEkranu(false)
                    "
                  ></button>
                </div>
              </th>

              <th>
                Wersja bota
                <!-- <button
                  class="btn__input--sort--bottom--gray"
                  [ngClass]="pobierzKierunekSortowania('paraKrypto')"
                  (click)="zmienKierunekSortowania('paraKrypto')"
                ></button>
                <div class="input_container input_container--mini">
                  <input
                    class="pr-4"
                    type="text"
                    placeholder="Szukaj"
                    [(ngModel)]="wyszukiwanie.filtry.paraKrypto"
                    (keyup)="
                      filtrujPoTekscie(this.wyszukiwanie.filtry.paraKrypto)
                    "
                  />
                  <button
                    class="btn__input--search"
                    *ngIf="!wyszukiwanie.filtry.paraKrypto"
                  ></button>
                  <button
                    class="btn__input--clear"
                    *ngIf="wyszukiwanie.filtry.paraKrypto"
                    (click)="
                      this.wyszukiwanie.filtry.paraKrypto = '';
                      pobierzDaneDoEkranu(false)
                    "
                  ></button>
                </div> -->
              </th>

              <th>
                Max h pozycji
                <button
                  class="btn__input--sort--bottom--gray"
                  [ngClass]="pobierzKierunekSortowania('maxGodzinPozycji')"
                  (click)="zmienKierunekSortowania('maxGodzinPozycji')"
                ></button>
                <div class="input_container input_container--mini">
                  <input
                    class="pr-4"
                    type="text"
                    placeholder="Szukaj"
                    [(ngModel)]="wyszukiwanie.filtry.maxGodzinPozycji"
                    (keyup)="
                      filtrujPoTekscie(
                        this.wyszukiwanie.filtry.maxGodzinPozycji
                      )
                    "
                  />
                  <button
                    class="btn__input--search"
                    *ngIf="!wyszukiwanie.filtry.maxGodzinPozycji"
                  ></button>
                  <button
                    class="btn__input--clear"
                    *ngIf="wyszukiwanie.filtry.maxGodzinPozycji"
                    (click)="
                      this.wyszukiwanie.filtry.maxGodzinPozycji = '';
                      pobierzDaneDoEkranu(false)
                    "
                  ></button>
                </div>
              </th>
              <th>
                LTP
                <button
                  class="btn__input--sort--bottom--gray"
                  [ngClass]="pobierzKierunekSortowania('tpl')"
                  (click)="zmienKierunekSortowania('tpl')"
                ></button>
                <div class="input_container input_container--mini">
                  <input
                    class="pr-4"
                    type="text"
                    placeholder="Szukaj"
                    [(ngModel)]="wyszukiwanie.filtry.tpl"
                    (keyup)="filtrujPoTekscie(this.wyszukiwanie.filtry.tpl)"
                  />
                  <button
                    class="btn__input--search"
                    *ngIf="!wyszukiwanie.filtry.tpl"
                  ></button>
                  <button
                    class="btn__input--clear"
                    *ngIf="wyszukiwanie.filtry.tpl"
                    (click)="
                      this.wyszukiwanie.filtry.tpl = '';
                      pobierzDaneDoEkranu(false)
                    "
                  ></button>
                </div>
              </th>
              <th>
                LSL
                <button
                  class="btn__input--sort--bottom--gray"
                  [ngClass]="pobierzKierunekSortowania('sll')"
                  (click)="zmienKierunekSortowania('sll')"
                ></button>
                <div class="input_container input_container--mini">
                  <input
                    class="pr-4"
                    type="text"
                    placeholder="Szukaj"
                    [(ngModel)]="wyszukiwanie.filtry.sll"
                    (keyup)="filtrujPoTekscie(this.wyszukiwanie.filtry.sll)"
                  />
                  <button
                    class="btn__input--search"
                    *ngIf="!wyszukiwanie.filtry.sll"
                  ></button>
                  <button
                    class="btn__input--clear"
                    *ngIf="wyszukiwanie.filtry.sll"
                    (click)="
                      this.wyszukiwanie.filtry.sll = '';
                      pobierzDaneDoEkranu(false)
                    "
                  ></button>
                </div>
              </th>
              <th>
                STP
                <button
                  class="btn__input--sort--bottom--gray"
                  [ngClass]="pobierzKierunekSortowania('tps')"
                  (click)="zmienKierunekSortowania('tps')"
                ></button>
                <div class="input_container input_container--mini">
                  <input
                    class="pr-4"
                    type="text"
                    placeholder="Szukaj"
                    [(ngModel)]="wyszukiwanie.filtry.tps"
                    (keyup)="filtrujPoTekscie(this.wyszukiwanie.filtry.tps)"
                  />
                  <button
                    class="btn__input--search"
                    *ngIf="!wyszukiwanie.filtry.tps"
                  ></button>
                  <button
                    class="btn__input--clear"
                    *ngIf="wyszukiwanie.filtry.tps"
                    (click)="
                      this.wyszukiwanie.filtry.tps = '';
                      pobierzDaneDoEkranu(false)
                    "
                  ></button>
                </div>
              </th>
              <th>
                SSL
                <button
                  class="btn__input--sort--bottom--gray"
                  [ngClass]="pobierzKierunekSortowania('sls')"
                  (click)="zmienKierunekSortowania('sls')"
                ></button>
                <div class="input_container input_container--mini">
                  <input
                    class="pr-4"
                    type="text"
                    placeholder="Szukaj"
                    [(ngModel)]="wyszukiwanie.filtry.sls"
                    (keyup)="filtrujPoTekscie(this.wyszukiwanie.filtry.sls)"
                  />
                  <button
                    class="btn__input--search"
                    *ngIf="!wyszukiwanie.filtry.sls"
                  ></button>
                  <button
                    class="btn__input--clear"
                    *ngIf="wyszukiwanie.filtry.sls"
                    (click)="
                      this.wyszukiwanie.filtry.sls = '';
                      pobierzDaneDoEkranu(false)
                    "
                  ></button>
                </div>
              </th>
              <th>
                Dźwignia
                <button
                  class="btn__input--sort--bottom--gray"
                  [ngClass]="pobierzKierunekSortowania('dzwignia')"
                  (click)="zmienKierunekSortowania('dzwignia')"
                ></button>
                <div class="input_container input_container--mini">
                  <input
                    class="pr-4"
                    type="text"
                    placeholder="Szukaj"
                    [(ngModel)]="wyszukiwanie.filtry.dzwignia"
                    (keyup)="
                      filtrujPoTekscie(this.wyszukiwanie.filtry.dzwignia)
                    "
                  />
                  <button
                    class="btn__input--search"
                    *ngIf="!wyszukiwanie.filtry.dzwignia"
                  ></button>
                  <button
                    class="btn__input--clear"
                    *ngIf="wyszukiwanie.filtry.dzwignia"
                    (click)="
                      this.wyszukiwanie.filtry.dzwignia = '';
                      pobierzDaneDoEkranu(false)
                    "
                  ></button>
                </div>
              </th>
              <th>
                Kapitał
                <button
                  class="btn__input--sort--bottom--gray"
                  [ngClass]="pobierzKierunekSortowania('procentKapitalu')"
                  (click)="zmienKierunekSortowania('procentKapitalu')"
                ></button>
                <div class="input_container input_container--mini">
                  <input
                    class="pr-4"
                    type="text"
                    placeholder="Szukaj"
                    [(ngModel)]="wyszukiwanie.filtry.procentKapitalu"
                    (keyup)="
                      filtrujPoTekscie(this.wyszukiwanie.filtry.procentKapitalu)
                    "
                  />
                  <button
                    class="btn__input--search"
                    *ngIf="!wyszukiwanie.filtry.procentKapitalu"
                  ></button>
                  <button
                    class="btn__input--clear"
                    *ngIf="wyszukiwanie.filtry.procentKapitalu"
                    (click)="
                      this.wyszukiwanie.filtry.procentKapitalu = '';
                      pobierzDaneDoEkranu(false)
                    "
                  ></button>
                </div>
              </th>

              <th>
                MSL
                <button
                  class="btn__input--sort--bottom--gray"
                  [ngClass]="pobierzKierunekSortowania('macdSL')"
                  (click)="zmienKierunekSortowania('macdSL')"
                ></button>
                <div class="input_container input_container--mini">
                  <input
                    class="pr-4"
                    type="text"
                    placeholder="Szukaj"
                    [(ngModel)]="wyszukiwanie.filtry.macdSL"
                    (keyup)="filtrujPoTekscie(this.wyszukiwanie.filtry.macdSL)"
                  />
                  <button
                    class="btn__input--search"
                    *ngIf="!wyszukiwanie.filtry.macdSL"
                  ></button>
                  <button
                    class="btn__input--clear"
                    *ngIf="wyszukiwanie.filtry.macdSL"
                    (click)="
                      this.wyszukiwanie.filtry.macdSL = '';
                      pobierzDaneDoEkranu(false)
                    "
                  ></button>
                </div>
              </th>
              <th>
                MLS
                <button
                  class="btn__input--sort--bottom--gray"
                  [ngClass]="pobierzKierunekSortowania('macdLS')"
                  (click)="zmienKierunekSortowania('macdLS')"
                ></button>
                <div class="input_container input_container--mini">
                  <input
                    class="pr-4"
                    type="text"
                    placeholder="Szukaj"
                    [(ngModel)]="wyszukiwanie.filtry.macdLS"
                    (keyup)="filtrujPoTekscie(this.wyszukiwanie.filtry.macdLS)"
                  />
                  <button
                    class="btn__input--search"
                    *ngIf="!wyszukiwanie.filtry.macdLS"
                  ></button>
                  <button
                    class="btn__input--clear"
                    *ngIf="wyszukiwanie.filtry.macdLS"
                    (click)="
                      this.wyszukiwanie.filtry.macdLS = '';
                      pobierzDaneDoEkranu(false)
                    "
                  ></button>
                </div>
              </th>
              <th>
                MSS
                <button
                  class="btn__input--sort--bottom--gray"
                  [ngClass]="pobierzKierunekSortowania('macdSS')"
                  (click)="zmienKierunekSortowania('macdSS')"
                ></button>
                <div class="input_container input_container--mini">
                  <input
                    class="pr-4"
                    type="text"
                    placeholder="Szukaj"
                    [(ngModel)]="wyszukiwanie.filtry.macdSS"
                    (keyup)="filtrujPoTekscie(this.wyszukiwanie.filtry.macdSS)"
                  />
                  <button
                    class="btn__input--search"
                    *ngIf="!wyszukiwanie.filtry.macdSS"
                  ></button>
                  <button
                    class="btn__input--clear"
                    *ngIf="wyszukiwanie.filtry.macdSS"
                    (click)="
                      this.wyszukiwanie.filtry.macdSS = '';
                      pobierzDaneDoEkranu(false)
                    "
                  ></button>
                </div>
              </th>
              <th>
                RSIL
                <button
                  class="btn__input--sort--bottom--gray"
                  [ngClass]="pobierzKierunekSortowania('rsiL')"
                  (click)="zmienKierunekSortowania('rsiL')"
                ></button>
                <div class="input_container input_container--mini">
                  <input
                    class="pr-4"
                    type="text"
                    placeholder="Szukaj"
                    [(ngModel)]="wyszukiwanie.filtry.rsiL"
                    (keyup)="filtrujPoTekscie(this.wyszukiwanie.filtry.rsiL)"
                  />
                  <button
                    class="btn__input--search"
                    *ngIf="!wyszukiwanie.filtry.rsiL"
                  ></button>
                  <button
                    class="btn__input--clear"
                    *ngIf="wyszukiwanie.filtry.rsiL"
                    (click)="
                      this.wyszukiwanie.filtry.rsiL = '';
                      pobierzDaneDoEkranu(false)
                    "
                  ></button>
                </div>
              </th>
              <th>
                Tylko analiza
                <button
                  class="btn__input--sort--bottom--gray"
                  [ngClass]="pobierzKierunekSortowania('tylkoAnalizaSygnalow')"
                  (click)="zmienKierunekSortowania('tylkoAnalizaSygnalow')"
                ></button>
                <div class="input_container input_container--mini">
                  <input
                    class="pr-4"
                    type="text"
                    placeholder="Szukaj"
                    [(ngModel)]="wyszukiwanie.filtry.tylkoAnalizaSygnalow"
                    (keyup)="
                      filtrujPoTekscie(
                        this.wyszukiwanie.filtry.tylkoAnalizaSygnalow
                      )
                    "
                  />
                  <button
                    class="btn__input--search"
                    *ngIf="!wyszukiwanie.filtry.tylkoAnalizaSygnalow"
                  ></button>
                  <button
                    class="btn__input--clear"
                    *ngIf="wyszukiwanie.filtry.tylkoAnalizaSygnalow"
                    (click)="
                      this.wyszukiwanie.filtry.tylkoAnalizaSygnalow = '';
                      pobierzDaneDoEkranu(false)
                    "
                  ></button>
                </div>
              </th>
              <th>
                Pozycja
                <button
                  class="btn__input--sort--bottom--gray"
                  [ngClass]="
                    pobierzKierunekSortowania('czyMaAktualnieOtwortaPozycje')
                  "
                  (click)="
                    zmienKierunekSortowania('czyMaAktualnieOtwortaPozycje')
                  "
                ></button>
                <div class="input_container input_container--mini">
                  <input
                    class="pr-4"
                    type="text"
                    placeholder="Szukaj"
                    [(ngModel)]="
                      wyszukiwanie.filtry.czyMaAktualnieOtwortaPozycje
                    "
                    (keyup)="
                      filtrujPoTekscie(
                        this.wyszukiwanie.filtry.czyMaAktualnieOtwortaPozycje
                      )
                    "
                  />
                  <button
                    class="btn__input--search"
                    *ngIf="!wyszukiwanie.filtry.czyMaAktualnieOtwortaPozycje"
                  ></button>
                  <button
                    class="btn__input--clear"
                    *ngIf="wyszukiwanie.filtry.czyMaAktualnieOtwortaPozycje"
                    (click)="
                      this.wyszukiwanie.filtry.czyMaAktualnieOtwortaPozycje =
                        '';
                      pobierzDaneDoEkranu(false)
                    "
                  ></button>
                </div>
              </th>
              <th>
                Max pozycja
                <button
                  class="btn__input--sort--bottom--gray"
                  [ngClass]="pobierzKierunekSortowania('maxWielkoscPozycji')"
                  (click)="zmienKierunekSortowania('maxWielkoscPozycji')"
                ></button>
                <div class="input_container input_container--mini">
                  <input
                    class="pr-4"
                    type="text"
                    placeholder="Szukaj"
                    [(ngModel)]="wyszukiwanie.filtry.maxWielkoscPozycji"
                    (keyup)="
                      filtrujPoTekscie(
                        this.wyszukiwanie.filtry.maxWielkoscPozycji
                      )
                    "
                  />
                  <button
                    class="btn__input--search"
                    *ngIf="!wyszukiwanie.filtry.maxWielkoscPozycji"
                  ></button>
                  <button
                    class="btn__input--clear"
                    *ngIf="wyszukiwanie.filtry.maxWielkoscPozycji"
                    (click)="
                      this.wyszukiwanie.filtry.maxWielkoscPozycji = '';
                      pobierzDaneDoEkranu(false)
                    "
                  ></button>
                </div>
              </th>
              <th>
                % na koniec msc
                <button
                  class="btn__input--sort--bottom--gray"
                  [ngClass]="
                    pobierzKierunekSortowania(
                      'odkladanyProcentNaKoniecMiesiaca'
                    )
                  "
                  (click)="
                    zmienKierunekSortowania('odkladanyProcentNaKoniecMiesiaca')
                  "
                ></button>
                <div class="input_container input_container--mini">
                  <input
                    class="pr-4"
                    type="text"
                    placeholder="Szukaj"
                    [(ngModel)]="
                      wyszukiwanie.filtry.odkladanyProcentNaKoniecMiesiaca
                    "
                    (keyup)="
                      filtrujPoTekscie(
                        this.wyszukiwanie.filtry
                          .odkladanyProcentNaKoniecMiesiaca
                      )
                    "
                  />
                  <button
                    class="btn__input--search"
                    *ngIf="
                      !wyszukiwanie.filtry.odkladanyProcentNaKoniecMiesiaca
                    "
                  ></button>
                  <button
                    class="btn__input--clear"
                    *ngIf="wyszukiwanie.filtry.odkladanyProcentNaKoniecMiesiaca"
                    (click)="
                      this.wyszukiwanie.filtry.odkladanyProcentNaKoniecMiesiaca =
                        '';
                      pobierzDaneDoEkranu(false)
                    "
                  ></button>
                </div>
              </th>
              <th>
                Odkładać od
                <button
                  class="btn__input--sort--bottom--gray"
                  [ngClass]="pobierzKierunekSortowania('odJakiejKwotyOdkladac')"
                  (click)="zmienKierunekSortowania('odJakiejKwotyOdkladac')"
                ></button>
                <div class="input_container input_container--mini">
                  <input
                    class="pr-4"
                    type="text"
                    placeholder="Szukaj"
                    [(ngModel)]="wyszukiwanie.filtry.odJakiejKwotyOdkladac"
                    (keyup)="
                      filtrujPoTekscie(
                        this.wyszukiwanie.filtry.odJakiejKwotyOdkladac
                      )
                    "
                  />
                  <button
                    class="btn__input--search"
                    *ngIf="!wyszukiwanie.filtry.odJakiejKwotyOdkladac"
                  ></button>
                  <button
                    class="btn__input--clear"
                    *ngIf="wyszukiwanie.filtry.odJakiejKwotyOdkladac"
                    (click)="
                      this.wyszukiwanie.filtry.odJakiejKwotyOdkladac = '';
                      pobierzDaneDoEkranu(false)
                    "
                  ></button>
                </div>
              </th>

              <th>Waga 5m</th>
              <th>Waga 15m</th>
              <th>Waga 1h</th>
              <th>Waga 4h</th>
              <th>Waga 1d</th>
              <th>Waga 3d</th>
              <th>Margines błędu</th>

              <th>Czy przesuwać granice</th>
              <th>Long TP procent</th>
              <th>Long SL procent</th>
              <th>Short TP procent</th>
              <th>Short SL procent</th>

              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody class="fz12" *ngIf="czySaWynikiNaLiscie(konfiguracje)">
            <tr
              *ngFor="let konfiguracja of konfiguracje"
              class="pointer project-row"
              [ngClass]="!konfiguracja.czyDzialaPoprawnie ? 'error' : ''"
            >
              <td>{{ konfiguracja.gielda }}</td>
              <td>{{ konfiguracja.rodzajKonta }}</td>
              <td>{{ konfiguracja.czyjeKonto }}</td>
              <td>{{ konfiguracja.czyAktywna }}</td>
              <td>{{ konfiguracja.paraKrypto }}</td>
              <td>{{ konfiguracja.wersjaBota }}</td>
              <td>{{ konfiguracja.maxGodzinPozycji }} h</td>
              <td>{{ konfiguracja.tpl }}%</td>
              <td>{{ konfiguracja.sll }}%</td>
              <td>{{ konfiguracja.tps }}%</td>
              <td>{{ konfiguracja.sls }}%</td>
              <td>{{ konfiguracja.dzwignia }}x</td>
              <td>{{ konfiguracja.procentKapitalu }}%</td>
              <td>{{ konfiguracja.macdSL }}</td>
              <td>{{ konfiguracja.macdLS }}</td>
              <td>{{ konfiguracja.macdSS }}</td>
              <td>{{ konfiguracja.rsiL }}</td>
              <td>{{ konfiguracja.tylkoAnalizaSygnalow }}</td>

              <td
                [ngClass]="
                  konfiguracja.czyMaAktualnieOtwortaPozycje ? 'strong' : ''
                "
              >
                {{ konfiguracja.czyMaAktualnieOtwortaPozycje }}
              </td>

              <td>{{ konfiguracja.maxWielkoscPozycji | currency }}</td>
              <td>{{ konfiguracja.odkladanyProcentNaKoniecMiesiaca }}</td>
              <td>{{ konfiguracja.odJakiejKwotyOdkladac | currency }}</td>

              <td>{{ konfiguracja.predykcjaWaga5m }}</td>
              <td>{{ konfiguracja.predykcjaWaga15m }}</td>
              <td>{{ konfiguracja.predykcjaWaga1h }}</td>
              <td>{{ konfiguracja.predykcjaWaga4h }}</td>
              <td>{{ konfiguracja.predykcjaWaga1d }}</td>
              <td>{{ konfiguracja.predykcjaWaga3d }}</td>
              <td>{{ konfiguracja.predykcjaMarginesBledu }}</td>

              <td>{{ konfiguracja.czyPrzesuwacGranice }}</td>
              <td>{{ konfiguracja.przesuniecieGranicyTPLongProcent }}</td>
              <td>{{ konfiguracja.przesuniecieGranicySLLongProcent }}</td>
              <td>{{ konfiguracja.przesuniecieGranicyTPShortProcent }}</td>
              <td>{{ konfiguracja.przesuniecieGranicySLShortProcent }}</td>

              <td>
                <button
                  *ngIf="
                    !konfiguracja.tylkoAnalizaSygnalow ||
                    konfiguracja.czyMaAktualnieOtwortaPozycje
                  "
                  title="Wejdź w pozycję"
                  class="btn__dolar"
                  (click)="otworzPozycje(konfiguracja.id)"
                ></button>
              </td>
              <td>
                <button
                  class="btn__edit"
                  (click)="edytuj(konfiguracja.id)"
                ></button>
              </td>
            </tr>
          </tbody>
        </table>
        <div
          class="ft__03 text-gray text-center my-5 py-5"
          *ngIf="!czySaWynikiNaLiscie(konfiguracje)"
        >
          <div>Brak konfiguracji dla wybranych filtrów</div>
        </div>
      </div>
    </div>

    <div
      class="paginate float-right d-flex align-items-center pt-4 pb-2 pt-lg-2 mt-1"
      *ngIf="czySaWynikiNaLiscie(konfiguracje)"
    >
      <div>Pokaż wyniki</div>
      <select
        class="paginate__select mx-2"
        name=""
        id=""
        (change)="zmienRozmiarTabeli()"
        [(ngModel)]="rozmiarTabeli"
      >
        <option [ngValue]="25">25</option>
        <option [ngValue]="50">50</option>
        <option [ngValue]="100">100</option>
      </select>
      <div class="px-2">
        {{ pobierzNumerRekorduPoczatek() }}-{{ pobierzNumerRekorduKoniec() }} z
        {{ calkowitaLiczbaElementow }}
      </div>
      <div class="d-flex">
        <button
          class="btn__left"
          *ngIf="this.wyszukiwanie.stronicowanie.strona > 1"
          (click)="zmienStrone(-1)"
        ></button>
        <div class="px-2">{{ this.wyszukiwanie.stronicowanie.strona }}</div>
        <button
          class="btn__right"
          *ngIf="czyJestWiecejRekordow"
          (click)="zmienStrone(1)"
        ></button>
      </div>
    </div>
    <div class="clearfix"></div>
    <div class="pb-2 text-right pt-2">
      <button class="btn__add" (click)="dodajKonfiguracje()"></button>
    </div>
  </div>

  <!-- mobile -->
  <div class="container-fluid d-md-none pb-5">
    <div class="pt-3 mt-1">
      <div class="ft__sort text-center">Konfiguracje futures</div>
    </div>

    <div class="filter-box d-flex justify-content-between pt-3 mb-3">
      <span></span>
      <div class="dropdown person_desktop text-right">
        <a
          class="btn__input--sort dropdown-toggle"
          [routerLink]=""
          (click)="pokazMenuSortowania()"
          role="button"
          id="dropdownSort"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          (clickOutside)="czyPokazacMenuSortowanie = false"
        >
        </a>
        <div
          class="dropdown-menu dropdown-menu-right"
          aria-labelledby="dropdownSort"
          *ngIf="czyPokazacMenuSortowanie"
          style="display: block; position: absolute"
        >
          <a
            class="dropdown-item"
            [routerLink]=""
            (click)="zmienKierunekSortowania('gielda')"
            >Giełda</a
          >
          <a
            class="dropdown-item"
            [routerLink]=""
            (click)="zmienKierunekSortowania('rodzajKonta')"
            >Rodzaj konta
          </a>
          <a
            class="dropdown-item"
            [routerLink]=""
            (click)="zmienKierunekSortowania('czyjeKonto')"
            >Czyje konto</a
          >
          <a
            class="dropdown-item"
            [routerLink]=""
            (click)="zmienKierunekSortowania('czyAktywna')"
            >Czy aktywna</a
          >
          <a
            class="dropdown-item"
            [routerLink]=""
            (click)="zmienKierunekSortowania('paraKrypto')"
            >Krypto</a
          >

          <a
            class="dropdown-item"
            [routerLink]=""
            (click)="zmienKierunekSortowania('maxGodzinPozycji')"
            >Max h pozycji</a
          >

          <a
            class="dropdown-item"
            [routerLink]=""
            (click)="zmienKierunekSortowania('tpl')"
            >LTP</a
          >
          <a
            class="dropdown-item"
            [routerLink]=""
            (click)="zmienKierunekSortowania('sll')"
            >LSL</a
          >
          <a
            class="dropdown-item"
            [routerLink]=""
            (click)="zmienKierunekSortowania('tps')"
            >STP</a
          >
          <a
            class="dropdown-item"
            [routerLink]=""
            (click)="zmienKierunekSortowania('sls')"
            >SSL</a
          >
          <a
            class="dropdown-item"
            [routerLink]=""
            (click)="zmienKierunekSortowania('dzwignia')"
            >Dźwignia</a
          >
          <a
            class="dropdown-item"
            [routerLink]=""
            (click)="zmienKierunekSortowania('procentKapitalu')"
            >Kapitał</a
          >
          <a
            class="dropdown-item"
            [routerLink]=""
            (click)="zmienKierunekSortowania('macdSL')"
            >MSL</a
          >
          <a
            class="dropdown-item"
            [routerLink]=""
            (click)="zmienKierunekSortowania('macdLS')"
            >MLS</a
          >
          <a
            class="dropdown-item"
            [routerLink]=""
            (click)="zmienKierunekSortowania('macdSS')"
            >MSS</a
          >
          <a
            class="dropdown-item"
            [routerLink]=""
            (click)="zmienKierunekSortowania('rsiL')"
            >RSIL</a
          >
          <a
            class="dropdown-item"
            [routerLink]=""
            (click)="zmienKierunekSortowania('tylkoAnalizaSygnalow')"
            >Tylko analiza</a
          >
          <a
            class="dropdown-item"
            [routerLink]=""
            (click)="zmienKierunekSortowania('czyMaAktualnieOtwortaPozycje')"
            >Pozycja</a
          >
          <a
            class="dropdown-item"
            [routerLink]=""
            (click)="zmienKierunekSortowania('maxWielkoscPozycji')"
            >Max pozycja</a
          >
          <a
            class="dropdown-item"
            [routerLink]=""
            (click)="
              zmienKierunekSortowania('odkladanyProcentNaKoniecMiesiaca')
            "
            >% na koniec msc</a
          >
          <a
            class="dropdown-item"
            [routerLink]=""
            (click)="zmienKierunekSortowania('odJakiejKwotyOdkladac')"
            >Odkładać od</a
          >
        </div>
      </div>
    </div>

    <div
      class="filter-select pt-3 pb-2 d-flex flex-column mb-3"
      *ngIf="pobierzKolumneSortowania()"
    >
      <div class="d-flex pb-2">
        <div class="filter_item">
          <button class="btn__x" (click)="wyczyscSortowanieMobile()"></button>
          <span class="fs__sort"
            >Sortowanie: {{ pobierzKolumneSortowania() }}</span
          >
        </div>
        <button
          class="btn__input--sort--bottom ml-3"
          [ngClass]="pobierzKierunekSortowaniaMobile()"
          (click)="zmienKierunekSortowaniaMobile()"
        ></button>
      </div>
    </div>

    <div
      class="input_container mb-3 text-right"
      *ngIf="!czyPokazanoWyszukiwarkeMobile"
    >
      <button
        class="btn__search"
        (click)="pokazWyszukiwarkeMobile(true)"
      ></button>
    </div>
    <div
      class="searchBox d-flex flex-wrap por pb-5"
      *ngIf="czyPokazanoWyszukiwarkeMobile"
    >
      <button
        class="btn__input--clear"
        (click)="pokazWyszukiwarkeMobile(false)"
      ></button>
    </div>

    <div
      class="box__mobile box__mobile--form mb-3"
      *ngIf="czyPokazanoWyszukiwarkeMobile"
    >
      <div class="input_container mb-3">
        <input
          class="pr-4"
          type="text"
          placeholder="Giełda"
          [(ngModel)]="wyszukiwanie.filtry.gielda"
        />
        <button
          class="btn__input--search"
          *ngIf="!wyszukiwanie.filtry.gielda"
        ></button>
        <button
          class="btn__input--clear"
          *ngIf="wyszukiwanie.filtry.gielda"
          (click)="this.wyszukiwanie.filtry.gielda = ''"
        ></button>
      </div>
      <div class="input_container mb-3">
        <input
          class="pr-4"
          type="text"
          placeholder="Rodzaj konta"
          [(ngModel)]="wyszukiwanie.filtry.rodzajKonta"
        />
        <button
          class="btn__input--search"
          *ngIf="!wyszukiwanie.filtry.rodzajKonta"
        ></button>
        <button
          class="btn__input--clear"
          *ngIf="wyszukiwanie.filtry.rodzajKonta"
          (click)="this.wyszukiwanie.filtry.rodzajKonta = ''"
        ></button>
      </div>
      <div class="input_container mb-3">
        <input
          class="pr-4"
          type="text"
          placeholder="Czyje konto"
          [(ngModel)]="wyszukiwanie.filtry.czyjeKonto"
        />
        <button
          class="btn__input--search"
          *ngIf="!wyszukiwanie.filtry.czyjeKonto"
        ></button>
        <button
          class="btn__input--clear"
          *ngIf="wyszukiwanie.filtry.czyjeKonto"
          (click)="this.wyszukiwanie.filtry.czyjeKonto = ''"
        ></button>
      </div>
      <div class="input_container mb-3">
        <input
          class="pr-4"
          type="text"
          placeholder="Czy aktywna"
          [(ngModel)]="wyszukiwanie.filtry.czyAktywna"
        />
        <button
          class="btn__input--search"
          *ngIf="!wyszukiwanie.filtry.czyAktywna"
        ></button>
        <button
          class="btn__input--clear"
          *ngIf="wyszukiwanie.filtry.czyAktywna"
          (click)="this.wyszukiwanie.filtry.czyAktywna = ''"
        ></button>
      </div>

      <div class="input_container mb-3">
        <input
          class="pr-4"
          type="text"
          placeholder="Krypto"
          [(ngModel)]="wyszukiwanie.filtry.paraKrypto"
        />
        <button
          class="btn__input--search"
          *ngIf="!wyszukiwanie.filtry.paraKrypto"
        ></button>
        <button
          class="btn__input--clear"
          *ngIf="wyszukiwanie.filtry.paraKrypto"
          (click)="this.wyszukiwanie.filtry.paraKrypto = ''"
        ></button>
      </div>
      <div class="input_container mb-3">
        <input
          class="pr-4"
          type="text"
          placeholder="Max h pozycji"
          [(ngModel)]="wyszukiwanie.filtry.maxGodzinPozycji"
        />
        <button
          class="btn__input--search"
          *ngIf="!wyszukiwanie.filtry.maxGodzinPozycji"
        ></button>
        <button
          class="btn__input--clear"
          *ngIf="wyszukiwanie.filtry.maxGodzinPozycji"
          (click)="this.wyszukiwanie.filtry.maxGodzinPozycji = ''"
        ></button>
      </div>

      <div class="input_container mb-3">
        <input
          class="pr-4"
          type="text"
          placeholder="LTP"
          [(ngModel)]="wyszukiwanie.filtry.tpl"
        />
        <button
          class="btn__input--search"
          *ngIf="!wyszukiwanie.filtry.tpl"
        ></button>
        <button
          class="btn__input--clear"
          *ngIf="wyszukiwanie.filtry.tpl"
          (click)="this.wyszukiwanie.filtry.tpl = ''"
        ></button>
      </div>
      <div class="input_container mb-3">
        <input
          class="pr-4"
          type="text"
          placeholder="LSL"
          [(ngModel)]="wyszukiwanie.filtry.sll"
        />
        <button
          class="btn__input--search"
          *ngIf="!wyszukiwanie.filtry.sll"
        ></button>
        <button
          class="btn__input--clear"
          *ngIf="wyszukiwanie.filtry.sll"
          (click)="this.wyszukiwanie.filtry.sll = ''"
        ></button>
      </div>
      <div class="input_container mb-3">
        <input
          class="pr-4"
          type="text"
          placeholder="STP"
          [(ngModel)]="wyszukiwanie.filtry.tps"
        />
        <button
          class="btn__input--search"
          *ngIf="!wyszukiwanie.filtry.tps"
        ></button>
        <button
          class="btn__input--clear"
          *ngIf="wyszukiwanie.filtry.tps"
          (click)="this.wyszukiwanie.filtry.tps = ''"
        ></button>
      </div>
      <div class="input_container mb-3">
        <input
          class="pr-4"
          type="text"
          placeholder="SSL"
          [(ngModel)]="wyszukiwanie.filtry.sls"
        />
        <button
          class="btn__input--search"
          *ngIf="!wyszukiwanie.filtry.sls"
        ></button>
        <button
          class="btn__input--clear"
          *ngIf="wyszukiwanie.filtry.sls"
          (click)="this.wyszukiwanie.filtry.sls = ''"
        ></button>
      </div>
      <div class="input_container mb-3">
        <input
          class="pr-4"
          type="text"
          placeholder="Dźwignia"
          [(ngModel)]="wyszukiwanie.filtry.dzwignia"
        />
        <button
          class="btn__input--search"
          *ngIf="!wyszukiwanie.filtry.dzwignia"
        ></button>
        <button
          class="btn__input--clear"
          *ngIf="wyszukiwanie.filtry.dzwignia"
          (click)="this.wyszukiwanie.filtry.dzwignia = ''"
        ></button>
      </div>
      <div class="input_container mb-3">
        <input
          class="pr-4"
          type="text"
          placeholder="Kapitał"
          [(ngModel)]="wyszukiwanie.filtry.procentKapitalu"
        />
        <button
          class="btn__input--search"
          *ngIf="!wyszukiwanie.filtry.procentKapitalu"
        ></button>
        <button
          class="btn__input--clear"
          *ngIf="wyszukiwanie.filtry.procentKapitalu"
          (click)="this.wyszukiwanie.filtry.procentKapitalu = ''"
        ></button>
      </div>
      <div class="input_container mb-3">
        <input
          class="pr-4"
          type="text"
          placeholder="MSL"
          [(ngModel)]="wyszukiwanie.filtry.macdSL"
        />
        <button
          class="btn__input--search"
          *ngIf="!wyszukiwanie.filtry.macdSL"
        ></button>
        <button
          class="btn__input--clear"
          *ngIf="wyszukiwanie.filtry.macdSL"
          (click)="this.wyszukiwanie.filtry.macdSL = ''"
        ></button>
      </div>
      <div class="input_container mb-3">
        <input
          class="pr-4"
          type="text"
          placeholder="MLS"
          [(ngModel)]="wyszukiwanie.filtry.macdLS"
        />
        <button
          class="btn__input--search"
          *ngIf="!wyszukiwanie.filtry.macdLS"
        ></button>
        <button
          class="btn__input--clear"
          *ngIf="wyszukiwanie.filtry.macdLS"
          (click)="this.wyszukiwanie.filtry.macdLS = ''"
        ></button>
      </div>
      <div class="input_container mb-3">
        <input
          class="pr-4"
          type="text"
          placeholder="MSS"
          [(ngModel)]="wyszukiwanie.filtry.macdSS"
        />
        <button
          class="btn__input--search"
          *ngIf="!wyszukiwanie.filtry.macdSS"
        ></button>
        <button
          class="btn__input--clear"
          *ngIf="wyszukiwanie.filtry.macdSS"
          (click)="this.wyszukiwanie.filtry.macdSS = ''"
        ></button>
      </div>
      <div class="input_container mb-3">
        <input
          class="pr-4"
          type="text"
          placeholder="RSIL"
          [(ngModel)]="wyszukiwanie.filtry.rsiL"
        />
        <button
          class="btn__input--search"
          *ngIf="!wyszukiwanie.filtry.rsiL"
        ></button>
        <button
          class="btn__input--clear"
          *ngIf="wyszukiwanie.filtry.rsiL"
          (click)="this.wyszukiwanie.filtry.rsiL = ''"
        ></button>
      </div>
      <div class="input_container mb-3">
        <input
          class="pr-4"
          type="text"
          placeholder="Tylko analiza"
          [(ngModel)]="wyszukiwanie.filtry.tylkoAnalizaSygnalow"
        />
        <button
          class="btn__input--search"
          *ngIf="!wyszukiwanie.filtry.tylkoAnalizaSygnalow"
        ></button>
        <button
          class="btn__input--clear"
          *ngIf="wyszukiwanie.filtry.tylkoAnalizaSygnalow"
          (click)="this.wyszukiwanie.filtry.tylkoAnalizaSygnalow = ''"
        ></button>
      </div>
      <div class="input_container mb-3">
        <input
          class="pr-4"
          type="text"
          placeholder="Pozycja"
          [(ngModel)]="wyszukiwanie.filtry.czyMaAktualnieOtwortaPozycje"
        />
        <button
          class="btn__input--search"
          *ngIf="!wyszukiwanie.filtry.czyMaAktualnieOtwortaPozycje"
        ></button>
        <button
          class="btn__input--clear"
          *ngIf="wyszukiwanie.filtry.czyMaAktualnieOtwortaPozycje"
          (click)="this.wyszukiwanie.filtry.czyMaAktualnieOtwortaPozycje = ''"
        ></button>
      </div>
      <div class="input_container mb-3">
        <input
          class="pr-4"
          type="text"
          placeholder="Max pozycja"
          [(ngModel)]="wyszukiwanie.filtry.maxWielkoscPozycji"
        />
        <button
          class="btn__input--search"
          *ngIf="!wyszukiwanie.filtry.maxWielkoscPozycji"
        ></button>
        <button
          class="btn__input--clear"
          *ngIf="wyszukiwanie.filtry.maxWielkoscPozycji"
          (click)="this.wyszukiwanie.filtry.maxWielkoscPozycji = ''"
        ></button>
      </div>
      <div class="input_container mb-3">
        <input
          class="pr-4"
          type="text"
          placeholder="% na koniec msc"
          [(ngModel)]="wyszukiwanie.filtry.odkladanyProcentNaKoniecMiesiaca"
        />
        <button
          class="btn__input--search"
          *ngIf="!wyszukiwanie.filtry.odkladanyProcentNaKoniecMiesiaca"
        ></button>
        <button
          class="btn__input--clear"
          *ngIf="wyszukiwanie.filtry.odkladanyProcentNaKoniecMiesiaca"
          (click)="
            this.wyszukiwanie.filtry.odkladanyProcentNaKoniecMiesiaca = ''
          "
        ></button>
      </div>
      <div class="input_container mb-3">
        <input
          class="pr-4"
          type="text"
          placeholder="Odkładać od"
          [(ngModel)]="wyszukiwanie.filtry.odJakiejKwotyOdkladac"
        />
        <button
          class="btn__input--search"
          *ngIf="!wyszukiwanie.filtry.odJakiejKwotyOdkladac"
        ></button>
        <button
          class="btn__input--clear"
          *ngIf="wyszukiwanie.filtry.odJakiejKwotyOdkladac"
          (click)="this.wyszukiwanie.filtry.odJakiejKwotyOdkladac = ''"
        ></button>
      </div>
    </div>

    <button
      class="btn__border mt-4 mb-4"
      (click)="pobierzDaneDoEkranu(true); pokazWyszukiwarkeMobile(false)"
      *ngIf="czyPokazanoWyszukiwarkeMobile"
    >
      Szukaj
    </button>

    <button
      class="btn__clear--filter mt-2 mb-4"
      (click)="wyczyscFiltry(); pokazWyszukiwarkeMobile(false)"
      *ngIf="czyPokazacWyczyscFiltry()"
    >
      Wyczyść filtry
    </button>

    <div
      class="no-results text-center ft__no-results"
      *ngIf="!czySaWynikiNaLiscie(konfiguracje)"
    >
      <div>Brak konfiguracji<br />dla wybranych filtrów</div>
    </div>

    <div class="box__mobile mb-3" *ngFor="let konfiguracja of konfiguracje">
      <div
        class="d-flex justify-content-between align-items-center pb-2 mb-2 pt-2"
      >
        <span class="fs__pname">Giełda {{ konfiguracja.gielda }}</span>
      </div>
      <div
        class="d-flex justify-content-between align-items-center pb-2 mr-3 mb-2 m_border"
      >
        <span class="fs__pname--2">Rodzaj konta</span>
        <span class="fs__pname--3 text-lg2">{{
          konfiguracja.rodzajKonta
        }}</span>
      </div>
      <div
        class="d-flex justify-content-between align-items-center pb-2 mr-3 mb-2 m_border"
      >
        <span class="fs__pname--2">Czyje konto</span>
        <span class="fs__pname--3 text-lg2">{{ konfiguracja.czyjeKonto }}</span>
      </div>
      <div
        class="d-flex justify-content-between align-items-center pb-2 mr-3 mb-2 m_border"
      >
        <span class="fs__pname--2">Czy aktywna</span>
        <span class="fs__pname--3 text-lg2">{{ konfiguracja.czyAktywna }}</span>
      </div>

      <div
        class="d-flex justify-content-between align-items-center pb-2 mr-3 mb-2 m_border"
      >
        <span class="fs__pname--2">Krypto</span>
        <span class="fs__pname--3 text-lg2">{{ konfiguracja.paraKrypto }}</span>
      </div>

      <div
        class="d-flex justify-content-between align-items-center pb-2 mr-3 mb-2 m_border"
      >
        <span class="fs__pname--2">Wersja bota</span>
        <span class="fs__pname--3 text-lg2">{{ konfiguracja.wersjaBota }}</span>
      </div>

      <div
        class="d-flex justify-content-between align-items-center pb-2 mr-3 mb-2 m_border"
      >
        <span class="fs__pname--2">Max h pozycji</span>
        <span class="fs__pname--3 text-lg2">{{
          konfiguracja.maxGodzinPozycji
        }}</span>
      </div>
      <div
        class="d-flex justify-content-between align-items-center pb-2 mr-3 mb-2 m_border"
      >
        <span class="fs__pname--2">LTP</span>
        <span class="fs__pname--3 text-lg2">{{ konfiguracja.tpl }}</span>
      </div>
      <div
        class="d-flex justify-content-between align-items-center pb-2 mr-3 mb-2 m_border"
      >
        <span class="fs__pname--2">LSL</span>
        <span class="fs__pname--3 text-lg2">{{ konfiguracja.sll }}</span>
      </div>

      <div
        class="d-flex justify-content-between align-items-center pb-2 mr-3 mb-2 m_border"
      >
        <span class="fs__pname--2">STP</span>
        <span class="fs__pname--3 text-lg2">{{ konfiguracja.tps }}</span>
      </div>
      <div
        class="d-flex justify-content-between align-items-center pb-2 mr-3 mb-2 m_border"
      >
        <span class="fs__pname--2">SSL</span>
        <span class="fs__pname--3 text-lg2">{{ konfiguracja.sls }}</span>
      </div>
      <div
        class="d-flex justify-content-between align-items-center pb-2 mr-3 mb-2 m_border"
      >
        <span class="fs__pname--2">Dźwignia</span>
        <span class="fs__pname--3 text-lg2">{{ konfiguracja.dzwignia }}</span>
      </div>
      <div
        class="d-flex justify-content-between align-items-center pb-2 mr-3 mb-2 m_border"
      >
        <span class="fs__pname--2">Kapitał</span>
        <span class="fs__pname--3 text-lg2">{{
          konfiguracja.procentKapitalu
        }}</span>
      </div>
      <div
        class="d-flex justify-content-between align-items-center pb-2 mr-3 mb-2 m_border"
      >
        <span class="fs__pname--2">MSL</span>
        <span class="fs__pname--3 text-lg2">{{ konfiguracja.macdSL }}</span>
      </div>
      <div
        class="d-flex justify-content-between align-items-center pb-2 mr-3 mb-2 m_border"
      >
        <span class="fs__pname--2">MLS</span>
        <span class="fs__pname--3 text-lg2">{{ konfiguracja.macdLS }}</span>
      </div>
      <div
        class="d-flex justify-content-between align-items-center pb-2 mr-3 mb-2 m_border"
      >
        <span class="fs__pname--2">MSS</span>
        <span class="fs__pname--3 text-lg2">{{ konfiguracja.macdSS }}</span>
      </div>
      <div
        class="d-flex justify-content-between align-items-center pb-2 mr-3 mb-2 m_border"
      >
        <span class="fs__pname--2">RSIL</span>
        <span class="fs__pname--3 text-lg2">{{ konfiguracja.rsiL }}</span>
      </div>
      <div
        class="d-flex justify-content-between align-items-center pb-2 mr-3 mb-2 m_border"
      >
        <span class="fs__pname--2">Tylko analiza</span>
        <span class="fs__pname--3 text-lg2">{{
          konfiguracja.tylkoAnalizaSygnalow
        }}</span>
      </div>
      <div
        class="d-flex justify-content-between align-items-center pb-2 mr-3 mb-2 m_border"
      >
        <span class="fs__pname--2">Pozycja</span>
        <span class="fs__pname--3 text-lg2">{{
          konfiguracja.czyMaAktualnieOtwortaPozycje
        }}</span>
      </div>
      <div
        class="d-flex justify-content-between align-items-center pb-2 mr-3 mb-2 m_border"
      >
        <span class="fs__pname--2">Max pozycja</span>
        <span class="fs__pname--3 text-lg2">{{
          konfiguracja.maxWielkoscPozycji | currency
        }}</span>
      </div>
      <div
        class="d-flex justify-content-between align-items-center pb-2 mr-3 mb-2 m_border"
      >
        <span class="fs__pname--2">% na koniec msc</span>
        <span class="fs__pname--3 text-lg2">{{
          konfiguracja.odkladanyProcentNaKoniecMiesiaca
        }}</span>
      </div>

      <div
        class="d-flex justify-content-between align-items-center pb-2 mr-3 mb-2 m_border"
      >
        <span class="fs__pname--2">Odkładać od</span>
        <span class="fs__pname--3 text-lg2">{{
          konfiguracja.odJakiejKwotyOdkladac | currency
        }}</span>
      </div>

      <div
        class="d-flex justify-content-between align-items-center pb-2 mr-3 mb-2 m_border"
      >
        <span class="fs__pname--2">Waga 5m</span>
        <span class="fs__pname--3 text-lg2">{{
          konfiguracja.predykcjaWaga5m
        }}</span>
      </div>
      <div
        class="d-flex justify-content-between align-items-center pb-2 mr-3 mb-2 m_border"
      >
        <span class="fs__pname--2">Waga 15m</span>
        <span class="fs__pname--3 text-lg2">{{
          konfiguracja.predykcjaWaga15m
        }}</span>
      </div>
      <div
        class="d-flex justify-content-between align-items-center pb-2 mr-3 mb-2 m_border"
      >
        <span class="fs__pname--2">Waga 1h</span>
        <span class="fs__pname--3 text-lg2">{{
          konfiguracja.predykcjaWaga1h
        }}</span>
      </div>
      <div
        class="d-flex justify-content-between align-items-center pb-2 mr-3 mb-2 m_border"
      >
        <span class="fs__pname--2">Waga 4h</span>
        <span class="fs__pname--3 text-lg2">{{
          konfiguracja.predykcjaWaga4h
        }}</span>
      </div>
      <div
        class="d-flex justify-content-between align-items-center pb-2 mr-3 mb-2 m_border"
      >
        <span class="fs__pname--2">Waga 1d</span>
        <span class="fs__pname--3 text-lg2">{{
          konfiguracja.predykcjaWaga1d
        }}</span>
      </div>
      <div
        class="d-flex justify-content-between align-items-center pb-2 mr-3 mb-2 m_border"
      >
        <span class="fs__pname--2">Waga 3d</span>
        <span class="fs__pname--3 text-lg2">{{
          konfiguracja.predykcjaWaga3d
        }}</span>
      </div>
      <div
        class="d-flex justify-content-between align-items-center pb-2 mr-3 mb-2 m_border"
      >
        <span class="fs__pname--2">Margines błędu</span>
        <span class="fs__pname--3 text-lg2">{{
          konfiguracja.predykcjaMarginesBledu
        }}</span>
      </div>

      <div
        class="d-flex justify-content-between align-items-center pb-2 mr-3 mb-2 m_border"
      >
        <span class="fs__pname--2">Czy przesuwać granice SL i TP</span>
        <span class="fs__pname--3 text-lg2">{{
          konfiguracja.czyPrzesuwacGranice
        }}</span>
      </div>

      <div
        class="d-flex justify-content-between align-items-center pb-2 mr-3 mb-2 m_border"
      >
        <span class="fs__pname--2">Long TP procent</span>
        <span class="fs__pname--3 text-lg2">{{
          konfiguracja.przesuniecieGranicyTPLongProcent
        }}</span>
      </div>
      <div
        class="d-flex justify-content-between align-items-center pb-2 mr-3 mb-2 m_border"
      >
        <span class="fs__pname--2">Long SL procent</span>
        <span class="fs__pname--3 text-lg2">{{
          konfiguracja.przesuniecieGranicySLLongProcent
        }}</span>
      </div>
      <div
        class="d-flex justify-content-between align-items-center pb-2 mr-3 mb-2 m_border"
      >
        <span class="fs__pname--2">Short TP procent</span>
        <span class="fs__pname--3 text-lg2">{{
          konfiguracja.przesuniecieGranicyTPShortProcent
        }}</span>
      </div>
      <div
        class="d-flex justify-content-between align-items-center pb-2 mr-3 mb-2 m_border"
      >
        <span class="fs__pname--2">Short SL procent</span>
        <span class="fs__pname--3 text-lg2">{{
          konfiguracja.przesuniecieGranicySLShortProcent
        }}</span>
      </div>
    </div>

    <div
      class="paginate float-right d-flex align-items-center pb-2 pt-lg-4"
      *ngIf="czySaWynikiNaLiscie(konfiguracje)"
    >
      <div>Pokaż wyniki</div>
      <select
        class="paginate__select mx-2"
        name=""
        id=""
        (change)="zmienRozmiarTabeli()"
        [(ngModel)]="rozmiarTabeli"
      >
        <option [ngValue]="25">25</option>
        <option [ngValue]="50">50</option>
        <option [ngValue]="100">100</option>
      </select>
      <div class="px-2">
        {{ pobierzNumerRekorduPoczatek() }}-{{ pobierzNumerRekorduKoniec() }} z
        {{ calkowitaLiczbaElementow }}
      </div>
      <div class="d-flex">
        <button
          class="btn__left"
          *ngIf="this.wyszukiwanie.stronicowanie.strona > 1"
          (click)="zmienStrone(-1)"
        ></button>
        <div class="px-2">{{ this.wyszukiwanie.stronicowanie.strona }}</div>
        <button
          class="btn__right"
          *ngIf="czyJestWiecejRekordow"
          (click)="zmienStrone(1)"
        ></button>
      </div>
    </div>
    <div class="clearfix"></div>
    <div class="pb-4 text-right">
      <button class="btn__add" (click)="dodajKonfiguracje()"></button>
    </div>
  </div>
</div>
<div class="bootom__buttons px-4">
  <div
    class="container--mw600 flex-column flex-sm-row d-flex justify-content-between align-items-center"
  >
    <button
      class="btn__link order-1 order-sm-0"
      (click)="zamknijWszystko()"
      *ngIf="czySaWynikiNaLiscie(konfiguracje)"
    >
      Zastopuj wszystkie konfiguracje
    </button>
  </div>
</div>
