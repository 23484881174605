import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { PobierzAktualnePozycjeFuturesFiltry } from 'src/app/modele/futures/pobierzAktualnePozycjeFuturesFiltry';
import { PobierzAktualnePozycjeFuturesRezultat } from 'src/app/modele/futures/pobierzAktualnePozycjeFuturesRezultat';
import { RejestrFiltrowanyRequest } from 'src/app/modele/wspolne/rejestrFiltrowanyRequest';
import { Stronicowanie } from 'src/app/modele/wspolne/stronicowanie';
import { FuturesService } from 'src/app/serwisy/futures/futures.service';
import { AuthService } from 'src/app/serwisy/ogolne/auth.service';
import { LoaderService } from 'src/app/serwisy/ogolne/loader.service';
import { BazowyComponent } from '../../bazowy.component';
import { PobierzHistorycznePozycjeFuturesFiltry } from 'src/app/modele/futures/pobierzHistorycznePozycjeFuturesFiltry';
import { PobierzHistorycznePozycjeFuturesRezultat } from 'src/app/modele/futures/pobierzHistorycznePozycjeFuturesRezultat';
import { Subscription, timer } from 'rxjs';
import {
  KierunekSortowania,
  Sortowanie,
} from 'src/app/modele/wspolne/sortowanie';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-historyczne-pozycje',
  templateUrl: './historyczne-pozycje.component.html',
  styleUrls: ['./historyczne-pozycje.component.css'],
  providers: [DatePipe],
})
export class HistorycznePozycjeComponent
  extends BazowyComponent
  implements OnInit, OnDestroy
{
  czyPokazacMenuSortowanie: boolean;
  czyPokazanoWyszukiwarkeMobile: boolean;
  subscription: Subscription;
  czyZaladowanoDane: boolean;
  calkowitaLiczbaElementow: BigInteger;
  rozmiarTabeli: number = 25;
  czyJestWiecejRekordow: boolean = true;
  pozycje: Array<PobierzHistorycznePozycjeFuturesRezultat> = new Array();
  wyszukiwanie: RejestrFiltrowanyRequest<PobierzHistorycznePozycjeFuturesFiltry> =
    new RejestrFiltrowanyRequest<PobierzHistorycznePozycjeFuturesFiltry>();

  constructor(
    public router: Router,
    modalService: NgbModal,
    loaderSerwis: LoaderService,
    komunikatSerwis: ToastrService,
    public authService: AuthService,
    private datePipe: DatePipe,
    public futuresSerwis: FuturesService
  ) {
    super(authService, modalService, loaderSerwis, komunikatSerwis, router);
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
  ngOnInit(): void {
    setTimeout(() => {
      this.wyszukiwanie.filtry = new PobierzHistorycznePozycjeFuturesFiltry();
      this.wyszukiwanie.sortowanie = new Sortowanie(
        'dataSprzedazy',
        KierunekSortowania.desc
      );
      this.wyszukiwanie.stronicowanie = new Stronicowanie(false);
      this.wyszukiwanie.stronicowanie.iloscRekordow = this.rozmiarTabeli;
      this.wyszukiwanie.stronicowanie.rekordPoczatkowy = 0;
      this.wyszukiwanie.stronicowanie.strona = 1;
      this.pobierzDaneDoEkranu(true);

      this.subscription = timer(5000, 5000).subscribe((x) => {
        this.pobierzDaneDoEkranu(false);
      });
    });
  }

  pobierzDaneDoEkranu(zLadowaczem: boolean) {
    this.futuresSerwis
      .pobierzHistorycznePozycjeFutures(this.wyszukiwanie, zLadowaczem)
      .subscribe((rezultat) => {
        if (this.czyRezultatOk(rezultat)) {
          this.pozycje = rezultat.listaElementow;
          this.czyZaladowanoDane = true;
          this.calkowitaLiczbaElementow = rezultat.calkowitaIloscRekordow;
          this.czyJestWiecejRekordow = rezultat.czyJestWiecejRekordow;
          return;
        }

        this.wyswietlKomunikat(rezultat.wiadomosc, true);
        return;
      });
  }

  zmienRozmiarTabeli() {
    this.wyszukiwanie.stronicowanie.iloscRekordow = this.rozmiarTabeli;
    this.pobierzDaneDoEkranu(true);
  }

  pobierzNumerRekorduPoczatek() {
    if (this.wyszukiwanie.stronicowanie == undefined) {
      return 1;
    }
    return this.wyszukiwanie.stronicowanie.rekordPoczatkowy + 1;
  }
  pobierzNumerRekorduKoniec() {
    if (this.wyszukiwanie.stronicowanie == undefined) {
      return 1;
    }
    return (
      this.wyszukiwanie.stronicowanie.rekordPoczatkowy + this.pozycje.length
    );
  }

  zmienStrone(oIle: number) {
    this.wyszukiwanie.stronicowanie.strona += oIle;
    this.wyszukiwanie.stronicowanie.rekordPoczatkowy =
      (this.wyszukiwanie.stronicowanie.strona - 1) *
      this.wyszukiwanie.stronicowanie.iloscRekordow;
    this.pobierzDaneDoEkranu(true);
  }

  pokazMenuSortowania() {
    this.czyPokazacMenuSortowanie = !this.czyPokazacMenuSortowanie;
  }

  pobierzKierunekSortowania(kolumna: string) {
    var kierunki = this.wyszukiwanie.sortowanie.listaSortowania;

    var kierunek = kierunki.find((m) => m.nazwaKolumny == kolumna);

    if (kierunek == undefined) {
      return '';
    }

    if (kierunek.kierunekSortowania == KierunekSortowania.asc) {
      return 'up';
    }
    if (kierunek.kierunekSortowania == KierunekSortowania.desc) {
      return 'down';
    }
  }

  zmienKierunekSortowania(kolumna: string) {
    this.czyPokazacMenuSortowanie = false;
    var kierunki = this.wyszukiwanie.sortowanie.listaSortowania;

    var kierunek = kierunki.find((m) => m.nazwaKolumny == kolumna);

    if (kierunek == undefined) {
      this.wyszukiwanie.sortowanie = new Sortowanie(
        kolumna,
        KierunekSortowania.asc
      );
      this.pobierzDaneDoEkranu(true);
      return;
    }

    if (kierunek.kierunekSortowania == KierunekSortowania.asc) {
      this.wyszukiwanie.sortowanie = new Sortowanie(
        kolumna,
        KierunekSortowania.desc
      );
      this.pobierzDaneDoEkranu(true);
      return;
    }
    if (kierunek.kierunekSortowania == KierunekSortowania.desc) {
      this.wyszukiwanie.sortowanie = new Sortowanie(
        'dataSprzedazy',
        KierunekSortowania.asc
      );
      this.pobierzDaneDoEkranu(true);
      return;
    }
  }

  pobierzKolumneSortowania() {
    var kierunki = this.wyszukiwanie.sortowanie.listaSortowania;

    switch (kierunki[0].nazwaKolumny) {
      case 'gielda':
        return 'Giełda';
      case 'rodzajKonta':
        return 'Rodzaj konta';
      case 'czyjeKonto':
        return 'Czyje konto';
      case 'dataZakupu':
        return 'Data zakupu';
      case 'dataSprzedazy':
        return 'Data sprzedaży';
      case 'rodzajTransakcji':
        return 'Rodzaj transakcji';
      case 'cenaZakupu':
        return 'Cena zakupu';
      case 'cenaSprzedazy':
        return 'Cena sprzedaży';
      case 'ilosc':
        return 'Ilość';
      case 'cenaCalkowitaTransakcji':
        return 'Cena całkowita transakcji';
      case 'planowanaCenaSprzedazy':
        return 'Planowana cena sprzedaży';
      case 'zarobiono':
        return 'Zarobiono';
      case 'procent':
        return 'Procent';
      case 'krypto':
        return 'Krypto';
    }
  }

  pobierzKierunekSortowaniaMobile() {
    var kierunki = this.wyszukiwanie.sortowanie.listaSortowania;

    if (kierunki[0].kierunekSortowania == KierunekSortowania.asc) {
      return 'up';
    }
    if (kierunki[0].kierunekSortowania == KierunekSortowania.desc) {
      return '';
    }
  }

  zmienKierunekSortowaniaMobile() {
    var kierunki = this.wyszukiwanie.sortowanie.listaSortowania;

    var kierunek = kierunki[0];

    if (kierunek.kierunekSortowania == KierunekSortowania.asc) {
      this.wyszukiwanie.sortowanie = new Sortowanie(
        kierunek.nazwaKolumny,
        KierunekSortowania.desc
      );
      this.pobierzDaneDoEkranu(true);
      return;
    }
    if (kierunek.kierunekSortowania == KierunekSortowania.desc) {
      this.wyszukiwanie.sortowanie = new Sortowanie(
        kierunek.nazwaKolumny,
        KierunekSortowania.asc
      );
      this.pobierzDaneDoEkranu(true);
      return;
    }
  }
  wyczyscSortowanieMobile() {
    this.wyszukiwanie.sortowanie = new Sortowanie('Id', KierunekSortowania.asc);
    this.pobierzDaneDoEkranu(true);
  }

  filtrujPoTekscie(wartosc: string) {
    this.subscription?.unsubscribe();
    this.wyszukiwanie.stronicowanie.rekordPoczatkowy = 0;
    this.wyszukiwanie.stronicowanie.strona = 1;
    this.pobierzDaneDoEkranu(false);
  }

  czyPokazacWyczyscFiltry() {
    return (
      this.wyszukiwanie.filtry.rodzajTransakcji ||
      this.wyszukiwanie.filtry.ilosc ||
      this.wyszukiwanie.filtry.cenaCalkowitaTransakcji ||
      this.wyszukiwanie.filtry.czyjeKonto ||
      this.wyszukiwanie.filtry.gielda ||
      this.wyszukiwanie.filtry.rodzajKonta ||
      this.wyszukiwanie.filtry.cenaZakupu ||
      this.wyszukiwanie.filtry.cenaSprzedazy ||
      this.wyszukiwanie.filtry.planowanaCenaSprzedazy ||
      this.wyszukiwanie.filtry.zarobiono ||
      this.wyszukiwanie.filtry.dataZakupu ||
      this.wyszukiwanie.filtry.dataSprzedazy ||
      this.wyszukiwanie.filtry.procent ||
      this.wyszukiwanie.filtry.krypto
    );
  }

  wyczyscFiltry() {
    this.wyszukiwanie.filtry.rodzajTransakcji = '';
    this.wyszukiwanie.filtry.ilosc = '';
    this.wyszukiwanie.filtry.cenaCalkowitaTransakcji = '';
    this.wyszukiwanie.filtry.czyjeKonto = '';
    this.wyszukiwanie.filtry.gielda = '';
    this.wyszukiwanie.filtry.rodzajKonta = '';
    this.wyszukiwanie.filtry.cenaZakupu = '';
    this.wyszukiwanie.filtry.cenaSprzedazy = '';
    this.wyszukiwanie.filtry.planowanaCenaSprzedazy = '';
    this.wyszukiwanie.filtry.zarobiono = '';
    this.wyszukiwanie.filtry.dataZakupu = '';
    this.wyszukiwanie.filtry.dataSprzedazy = '';
    this.wyszukiwanie.filtry.procent = '';
    this.wyszukiwanie.filtry.krypto = '';

    this.pobierzDaneDoEkranu(true);
  }

  pokazWyszukiwarkeMobile(czyPokazac: boolean) {
    this.czyPokazanoWyszukiwarkeMobile = czyPokazac;
  }

  formatujDate(data: Date) {
    return this.datePipe.transform(data, 'yyyy-MM-dd, HH:mm');
  }
}
