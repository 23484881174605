import {
  ErrorHandler,
  LOCALE_ID,
  NgModule,
  NO_ERRORS_SCHEMA,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from './serwisy/ogolne/auth.service';
import { ErrorService } from './serwisy/ogolne/error.service';
import { HttpRequestInterceptor } from './serwisy/ogolne/http-request.interceptor';
import { KalendarzService } from './serwisy/ogolne/kalendarz.service';
import { LoaderService } from './serwisy/ogolne/loader.service';
import { MenuService } from './serwisy/ogolne/menu.service';
import { CookieService } from 'ngx-cookie-service';
import { CommonModule, registerLocaleData } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppInjectorModule } from './app.injector.module';
import { ToastrModule } from 'ngx-toastr';
import { LogowanieComponent } from './komponenty/konto/logowanie/logowanie.component';
import { KalendarzModalContent } from './komponenty/kalendarz/kalendarz-modal';
import { NawigacjaComponent } from './komponenty/nawigacja/nawigacja.component';
import { NaglowekNonAuthComponent } from './komponenty/konto/naglowek-non-auth/naglowek-non-auth.component';
import { TakNieModalComponent } from './komponenty/ogolne/tak-nie-modal/tak-nie-modal.component';
import { KonfiguracjeGieldComponent } from './komponenty/futures/konfiguracje-gield/konfiguracje-gield.component';
import { KonfiguracjeFuturesComponent } from './komponenty/futures/konfiguracje-futures/konfiguracje-futures.component';
import { AktualnePozycjeComponent } from './komponenty/futures/aktualne-pozycje/aktualne-pozycje.component';
import { HistorycznePozycjeComponent } from './komponenty/futures/historyczne-pozycje/historyczne-pozycje.component';
import { DodajKonfiguracjeGieldComponent } from './komponenty/futures/modale/dodaj-konfiguracje-gield/dodaj-konfiguracje-gield.component';
import { DodajKonfiguracjeFuturesComponent } from './komponenty/futures/modale/dodaj-konfiguracje-futures/dodaj-konfiguracje-futures.component';
import { EdytujKonfiguracjeFuturesComponent } from './komponenty/futures/modale/edytuj-konfiguracje-futures/edytuj-konfiguracje-futures.component';
import { EdytujKonfiguracjeGieldComponent } from './komponenty/futures/modale/edytuj-konfiguracje-gield/edytuj-konfiguracje-gield.component';
import { WejdzWPozycjeComponent } from './komponenty/futures/modale/wejdz-w-pozycje/wejdz-w-pozycje.component';
import { CenyLiveComponent } from './komponenty/futures/ceny-live/ceny-live.component';
import localePL from '@angular/common/locales/pl';
import { TestyComponent } from './komponenty/futures/testy/testy.component';
import { SzukanieNajlepszejKonfiguracjiComponent } from './komponenty/futures/testy/szukanie-najlepszej-konfiguracji/szukanie-najlepszej-konfiguracji.component';
import { TestowanieKonfiguracjiComponent } from './komponenty/futures/testy/testowanie-konfiguracji/testowanie-konfiguracji.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgSelectModule } from '@ng-select/ng-select';
import { StatystykiKonfiguracjiComponent } from './komponenty/futures/statystyki-konfiguracji/statystyki-konfiguracji.component';
import { TabelaComponent } from './komponenty/ogolne/tabela/tabela.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { TradingviewWidgetModule } from 'angular-tradingview-widget';

registerLocaleData(localePL);

@NgModule({
  declarations: [
    AppComponent,
    LogowanieComponent,
    KalendarzModalContent,
    NawigacjaComponent,
    NaglowekNonAuthComponent,
    TakNieModalComponent,
    KonfiguracjeGieldComponent,
    KonfiguracjeFuturesComponent,
    AktualnePozycjeComponent,
    HistorycznePozycjeComponent,
    DodajKonfiguracjeGieldComponent,
    DodajKonfiguracjeFuturesComponent,
    EdytujKonfiguracjeFuturesComponent,
    EdytujKonfiguracjeGieldComponent,
    WejdzWPozycjeComponent,
    CenyLiveComponent,
    TestyComponent,
    SzukanieNajlepszejKonfiguracjiComponent,
    TestowanieKonfiguracjiComponent,
    StatystykiKonfiguracjiComponent,
    TabelaComponent,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    NgbModule,
    ToastrModule.forRoot({
      timeOut: 4000,
      positionClass: 'toast-top-center',
      preventDuplicates: true,
      enableHtml: true,
      maxOpened: 1,
    }),
    AppInjectorModule,
    ReactiveFormsModule,
    NgMultiSelectDropDownModule.forRoot(),
    NgSelectModule,
    SlickCarouselModule,
    TradingviewWidgetModule,
  ],
  providers: [
    {
      provide: ErrorHandler,
      useClass: ErrorService,
    },
    CookieService,
    LoaderService,
    KalendarzService,
    AuthService,
    MenuService,
    NgbActiveModal,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpRequestInterceptor,
      multi: true,
    },
    { provide: LOCALE_ID, useValue: 'pl-PL' },
  ],
  bootstrap: [AppComponent],
  schemas: [NO_ERRORS_SCHEMA],
})
export class AppModule {}
