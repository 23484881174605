<div>
  <div class="container--main d-none d-md-block">
    <div class="ft__sort text-center pt-2">Testy</div>
  </div>

  <div class="container--main d-none d-md-block">
    <div class="d-flex py-2">
      <div class="project__menu">
        <a routerLink="/testowanie-konfiguracji" [routerLinkActive]="['active']"
          >Testy konfiguracji</a
        >
        <a
          routerLink="/szukanie-najlepszej-konfiguracji"
          [routerLinkActive]="['active']"
          >Szukanie najlepszej konfiguracji</a
        >
      </div>
    </div>
  </div>

  <!-- mobile -->
  <div class="container-fluid d-md-none pb-5">
    <div class="pt-3 mt-1">
      <div class="ft__sort text-center">Testy</div>
    </div>

    <div class="d-fledx py-3">
      <div class="project__menu">
        <button class="btn__left"></button>
        <ngx-slick-carousel
          class="carousel menu_slider"
          #slickModal="slick-carousel"
          [config]="slideConfig"
        >
          <div ngxSlickItem class="slide">
            <a
              routerLink="/testowanie-konfiguracji"
              [routerLinkActive]="['active']"
              >Testy konfiguracji</a
            >
          </div>
          <div ngxSlickItem class="slide">
            <a
              routerLink="/szukanie-najlepszej-konfiguracji"
              [routerLinkActive]="['active']"
              >Szukanie najlepszej konfiguracji</a
            >
          </div>
        </ngx-slick-carousel>
        <button class="btn__right"></button>
      </div>
    </div>
  </div>
</div>
