<!-- <nav class="navbar">
    <div class="navbar__container">
        <div class="navbrand d-flex align-items-center justify-content-center">
            <a class="brand" href="#">
                <img src="assets/img/logo.svg" alt="">
            </a>
        </div>
    </div>
</nav> -->

<div class="container container--login">
  <div class="ft__01 text-center py-4 mt-2">Zaloguj się</div>

  <div
    class="input_container pb-3"
    [ngClass]="czyBladWalidacyjny('email') ? 'error' : ''"
  >
    <input
      placeholder="E-mail"
      type="text"
      name="email"
      [(ngModel)]="zalogujModel.email"
    />
    <div class="error-info fs__error pt-2" *ngIf="czyBladWalidacyjny('email')">
      {{ pobierzBladWalidacyjny("email") }}
    </div>
  </div>
  <form (ngSubmit)="zaloguj()">
    <div
      class="input_container pb-2"
      [ngClass]="czyBladWalidacyjny('haslo') ? 'error' : ''"
    >
      <input
        [type]="czyPokazacHaslo ? 'text' : 'password'"
        name="haslo"
        [(ngModel)]="zalogujModel.haslo"
        placeholder="Hasło"
      />
      <button
        type="button"
        class="btn__input--eye"
        [ngClass]="czyPokazacHaslo ? 'active' : ''"
        (click)="pokazHaslo()"
      ></button>
      <div
        class="error-info fs__error pt-2"
        *ngIf="czyBladWalidacyjny('haslo')"
      >
        {{ pobierzBladWalidacyjny("haslo") }}
      </div>
    </div>
  </form>
  <div class="input_container pb-3">
    <button class="btn__primary" (click)="zaloguj()">Zaloguj</button>
  </div>
</div>

<!-- <div class="tradingview-widget-container">
  <div class="tradingview-widget-container__widget"></div>
  <div class="tradingview-widget-copyright">
    <a
      rel="noreferrer"
      href="https://www.tradingview.com/symbols/MHCUSD/?exchange=CEXIO"
      rel="noopener"
      target="_blank"
    >
      <span class="blue-text">MHCUSD Rates</span></a
    >
    by TradingView
  </div>
  <div #tradingview></div>
</div>  -->
