import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { FuturesService } from 'src/app/serwisy/futures/futures.service';
import { AuthService } from 'src/app/serwisy/ogolne/auth.service';
import { LoaderService } from 'src/app/serwisy/ogolne/loader.service';
import { BazowyComponent } from '../../bazowy.component';
import { PobierzStatystkiKonfiguracjiRezultat } from 'src/app/modele/statystyki/pobierzStatystkiKonfiguracjiRezultat';
import { RejestrFiltrowanyRequest } from 'src/app/modele/wspolne/rejestrFiltrowanyRequest';
import { PobierzStatystkiKonfiguracjiFiltr } from 'src/app/modele/statystyki/pobierzStatystkiKonfiguracjiFiltr';
import {
  Sortowanie,
  KierunekSortowania,
} from 'src/app/modele/wspolne/sortowanie';
import { Stronicowanie } from 'src/app/modele/wspolne/stronicowanie';

@Component({
  selector: 'app-statystyki-konfiguracji',
  templateUrl: './statystyki-konfiguracji.component.html',
  styleUrls: ['./statystyki-konfiguracji.component.css'],
})
export class StatystykiKonfiguracjiComponent
  extends BazowyComponent
  implements OnInit, OnDestroy
{
  subscription: Subscription;
  czyZaladowanoDane: boolean;
  calkowitaLiczbaElementow: BigInteger;
  rozmiarTabeli: number = 25;
  czyJestWiecejRekordow: boolean = true;
  modalReference: any;
  modalService: any;
  czyPokazanoWyszukiwarkeMobile: boolean;
  rezultaty: Array<PobierzStatystkiKonfiguracjiRezultat> = new Array();
  czyPokazacMenuSortowanie: boolean;
  wyszukiwanie: RejestrFiltrowanyRequest<PobierzStatystkiKonfiguracjiFiltr> =
    new RejestrFiltrowanyRequest<PobierzStatystkiKonfiguracjiFiltr>();

  constructor(
    public router: Router,
    modalService: NgbModal,
    loaderSerwis: LoaderService,
    komunikatSerwis: ToastrService,
    public authService: AuthService,
    public futuresSerwis: FuturesService
  ) {
    super(authService, modalService, loaderSerwis, komunikatSerwis, router);
  }
  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.wyszukiwanie.stronicowanie = new Stronicowanie(true);
      this.wyszukiwanie.stronicowanie.iloscRekordow = this.rozmiarTabeli;
      this.wyszukiwanie.stronicowanie.rekordPoczatkowy = 0;
      this.wyszukiwanie.stronicowanie.strona = 1;
      this.wyszukiwanie.sortowanie = new Sortowanie(
        'czyjeKonto',
        KierunekSortowania.desc
      );
      this.wyszukiwanie.filtry = new PobierzStatystkiKonfiguracjiFiltr();
      this.pobierzWyniki(true);
    });
  }

  pobierzWyniki(zLadowaczem: boolean) {
    if (this.wyszukiwanie.filtry.dataDo === '') {
      this.wyszukiwanie.filtry.dataDo = null;
    }
    if (this.wyszukiwanie.filtry.dataOd === '') {
      this.wyszukiwanie.filtry.dataOd = null;
    }
    this.subscription?.unsubscribe();
    this.subscription = this.futuresSerwis
      .pobierzStatystkiKonfiguracji(this.wyszukiwanie, zLadowaczem)
      .subscribe((rezultat) => {
        if (this.czyRezultatOk(rezultat)) {
          this.rezultaty = rezultat.listaElementow;
          return;
        }

        this.wyswietlKomunikat(rezultat.wiadomosc, true);
        return;
      });
  }
}
