export enum StatusOperacji
{
    /// <summary>
        ///     Serwis nie ustawił statusu operacji, prawdopodobnie wystąpił błąd
        /// </summary>
        nieznany = 0,

        /// <summary>
        ///     OK - udało się wykonać operację
        /// </summary>
        oK = 1,

        /// <summary>
        ///     Nie udało się wykonać operacji, wystąpił błąd walidacji
        /// </summary>
        bladWalidacji,

        /// <summary>
        ///     Nie udało się wykonać operacji, wystąpił błąd walidacji. należy wyświetlić link do kliknięcia
        /// </summary>
        bladWalidacjiLink,

        /// <summary>
        ///     Nie udało się wykonać operacji, wystąpił inny błąd
        /// </summary>
        blad,

        /// <summary>
        ///     Nie udało się wykonać operacji, użytkownik nie ma odpowiednich uprawnień
        /// </summary>
        nieautoryzowany,


        /// <summary>
        ///     Nie udało się wykonać operacji, brak dostępu do wykonania operacji
        /// </summary>
        brakDostepu,
}