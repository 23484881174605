<div class="preloader active d-flex align-items-center justify-content-center" *ngIf="czyPokazLadowacz">
  <div class="">
    <img class="loader" alt="" src="/assets/img/loader.svg">
  </div>
</div>
<ng-container *ngIf="isAuthorized(); else notAuthorized">
  <app-nawigacja *ngIf="!menuService.CzySchowacMenu"></app-nawigacja>
  <router-outlet></router-outlet>
</ng-container>

<ng-template #notAuthorized>
  <router-outlet></router-outlet>
</ng-template>