import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './serwisy/ogolne/auth.service';
import { Routing } from './modele/wspolne/routing';
import { LogowanieComponent } from './komponenty/konto/logowanie/logowanie.component';
import { KonfiguracjeGieldComponent } from './komponenty/futures/konfiguracje-gield/konfiguracje-gield.component';
import { KonfiguracjeFuturesComponent } from './komponenty/futures/konfiguracje-futures/konfiguracje-futures.component';
import { AktualnePozycjeComponent } from './komponenty/futures/aktualne-pozycje/aktualne-pozycje.component';
import { HistorycznePozycjeComponent } from './komponenty/futures/historyczne-pozycje/historyczne-pozycje.component';
import { CenyLiveComponent } from './komponenty/futures/ceny-live/ceny-live.component';
import { TestyComponent } from './komponenty/futures/testy/testy.component';
import { SzukanieNajlepszejKonfiguracjiComponent } from './komponenty/futures/testy/szukanie-najlepszej-konfiguracji/szukanie-najlepszej-konfiguracji.component';
import { TestowanieKonfiguracjiComponent } from './komponenty/futures/testy/testowanie-konfiguracji/testowanie-konfiguracji.component';
import { StatystykiKonfiguracjiComponent } from './komponenty/futures/statystyki-konfiguracji/statystyki-konfiguracji.component';

const routes: Routes = [
  { path: '', redirectTo: Routing.KonfiguracjeGield, pathMatch: 'full' },
  {
    path: Routing.Zaloguj,
    component: LogowanieComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: Routing.KonfiguracjeGield,
    component: KonfiguracjeGieldComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: Routing.KonfiguracjeFutures,
    component: KonfiguracjeFuturesComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: Routing.AktualnePozycje,
    component: AktualnePozycjeComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: Routing.HistorycznePozycje,
    component: HistorycznePozycjeComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: Routing.CenyLive,
    component: CenyLiveComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: Routing.Testy,
    component: TestyComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: Routing.SzukanieNajlepszejKonfiguracji,
    component: SzukanieNajlepszejKonfiguracjiComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: Routing.TestowanieKonfiguracji,
    component: TestowanieKonfiguracjiComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: Routing.Statystyki,
    component: StatystykiKonfiguracjiComponent,
    canActivate: [AuthGuardService],
  },
  { path: '**', redirectTo: Routing.Zaloguj, pathMatch: 'full' }, // Wildcard route for a 404 page
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  providers: [AuthGuardService],
  exports: [RouterModule],
})
export class AppRoutingModule {}
