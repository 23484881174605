<div class="modal-content">
  <div class="modal-body">
    <button
      aria-label="Close"
      class="close"
      data-dismiss="modal"
      type="button"
      (click)="activeModal.dismiss()"
    ></button>
    <div class="ft__01 text-center py-4">
      Czy na pewno chcesz otworzyć nową pozycję?
    </div>
    <div class="text-center mb-2"></div>
    <form action="">
      <div class="input_container mb-4">
        <select [(ngModel)]="model.rodzaj" name="rodzaj">
          <option [ngValue]="undefined" [disabled]="true">
            Rodzaj pozycji
          </option>
          <option [ngValue]="rodzaj.key" *ngFor="let rodzaj of dostepneRodzaje">
            {{ rodzaj.value }}
          </option>
        </select>
      </div>

      <div class="input_container pb-3">
        <button class="btn__primary" (click)="zapisz()">Tak</button>
      </div>
      <div class="input_container pb-3">
        <button class="btn__link" (click)="activeModal.dismiss()">Nie</button>
      </div>
    </form>
  </div>
</div>
