import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { WybranaData } from 'src/app/komponenty/kalendarz/kalendarz-modal';

@Injectable({
  providedIn: 'root'
})
export class KalendarzService {

  public bhs: BehaviorSubject<WybranaData> = new BehaviorSubject<WybranaData>(new WybranaData(new Date()));

  wybierzDate(data: WybranaData) {
    this.bhs.next(data);
  }
}
