<div>
  <div class="container--main d-none d-md-block">
    <div class="ft__sort text-center pt-2">Statystyki</div>
  </div>

  <div class="float-container d-none d-md-block">
    <div class="float-child-left">
      <form action="">
        <div
          class="input_container pb-3"
          [ngClass]="czyBladWalidacyjny('dataOd') ? 'error' : ''"
        >
          Data od
          <input
            type="date"
            placeholder="Data od"
            [(ngModel)]="wyszukiwanie.filtry.dataOd"
            name="dataOd"
            (change)="pobierzWyniki(true)"
          />
          <div
            class="error-info fs__error pt-2"
            *ngIf="czyBladWalidacyjny('dataOd')"
          >
            {{ pobierzBladWalidacyjny("dataOd") }}
          </div>
        </div>
        <div
          class="input_container pb-3"
          [ngClass]="czyBladWalidacyjny('dataDo') ? 'error' : ''"
        >
          Data do
          <input
            type="date"
            placeholder="Data do"
            [(ngModel)]="wyszukiwanie.filtry.dataDo"
            name="dataDo"
            (change)="pobierzWyniki(true)"
          />
          <div
            class="error-info fs__error pt-2"
            *ngIf="czyBladWalidacyjny('dataDo')"
          >
            {{ pobierzBladWalidacyjny("dataDo") }}
          </div>
        </div>
      </form>
    </div>

    <div class="float-child-right">
      <div class="container-fluid d-none d-md-block">
        <div class="box__scroll py-3">
          <div class="tableFixHead tableFixHead--3" style="height: 70vh">
            <table class="table">
              <thead>
                <tr>
                  <th>Giełda</th>
                  <th>Rodzaj konta</th>
                  <th>Czyje konto</th>
                  <th>Adres API</th>
                  <th>Wszystkie pozycje</th>
                  <th>Pozycje stratne</th>
                  <th>Pozycje zyskowne</th>
                  <th>Skuteczność %</th>
                  <th>Stracono</th>
                  <th>Zarobiono</th>
                  <th>Wynik</th>
                </tr>
              </thead>
              <tbody class="fz12" *ngIf="czySaWynikiNaLiscie(rezultaty)">
                <tr
                  *ngFor="let rezultat of rezultaty"
                  class="pointer project-row"
                >
                  <td>{{ rezultat.gielda }}</td>
                  <td>{{ rezultat.rodzajKonta }}</td>
                  <td>{{ rezultat.czyjeKonto }}</td>
                  <td>{{ rezultat.adres }}</td>
                  <td>{{ rezultat.wszystkiePozycje }}</td>
                  <td>{{ rezultat.pozycjeStratne }}</td>
                  <td>{{ rezultat.pozycjeZyskowne }}</td>
                  <td>{{ rezultat.skutecznosc | number : "1.1-2" }}</td>
                  <td>{{ rezultat.stracono | currency : "PLN" }}</td>
                  <td>{{ rezultat.zarobiono | currency : "PLN" }}</td>
                  <td>{{ rezultat.wynik | currency : "PLN" }}</td>
                </tr>
              </tbody>
            </table>
            <div
              class="ft__03 text-gray text-center my-5 py-5"
              *ngIf="!czySaWynikiNaLiscie(rezultaty)"
            >
              <div>Brak giełd dla wybranych filtrów</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- mobile -->
  <div class="container-fluid d-md-none pb-5">
    <div class="pt-3 mt-1">
      <div class="ft__sort text-center">Statystyki</div>
    </div>

    <div class="box__mobiled mb-4">
      <form action="">
        <div
          class="input_container pb-3"
          [ngClass]="czyBladWalidacyjny('dataOd') ? 'error' : ''"
        >
          Data od
          <input
            type="date"
            placeholder="Data od"
            [(ngModel)]="wyszukiwanie.filtry.dataOd"
            name="dataOd"
            (change)="pobierzWyniki(true)"
          />
          <div
            class="error-info fs__error pt-2"
            *ngIf="czyBladWalidacyjny('dataOd')"
          >
            {{ pobierzBladWalidacyjny("dataOd") }}
          </div>
        </div>
        <div
          class="input_container pb-3"
          [ngClass]="czyBladWalidacyjny('dataDo') ? 'error' : ''"
        >
          Data do
          <input
            type="date"
            placeholder="Data do"
            [(ngModel)]="wyszukiwanie.filtry.dataDo"
            name="dataDo"
            (change)="pobierzWyniki(true)"
          />
          <div
            class="error-info fs__error pt-2"
            *ngIf="czyBladWalidacyjny('dataDo')"
          >
            {{ pobierzBladWalidacyjny("dataDo") }}
          </div>
        </div>
      </form>
      <div class="float-child-right-mobile"></div>
    </div>

    <div
      class="box__mobile box__mobile--table mb-3"
      *ngIf="czySaWynikiNaLiscie(rezultaty)"
    >
      <div class="fixed_table_container">
        <table class="table">
          <thead>
            <tr>
              <th>Giełda</th>
              <th>Rodzaj konta</th>
              <th>Czyje konto</th>
              <th>Adres API</th>
              <th>Wszystkie pozycje</th>
              <th>Pozycje stratne</th>
              <th>Pozycje zyskowne</th>
              <th>Skuteczność %</th>
              <th>Stracono</th>
              <th>Zarobiono</th>
              <th>Wynik</th>
            </tr>
          </thead>
          <tbody class="fz12" *ngIf="czySaWynikiNaLiscie(rezultaty)">
            <tr *ngFor="let rezultat of rezultaty" class="pointer project-row">
              <td>{{ rezultat.gielda }}</td>
              <td>{{ rezultat.rodzajKonta }}</td>
              <td>{{ rezultat.czyjeKonto }}</td>
              <td>{{ rezultat.adres }}</td>
              <td>{{ rezultat.wszystkiePozycje }}</td>
              <td>{{ rezultat.pozycjeStratne }}</td>
              <td>{{ rezultat.pozycjeZyskowne }}</td>
              <td>{{ rezultat.skutecznosc | number : "1.1-2" }}</td>
              <td>{{ rezultat.stracono | currency : "PLN" }}</td>
              <td>{{ rezultat.zarobiono | currency : "PLN" }}</td>
              <td>{{ rezultat.wynik | currency : "PLN" }}</td>
            </tr>
          </tbody>
        </table>
        <div
          class="ft__03 text-gray text-center my-5 py-5"
          *ngIf="!czySaWynikiNaLiscie(rezultaty)"
        >
          <div>Brak giełd dla wybranych filtrów</div>
        </div>
      </div>
    </div>
  </div>
</div>
