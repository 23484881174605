import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  private czySchowacMenu: boolean;

  constructor() {
    this.czySchowacMenu = false;
  }

  public schowajMenu() {
    this.czySchowacMenu = true;
  }

  public pokazMenu() {
    this.czySchowacMenu = false;
  }

  get CzySchowacMenu() {
    return this.czySchowacMenu;
  }
}
